<div class="custom-container col-xs-12">
    <div class="row main-row col-xs-12">
        <div class="col-xs-12">
            <h1>
                <mat-icon>list</mat-icon>Cuaderno de Registro
            </h1>
        </div>
        <mat-accordion class="col-xs-12" *ngIf="users.length > 0 && users[0] !== null">
            <mat-expansion-panel style="margin-bottom: 5px" id="itemtable">
                <mat-expansion-panel-header style="background-color:#CCCCCC;">
                    <mat-panel-title style="background-color:#CCCCCC; color:#666666; font-weight: 800">
                        RECUENTO DE EVALUACIONES DE COMPETENCIAS (REALIZADAS POR MI/TOTALES)
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-notebook-items [items]="users" type="competencias">
                </app-notebook-items>
            </mat-expansion-panel>

            <mat-expansion-panel style="margin-bottom: 5px" id="itemtable">
                <mat-expansion-panel-header style="background-color:#CCCCCC;">
                    <mat-panel-title style="background-color:#CCCCCC; color:#666666; font-weight: 800">
                        RECUENTO DE EVALUACIONES DE PROCEDIMIENTOS (REALIZADAS POR MI/TOTALES)
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-notebook-items [items]="users" type="procedimientos">
                </app-notebook-items>
            </mat-expansion-panel>

            <mat-expansion-panel style="margin-bottom: 5px" id="itemtable">
                <mat-expansion-panel-header style="background-color:#CCCCCC;">
                    <mat-panel-title style="background-color:#CCCCCC; color:#666666; font-weight: 800">
                        RECUENTO DE PROCEDIMIENTOS CERTIFICADOS (REALIZADAS POR MI/TOTALES)
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-notebook-items [items]="users" type="procedCertificados">
                </app-notebook-items>
            </mat-expansion-panel>

            <mat-expansion-panel style="margin-bottom: 5px" id="itemtable">
                <mat-expansion-panel-header style="background-color:#CCCCCC;">
                    <mat-panel-title style="background-color:#CCCCCC; color:#666666; font-weight: 800">
                        RECUENTO DE ACTIVIDAD ASISTENCIAL CERTIFICADAS (REALIZADAS POR MI/TOTALES)
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-notebook-items [items]="users" type="actAsistencial">
                </app-notebook-items>
            </mat-expansion-panel>

            <mat-expansion-panel id="itemtable">
                <mat-expansion-panel-header style="background-color:#CCCCCC;">
                    <mat-panel-title style="background-color:#CCCCCC; color:#666666; font-weight: 800">
                        RECUENTO DE ACTIVIDADES DOCENTES E INVESTIGADORAS CERTIFICADAS (REALIZADAS POR MI/TOTALES)
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-notebook-items [items]="users" type="actDocente">
                </app-notebook-items>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="col-xs-12 text-center" *ngIf="users.length === 0">
            <h3>No se ha encontrado ningún elemento.</h3>
        </div>
        <div class="col-xs-12 text-center" *ngIf="users.length > 0 && users[0]===null">
            <h3>Página no disponible sin conexión.</h3>
        </div>
    </div>
</div>