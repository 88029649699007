<div class="row">
    <div class="col-xs-12" *ngIf="dataSource.data !== null && dataSource.data !== undefined">
            <div class="filtro" fxLayout="row">
                <!--<div style="max-width: 30%; width: 30%; margin-left: 2%">-->
                    <mat-form-field  style="width:28%; margin-left: 1%"> <!--[formGroup]="form"-->
                        <input #inputSearch (keydown)="onKey($event)" style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por caso/competencia" [(ngModel)]="searchTextCaso" (ngModelChange)="typingTimer(); allclean()"><!--formControlName="searchBar"-->
                        <button mat-button *ngIf="searchTextCaso !== ''" matSuffix mat-icon-button (click)="cleancaso()">
                            <mat-icon class="close-icon">close</mat-icon>
                        </button>
                    </mat-form-field>
                <!--</div>-->
                <!--<div style="max-width: 25%; width: 25%; margin-left: 2%">-->
                    <mat-form-field style="width:28%; margin-left: 1%"> <!--[formGroup]="form"-->
                        <mat-select style="margin-top: 0.438em  !important;" [(ngModel)]="searchMetodo" placeholder="Buscar por método" (ngModelChange)="typingTimerInstant(); allclean()">
                            <mat-option *ngFor="let type of evalTypeOptions" [value]="type.name">
                                {{type.show}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                <!--</div>-->
                <mat-paginator #paginator2 [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 2)"></mat-paginator>
            </div>
        <mat-table #table matSort [dataSource]="dataSource" style="width: 100%" >                
            
            <ng-container [matColumnDef]="column.prop" *ngFor="let column of columns">
                <mat-header-cell *matHeaderCellDef >{{ column.title }}</mat-header-cell>
                <mat-cell *matCellDef="let row" style="width: 100%; min-height: 25px !important">
                    <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">{{ column.title }}</p>
                    <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{ render(row, column) }}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="opciones">
                <mat-header-cell *matHeaderCellDef class="mat-center-style">Opciones</mat-header-cell>
                <mat-cell  *matCellDef="let row" class="mat-center-style">
                    <button mat-raised-button style="background-color:#428bca; color: white;"(click)="onView(row)">
                        <mat-icon>remove_red_eye</mat-icon>
                        Ver
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" style="width:100%"></mat-header-row>
            <mat-row *matRowDef="let rotacion; columns: displayedColumns;" style="width:100%"
                     matRipple>
            </mat-row>
        </mat-table>
        <mat-paginator #paginator [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 1)"></mat-paginator>
    </div>
</div>