<div id="top" class="page-layout blank p-10" fusePerfectScrollbar [ngClass]="{'hideMe': dataSource === null || dataSource === undefined}">

    <!--<h3 class="tittle" *ngIf='residente !== null && residente !== undefined'>
        <mat-icon>refresh</mat-icon>
        Rotaciones del Residente: {{ residente.nombre_completo }}
    </h3>-->

   <!-- <div class="filtro" fxLayout="row">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
    </div> -->
    <!-- SEARCH-->
        <div class="filtro" fxLayout="column" *ngIf="form">
            <div style="display: flex; justify-content: space-around; width: 100%;">
                <mat-form-field style="width:31%">
                    <input #inputSearch1 (keydown)="onKey($event)" style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por residente" [(ngModel)]="searchText" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchText !== ''" matSuffix mat-icon-button (click)="clean()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field> 
                <mat-form-field style="width:31%">
                    <input #inputSearch2 (keydown)="onKey($event)"  style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por competencia" [(ngModel)]="searchTextCaso" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchTextCaso !== ''" matSuffix mat-icon-button (click)="cleancaso()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field style="width:31%">
                    <mat-select style="margin-top: 0.438em  !important;" [(ngModel)]="searchMetodo" placeholder="Buscar por método" (ngModelChange)="typingTimerInstant(); allclean();">
                        <mat-option *ngFor="let type of evalTypeOptions" [value]="type.id">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="display: flex; justify-content: space-around; width: 100%; align-items: center;" class="displayBlock">
                <button mat-raised-button style="background-color:#17A08C; color:white;" routerLink="/stepper" class="smallMargin">
                    <mat-icon>add</mat-icon> Nuevo
                </button>
                <mat-form-field style="width:31%">
                    <input #inputSearch2 (keydown)="onKey($event)"  style="margin-top: 0.438em  !important;" matInput type="text" [placeholder]="casoLabel" [(ngModel)]="searchTextClinico" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchTextClinico !== ''" matSuffix mat-icon-button (click)="cleanclinico()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-paginator #paginator2 [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 2)"></mat-paginator>
            </div>
            <!--<div style="max-width: 30%; margin-left: 2%">
                <mat-form-field class="full-width">
                    <input #inputSearch1 (keydown)="onKey($event)" style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por residente" [(ngModel)]="searchText" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchText !== ''" matSuffix mat-icon-button (click)="clean()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field> 
                <button mat-raised-button style="background-color:#17A08C; color:white;" routerLink="/stepper">
                    <mat-icon>add</mat-icon> Nuevo
                </button>
            </div>
            <div style="max-width: 70%; width: 70%;">
                <div style="max-width: 100%; width: 100%; display: flex;">
                    <div style="max-width: 50%; width: 50%; margin-left: 2%">
                        <mat-form-field class="full-width">
                            <input #inputSearch2 (keydown)="onKey($event)"  style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por competencia" [(ngModel)]="searchTextCaso" (ngModelChange)="typingTimer(); allclean()">
                            <button mat-button *ngIf="searchTextCaso !== ''" matSuffix mat-icon-button (click)="cleancaso()">
                                <mat-icon class="close-icon">close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>

                    <div style="max-width: 50%; width: 50%; margin-left: 2%">
                        <mat-form-field class="full-width">
                            <mat-select style="margin-top: 0.438em  !important;" [(ngModel)]="searchMetodo" placeholder="Buscar por método" (ngModelChange)="typingTimerInstant(); allclean();">
                                <mat-option *ngFor="let type of evalTypeOptions" [value]="type.id">
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <mat-paginator style ="width: 100%" #paginator2 [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 2)"></mat-paginator>
            </div>-->
        </div>
        <!--<div class="spinner-container" style="display:flex; height:75vh; justify-content: center; align-items: center; color: #17A08C"
        id="spinnerEval" *ngIf="dataSource.loading$ | async">
            <mat-spinner></mat-spinner>
        </div>-->
        <ng-container *ngIf="filtered.length === 0 && (searchText !== '' || searchTextCaso !== '' || searchMetodo !== 0)">
            <div style="margin-left: 25px">No se encontraron datos que mostrar</div>
        </ng-container>
        <!--<mat-paginator #paginator2 [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 2)"></mat-paginator>-->
            <mat-table #table [dataSource]="dataSource"
            [@animateStagger]="{value:'50'}" style="width: 100%">
            <ng-container matColumnDef="tipo"> <!--matSort-->
                    <mat-header-cell *matHeaderCellDef class="mat-center-style">Tipo</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Tipo:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.tipo}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="fecha">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Fecha</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="text-align:center; width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Fecha:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.fechaFormateada}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="evaluador">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Evaluador</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Evaluador:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.nombreEvaluador}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="residente">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Residente</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Residente:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.nombreResidente}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="competencia">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Competencia</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Competencia:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.competencia}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="metodo">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Método</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Método:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.metodo}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="caso">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >{{casoColumnLabel}}</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">{{casoColumnLabel}}:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.caso}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="nivelRequerido">
                    <mat-header-cell *matHeaderCellDef class="mat-center2-style">Nivel Requerido</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center2-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Nivel Requerido:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.nivelRequerido}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="nivelAlcanzado">
                    <mat-header-cell *matHeaderCellDef class="mat-center2-style" >Nivel Alcanzado</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center2-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Nivel Alcanzado:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{evaluacion.nivelAlcanzado}}</p>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="certificada">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Certificada</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                            <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">Certificada:</p>
                            <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{format(evaluacion.certificado)}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="acciones">
                    <mat-header-cell *matHeaderCellDef class="mat-center-style" >Acciones</mat-header-cell> <!-- mat-sort-header -->
                    <mat-cell *matCellDef="let evaluacion" class="mat-center-style" style="width: 100%; min-height: 25px !important; padding-left: 10px !important">
                        <button routerLink="/stepper" [queryParams]="{id:evaluacion.idEvaluacion, tipo:tipoEvaluacion, idTipo:evaluacion.idTipo}" mat-raised-button style="background-color:#428bca; color: white; margin-top: 3px; max-width: 180px"> 
                            <mat-icon style="color: white; font-size: 15px">remove_red_eye</mat-icon> 
                                Ver
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns" style="width:100%"></mat-header-row>
                <mat-row *matRowDef="let rotacion; columns: displayedColumns;" style="width:100%; min-height: 15px !important"
                        matRipple
                        [@animate]="{value:'*',params:{y:'100%'}}">
                </mat-row>
            </mat-table>

            <mat-paginator #paginator [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 1)"></mat-paginator>
</div>
