import { EventInfoComponent } from './eventInfo/eventInfo.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProfileComponent } from './profile.component';


import { MatButtonModule, MatRadioModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule , MatRippleModule, 
MatTableModule, MatToolbarModule, MatButtonToggleModule, MatSliderModule, MatIconModule, MatListModule, MatMenuModule, MatSelectModule, 
MatSlideToggleModule, MatTabsModule, MatCardModule } from '@angular/material';
import { MaterialFileInputModule } from 'ngx-material-file-input';


import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';

import { AuthService } from 'app/services/auth.service';
import { PrivacyPolicyComponent } from './privacyPolicy/privacyPolicy.component';

const routes = [
    {
        path     : 'profile',
        component: ProfileComponent,
        //canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        ProfileComponent,
        EventInfoComponent,
        PrivacyPolicyComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatCardModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseHighlightModule,
        MatCheckboxModule,
        MatRadioModule,
        MatRippleModule,
        MatInputModule,
        MatSliderModule,
        MaterialFileInputModule
    ],
    exports     : [
        ProfileComponent
    ],
    entryComponents: [
        EventInfoComponent,
        PrivacyPolicyComponent
    ]
})

export class ProfileModule
{
}
