import { SpinnerService } from './../../../../services/spinner.service';
import { AlertService } from './../../../../services/alert.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { merge} from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { PortEvaluation } from '../models/port-evaluation.model';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { EvaluationService } from '../../stepper/evaluation.service';
import { AuthService } from 'app/services/auth.service';
import { UtilsService } from 'app/services/utils.service';
@Component({
  selector: 'app-port-evaluations',
  templateUrl: './port-evaluations.component.html',
  styleUrls: ['./port-evaluations.component.scss']
})
export class PortEvaluationsComponent implements OnInit, AfterViewInit {
    @ViewChild('inputSearch1') inputSearch1;
    @ViewChild('inputSearch2') inputSearch2;
    dataSource = new MatTableDataSource<PortEvaluation>();
    dataLength: number;
    searchText: string = '';
    searchTextCaso: string = '';
    searchMetodo: string = '';
    allData: PortEvaluation[];
    filteredData: PortEvaluation[] = [];
    typingTime;
    evalTypeOptions = [
        { name: '', show: 'Sin filtro' },
        { name: 'ECLOE', show: 'ECLOE' },
        { name: 'EPOE', show: 'EPOE' },
        { name: 'Casos Especiales', show: 'Casos Especiales' },
        { name: '360', show: '360' },
        { name: 'Simulación, cursos o talleres con evaluación', show: 'Simulación, cursos o talleres' }
    ];
    columns = [
        { prop: 'tipo', title: 'Tipo' },
        { prop: 'fecha', title: 'Fecha'},
        { prop: 'anyo', title: 'Año Residencia' },
        { prop: 'evaluador', title: 'Evaluador' },
        { prop: 'metodo', title: 'Método' },
        { prop: 'casoCompetencias', title: 'Caso/Competencias' },
        { prop: 'puntos', title: 'Puntos' },
        { prop: 'nivel', title: 'Nivel' },
        { prop: 'certificado', title: 'Certificado', formatter: v => v ? 'Sí' : 'No' }
    ];
    displayedColumns: string[] = [];
    @ViewChildren(MatPaginator) paginatorList: QueryList<MatPaginator>;

    paginator: MatPaginator;
    paginator2: MatPaginator;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor (
        private evaluationsService: EvaluationService,
        private auth: AuthService,
        private utils: UtilsService,
        private router: Router,
        private alert: AlertService,
        private spinner: SpinnerService
    ) { }

    ngOnInit(): void {

        // const residentId = this.auth.getCurrentUserId();
        this.spinner.show();
        if (this.getRol() === '2') {
            this.evaluationsService.getPortfolioEvaluations().subscribe(items => {
                // console.log(items);
                this.allData = items.evaluaciones;
                // console.log(this.allData);
                this.filteredData = this.allData;
                this.dataLength = items.evaluacionesTotales;
                this.loadEvalPage();
                this.spinner.hide();
            }, error => {
                this.spinner.hide();
            });
        } else if (this.getRol() === '3') {
            this.evaluationsService.residenteConsulta.subscribe(resident => {
                if (resident !== null) {
                    this.evaluationsService.getPortfolioEvaluations(resident.id).subscribe(items => {
                        // console.log(items);
                        this.allData = items.evaluaciones;
                        this.filteredData = this.allData;
                        this.dataLength = items.evaluacionesTotales;
                        this.loadEvalPage();
                        this.spinner.hide();
                    }, error => {
                        this.spinner.hide();
                    });
                } else {
                    this.alert.showError('Se produjo un error.');
                    this.router.navigate(['misresidentes']);
                }
            }).unsubscribe();
        }
        this.displayedColumns = this.columns.map(c => c.prop);
        this.displayedColumns.push('opciones');
    }

    onView(evaluation: PortEvaluation): void {
        const params = {
            id: evaluation.idEvaluacion,
            idTipo: evaluation.idTipo
        };
        this.router.navigate(['stepper'], { queryParams: params });
    }

    render = (row, column) => column.formatter ? column.formatter(row[column.prop]) : row[column.prop];

    ngAfterViewInit(): void {
        this.paginator2 = this.paginatorList.toArray()[0];
        this.paginator = this.paginatorList.toArray()[1];
        merge(this.paginator.page, this.paginator2.page)
        .pipe(
            tap(() => this.loadEvalPage())
        )
        .subscribe();
    }

    onKey(e) {
        if (e.keyCode == 13) {
            this.inputSearch1.nativeElement.blur()
            this.inputSearch2.nativeElement.blur()
        }
    }
    /*loadEvalPage(): void {
        /*
        this.loadDataSourceFormApi(
            this.searchText,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
            *//*
            const pageNumber = this.paginator.pageIndex;
            const pageSize = this.paginator.pageSize;
            const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
            const final = initial + pageSize;
            this.dataSource.data = this.filteredData.slice(initial, final);

    }*/

    loadEvalPage() {
            // this.paginator = this.paginatorList.toArray()[1];
            // this.paginator2 = this.paginatorList.toArray()[0];
            // console.log(this.paginator, this.paginator2);
            let pageNumber = this.paginator.pageIndex;
            let pageSize = this.paginator.pageSize;
            const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
            const final = initial + pageSize;
            
            if ((this.searchMetodo !== '' || this.searchTextCaso !== '') && this.filteredData.length > 0) {
                console.log
                this.dataSource.data = this.filteredData.slice(initial, final);
            } else {
                this.dataSource.data = this.allData.slice(initial, final);
            }
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }

    typingTimer(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchTextCaso.length >= 3 || 
                this.searchMetodo !== undefined  ||
                (this.searchTextCaso === '' && this.searchMetodo === '')) {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                let pageNumber = this.paginator.pageIndex;
                let pageSize = this.paginator.pageSize;
                const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
                const final = initial + pageSize;

                if (this.searchMetodo !== '' && this.searchTextCaso !== '') {
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filteredData = this.allData.filter(r => r.casoCompetencias.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso)).filter(r => r.metodo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()))
                    
                    ;
                }
                
                else if (this.searchTextCaso !== '') {
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filteredData = this.allData.filter(r => r.casoCompetencias.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso));
                } else if (this.searchMetodo !== '') {
                    this.filteredData = this.allData.filter(r => r.metodo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                }



                else {
                    this.filteredData = this.allData;
                }
                this.dataSource.data = this.filteredData.slice(initial, final);
            }
        }, 1000);
    }

    typingTimerInstant(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchTextCaso.length >= 3 || 
                this.searchMetodo !== undefined  ||
                (this.searchTextCaso === '' && this.searchMetodo === '')) {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                let pageNumber = this.paginator.pageIndex;
                let pageSize = this.paginator.pageSize;
                const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
                const final = initial + pageSize;

                if (this.searchMetodo !== '' && this.searchTextCaso !== '') {
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filteredData = this.allData.filter(r => r.casoCompetencias.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso)).filter(r => r.metodo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()))
                    
                    ;
                }
                
                else if (this.searchTextCaso !== '') {
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filteredData = this.allData.filter(r => r.casoCompetencias.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso));
                } else if (this.searchMetodo !== '') {
                    this.filteredData = this.allData.filter(r => r.metodo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                }



                else {
                    this.filteredData = this.allData;
                }
                this.dataSource.data = this.filteredData.slice(initial, final);
            }
        }, 1);
    }

    cleancaso(): void {
        this.searchTextCaso = '';
        this.typingTimerInstant();
    }

    allclean(): void {
        if (this.searchText === '' && this.searchTextCaso === '' && this.searchMetodo === '') {
            this.filteredData = [];
        }
    }

    calculateLength(): number {
        if ((this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== '') && this.filteredData.length > 0) {
            return this.filteredData.length;
        } else if (this.filteredData.length === 0 && (this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== '')){
            return 0;
        } else {
            return this.dataLength;
        }
    }

    syncPages(event, number) {
        if (number === 1) {
            this.paginator2.pageIndex = event.pageIndex;
            this.paginator2.pageSize = event.pageSize;
            this.paginator2.length = event.length;
        } else if (number === 2) {
            this.paginator.pageIndex = event.pageIndex;
            this.paginator.pageSize = event.pageSize;
            this.paginator.length = event.length;
        }
    }

}
