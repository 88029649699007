import { Component, OnInit, Input } from '@angular/core';
import { RegNotebookItem } from '../reg-notebook-tutor.model';

@Component({
  selector: 'app-notebook-items',
  templateUrl: './notebook-items.component.html',
  styleUrls: ['./notebook-items.component.scss']
})
export class NotebookItemsComponent implements OnInit {

    @Input() items: any[] = [];
    @Input() type = '';
    constructor() { }

    ngOnInit(): void {
    }

}
