import { NetworkService } from './../../services/network.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { RegNotebookDomain } from './reg-notebook-tutor-domain.model';

@Injectable({
    providedIn: 'root'
})
export class RegNotebookService {
    
    private entityUrl = `${environment.backendUrl}/cuadernoRegistro`;

    constructor (
        private http: HttpClient,
        private network: NetworkService
    ) {}
    
    getDataTutor(): Observable<RegNotebookDomain[]> {

        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<RegNotebookDomain[]>(`${this.entityUrl}/tutor`);
            } else {
                return of([null]);
            }
        });

        // return this.http.get<RegNotebookDomain[]>(`${this.entityUrl}/tutor`);
    }

}
