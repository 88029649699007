import { StepperTwoComponent } from './step2/stepper-two.component';
import { StepperOneComponent } from './step1/stepper-one.component';
import { ResidentesModule } from './../residentes/residentes.module';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { StepperFormComponent } from './stepper-form.component';
import { MatButtonModule, MatRadioModule, MatCheckboxModule, MatDatepickerModule, MatInputModule , MatRippleModule, MatButtonToggleModule, MatSliderModule, MatIconModule, MatListModule, 
MatMenuModule, MatSelectModule, MatSlideToggleModule, MatTabsModule, MatCardModule, MatStepperModule,
MatTooltipModule, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatTableModule, MatProgressBarModule } from '@angular/material';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';
import { StepperZeroComponent } from './step0/stepper-zero.component';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { StepperThreeComponent } from './step3/stepper-three.component';
import { FileUploadModule } from 'ng2-file-upload';

const routes = [
    {
        path     : 'stepper',
        component: StepperFormComponent,
        // canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        StepperFormComponent,
        StepperZeroComponent,
        StepperOneComponent,
        StepperTwoComponent,
        StepperThreeComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTooltipModule,
        MatTableModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseHighlightModule,
        MatCheckboxModule,
        MatRadioModule,
        MatRippleModule,
        MatInputModule,
        MatSliderModule,
        MaterialFileInputModule,
        MatCardModule,
        MatIconModule,
        MatStepperModule,
        MatDatepickerModule,
        ResidentesModule,
        FileUploadModule,
        MatProgressBarModule
    ],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [ MAT_DATE_LOCALE ] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
    ],
    exports: [
        StepperFormComponent
    ]
})

export class EvaluationModule
{
}
