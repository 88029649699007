import { AlertService } from './../../../services/alert.service';
import { CreateEvaluation } from './../../../models/create-evaluation.model';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, from, Subject, BehaviorSubject } from 'rxjs';
import { ProfItem } from './models/prof-item.model';
import { CobatriceItems } from './models/cobatrice-numbers.model';
import { NetworkService } from 'app/services/network.service';
import { IDbService } from 'app/services/idb.service';
import { Evaluacion } from 'app/models/misevaluaciones.model';

export interface EvaluationPaginated {
    evaluaciones: any[];
    evaluacionesTotales: number;
}

@Injectable({
    providedIn: 'root'
})
export class EvaluationService {
    
    public _pendingEvaluations$ = new Subject<number>();
    readonly pendingEvaluations$ = this._pendingEvaluations$.asObservable()

    private entityUrl = `${environment.backendUrl}/documentos`;
    
    _residenteConsulta: BehaviorSubject<any>;
    residenteConsulta: Observable<any>;
    _tutorPortfolioView: BehaviorSubject<boolean>;
    tutorPortfolioView: Observable<boolean>;
    _tutorStrWeak: BehaviorSubject<boolean>;
    tutorStrWeak: Observable<boolean>;
    headers: HttpHeaders;

    constructor (
        private http: HttpClient,
        private network: NetworkService,
        private dbService: IDbService,
        private alert: AlertService,
        // private idbService: IDbService
    ) {
        this._residenteConsulta = new BehaviorSubject(null);
        this.residenteConsulta = this._residenteConsulta.asObservable();
        this._tutorPortfolioView = new BehaviorSubject(null);
        this.tutorPortfolioView = this._tutorPortfolioView.asObservable();
        this._tutorStrWeak = new BehaviorSubject(null);
        this.tutorStrWeak = this._tutorStrWeak.asObservable();
    }
    
    getProfItems(evTypeId: any): Observable<ProfItem[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<ProfItem[]>(`${this.entityUrl}/getItemsProfesionalismo/${evTypeId}`);
            } else {
                return from(this.dbService.get('prof')).switchMap(itemsProf => {
                    return itemsProf && itemsProf[evTypeId] ? of(itemsProf[evTypeId]) : of([]);       
                });
            }
        });
    }   

    /** 
     * Las Especiales y Simulaciones devuelven directamente las Cobatrices.
     */
    getCobatrices(evTypeId: any): Observable<CobatriceItems> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<CobatriceItems>(`${this.entityUrl}/getItemsProfesionalismo/${evTypeId}`);
            } else {
                return from(this.dbService.get('prof')).switchMap(itemsProf => {
                    return itemsProf && itemsProf[evTypeId] ? of(itemsProf[evTypeId]) : of(null);       
                });
            }
        });
    }

    /** 
     * Obtiene las cobatrices de un elemento de profesionalismo.
     * OJO: si el método de evaluación no contiene los items de profesionalismo, el endpoint "getItemsProfesionalismo"
     * devuelve directamente las cobatrices.
    */
    getCobatricesOfProfItem(profItemId: string): Observable<CobatriceItems> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<CobatriceItems>(`${this.entityUrl}/getCobatrices/${profItemId}`);
            } else {
                return from(this.dbService.get('cobatrices')).switchMap(cobatrices => {
                    return cobatrices && cobatrices[profItemId] ? of(cobatrices[profItemId]) : of(null);
                });
            }
        });
    }   

    getQuestions(idMetodoEval: number): Observable<any[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<any[]>(`${environment.backendUrl}/evaluacion/getQuestions/${idMetodoEval}`);
            } else {
                return from(this.dbService.get('questions')).switchMap(questions => {
                    return questions && questions[idMetodoEval] ? of(questions[idMetodoEval]) : of([]);
                });
            }
        });
    }

    getPreviousLevelxCompetence(idResidente: number, idMetodoEval: number): Observable<any[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                const body = {
                    idResidente: idResidente,
                    idMetodoEval: idMetodoEval
                };
                return this.http.post<any[]>(`${environment.backendUrl}/evaluacion/getPreviousLevelxCompetences`, body, { headers: this.headers });
            } else {
                return from(this.dbService.get('previousLevels')).switchMap(plvls => {
                    return plvls && plvls[idResidente] && plvls[idResidente]['previousLevelsPerCompetences'] ? of(plvls[idResidente]['previousLevelsPerCompetences']) : of([]);
                });
            }
        });
    }

    getPreviousLevel(idResidente: number, idMetodoEval: number): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                const body = {
                    idResidente: idResidente,
                    idMetodoEval: idMetodoEval
                };
                return this.http.post<any>(`${environment.backendUrl}/evaluacion/getPreviousLevel`, body, { headers: this.headers });
            } else {
                return from(this.dbService.get('previousLevels')).switchMap(plvls => {
                    return plvls && plvls[idResidente] && plvls[idResidente]['previousLevelsPerMethod'] && plvls[idResidente]['previousLevelsPerMethod'][idMetodoEval] ? of(plvls[idResidente]['previousLevelsPerMethod'][idMetodoEval]) : of([]);
                });
            }
        });
    }

    getQuestionsPerCompetences(idMetodoEval: number): Observable<any[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<any[]>(`${environment.backendUrl}/evaluacion/getQuestionsPerCompetences/${idMetodoEval}`);
            } else {
                return from(this.dbService.get('questionsPerCompetences')).switchMap(questions => {
                    return questions && questions[idMetodoEval] ? of(questions[idMetodoEval]) : of([]);
                });
            }
        });
    }

    getEvaluacionesCompetenciasPag(filter = null, sortOrder = 'asc', pageNumber = 0, pageSize = 10): Observable<EvaluationPaginated> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
              return this.http.get<EvaluationPaginated>(`${environment.backendUrl}/evaluacion/getEvaluacionesCompetencias`);
            }else {
                return from(this.dbService.get('evalsCompetencias')).switchMap(evals => {
                    if (evals) {
                        return of({
                            evaluaciones: evals.evaluaciones,
                            evaluacionesTotales: evals.evaluacionesTotales
                        });
                    } else {
                        return of({
                            evaluaciones: [],
                            evaluacionesTotales: 0
                        });
                    }
                });
            }
        });

    }

    getEvaluacionesProcedimientosPag(filter = '', sortOrder = 'asc', pageNumber = 0, pageSize = 3): Observable<EvaluationPaginated> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
              return this.http.get<EvaluationPaginated>(`${environment.backendUrl}/evaluacion/getEvaluacionesProcedimientos`);
            }else {
                return from(this.dbService.get('evalsProcedimientos')).switchMap(evals => {
                    if (evals) {
                        return of({
                            evaluaciones: evals.evaluaciones,
                            evaluacionesTotales: evals.evaluacionesTotales
                        });
                    } else {
                        return of({
                            evaluaciones: [],
                            evaluacionesTotales: 0
                        });
                    }
                });
            }
        });
    }

    /** 
     * Si no tenemos conexión, las evaluaciones se van a guardar en Indexed DB para su posterior sincronizado.
    */
    postEvaluacion(evaluation: CreateEvaluation): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.post<any>(`${environment.backendUrl}/evaluacion/createEvaluacion`, evaluation);
            } else {
            return from(this.dbService.get('evaluationsToUpload')).switchMap(evaluations => {
                    evaluations.push(evaluation);
                    this._pendingEvaluations$.next(evaluations.length);
                    this.dbService.set('evaluationsToUpload', evaluations);
                    this.alert.showSuccessToast('Evaluación guardada. Pendiente de sincronización.');
                    return of('Evaluación guardada. Pendiente de sincronización.');
                });
            }
        });
    }
    
    postEvaluacionResidente(evaluation: CreateEvaluation): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.post<any>(`${environment.backendUrl}/evaluacion/createEvaluacionResidente`, evaluation);
            } else {
            return from(this.dbService.get('evaluationsToUpload')).switchMap(evaluations => {
                    evaluations.push(evaluation);
                    this._pendingEvaluations$.next(evaluations.length);
                    this.dbService.set('evaluationsToUpload', evaluations);
                    this.alert.showSuccessToast('Evaluación guardada. Pendiente de sincronización.');
                    return of('Evaluación guardada. Pendiente de sincronización.');
                });
            }
        });
    }

    /** Obtiene las evaluaciones que debe ver el residente en su portfolio. Solo funciona en online. */
    getPortfolioEvaluations = (residentId?: string) => {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                if (residentId) {
                    return this.http.get<any>(`${environment.backendUrl}/evaluacion/getEvaluacionesPortfolio?idResidente=${residentId}`);
                } else {
                    return this.http.get<any>(`${environment.backendUrl}/evaluacion/getEvaluacionesPortfolio`);
                }
            } else {
                return of([]);
            }
        });
        // return this.http.get<any[]>(`${environment.backendUrl}/#`);
    }

    getById(evaluationId: string): Observable<Evaluacion> {
        return this.http.get<Evaluacion>(`${environment.backendUrl}/evaluacion/getEvaluacion/${evaluationId}`);
    }

    emitResidenteConsulta(residente): void {
        this._residenteConsulta.next(residente);
    }

    emitTutorPortfolioView(view): void {
        this._tutorPortfolioView.next(view);
    }

    emitTutorStrWeak(view): void {
        this._tutorStrWeak.next(view);
    }
}
