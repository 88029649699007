<mat-toolbar disableRipple>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex style="padding: 0px 10px">

        <div class="pie-menu" NOfxHide NOfxShow.gt-xs>

                <button disableRipple [matMenuTriggerFor]="evsMenu" class="mat-icon-button" *ngIf="getRol() === '3'">
                    <mat-icon class="mat-icon">done_all</mat-icon>
                    <div class="icon-txt text-align">Mis Evaluaciones</div>
                </button>

                <button [disabled]="!connection" disableRipple routerLink="/miportafolio" class="mat-icon-button" *ngIf="getRol() === '2'">
                    <mat-icon class="mat-icon">personal_video</mat-icon>
                    <div class="icon-txt text-align">Mi Portafolio</div>
                </button>

                <button [disabled]="!connection" disableRipple routerLink="/fortalezas-y-debilidades" class="mat-icon-button" *ngIf="getRol() === '2'">
                    <mat-icon class="mat-icon">error</mat-icon>
                    <div class="icon-txt text-align">Fortalezas y Debilidades</div>
                </button>


                <!--<button routerLink="/misevaluaciones" [queryParams]="{tipo:'procedimientos'}" disableRipple class="mat-icon-button" *ngIf="getRol() === '2'">
                    <mat-icon class="mat-icon">done_all</mat-icon>
                    <div class="icon-txt text-align">Mis Evaluaciones</div>
                </button>-->
                
                <button disableRipple routerLink="/misresidentes" *ngIf="getRol() === '3'" class="mat-icon-button">
                    <mat-icon class="mat-icon">people</mat-icon>
                    <div class="icon-txt text-align">Mis Residentes</div>
                </button>

                <!--<button disableRipple routerLink="/misresidentes" class="mat-icon-button">
                    <mat-icon class="mat-icon">home</mat-icon>
                    <div class="icon-txt text-align">Inicio</div>
                </button>-->

                <button [disabled]="!connection" disableRipple [routerLink]="getRoute()" class="mat-icon-button">
                    <mat-icon class="mat-icon">assignment</mat-icon>
                    <div class="icon-txt text-align">Cuaderno de Registro</div>
                </button>

                <button disableRipple routerLink="/documentos-evaluacion" class="mat-icon-button">
                    <mat-icon class="mat-icon">chrome_reader_mode</mat-icon>
                    <div class="icon-txt text-align">Docs Evaluación</div>
                </button>
                
                <!--<button disableRipple routerLink="/calendar" class="mat-icon-button">
                    <mat-icon class="mat-icon">home</mat-icon>
                    <div class="icon-txt text-align">Home</div>
                </button>-->

               <!-- <button disableRipple routerLink="/rotaciones" class="mat-icon-button">
                    <mat-icon class="mat-icon">refresh</mat-icon>
                    <div class="icon-txt text-align">Rotaciones</div>
                </button> -->


          <!--  <button disableRipple routerLink="/calendar" class="mat-icon-button">
                <mat-icon class="mat-icon"><img src="assets/images/ico_app/calendario.png" /></mat-icon>
                <div class="icon-txt text-align">{{'footer.calendar' | translate}}</div>
            </button>

            <button disableRipple routerLink="/recipes" class=" mat-icon-button">
                <mat-icon class="mat-icon"><img src="assets/images/ico_app/recetas.png" /></mat-icon>
                <div class="icon-txt text-align">{{'footer.recipes' | translate}}</div>
            </button>

            <button disableRipple routerLink="/webinars" class=" mat-icon-button" >
                <mat-icon class="mat-icon"><img src="assets/images/ico_app/webinars.png" /></mat-icon>
                <div class="icon-txt text-align">{{'footer.webminars' | translate}}</div>
            </button>

            <button disableRipple routerLink="/programmeconfig" class=" mat-icon-button" >
                <mat-icon class="mat-icon"><img src="assets/images/ico_app/programa.png" /></mat-icon>
                <div class="icon-txt text-align">{{'footer.programme' | translate}}</div>   
            </button>


            <button disableRipple routerLink="/gallery" class=" mat-icon-button" >
                <mat-icon class="mat-icon"><img src="assets/images/ico_app/galeria.png" /></mat-icon>
                <div class="icon-txt text-align">{{'footer.galery' | translate}}</div>       
            </button>


            <button disableRipple routerLink="/contact" class=" mat-icon-button">
                <mat-icon class="mat-icon"><img src="assets/images/ico_app/contacto.png" /></mat-icon>
                <div class="icon-txt text-align">{{'footer.contact' | translate}}</div>    
            </button> -->


        </div>

    </div>

</mat-toolbar>


<mat-menu #evsMenu="matMenu">
    <button disableRipple mat-menu-item routerLink="/misevaluaciones" [queryParams]="{tipo:'competencias'}">Evaluaciones de casos (ECLOE)</button> <!--Por competencias-->
    <button disableRipple mat-menu-item routerLink="/misevaluaciones" [queryParams]="{tipo:'procedimientos'}">Evaluaciones de procedimientos (EPOE)</button> <!--Por procedimientos-->
    <button [disabled]="!connection" disableRipple mat-menu-item routerLink="/miscertificaciones" [queryParams]="{tipo:'procedimientos'}">Certificaciones</button>
</mat-menu>