import { tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { TaskInfoComponent } from './taskInfo/taskInfo.component';
import { MatDialogRef, MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DialogService } from './../../../services/dialog.service';
import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { RegNotebookService } from './../../register-notebook/reg-notebook.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from '../../../services/spinner.service';
import { RotacionesService } from 'app/services/rotaciones.service';
import { ResidentsService } from 'app/services/residents.service';
import { environment } from 'environments/environment';
import { NetworkService } from 'app/services/network.service';

declare var device;


@Component({
    selector   : 'tareas-pendientes',
    templateUrl: './tareaspendientes.component.html',
    styleUrls  : ['./tareaspendientes.component.scss'],
    animations   : fuseAnimations
})

export class TareasPendientesComponent implements OnInit, AfterViewInit
{
    // profileForm: FormGroup;
    // profile: Profile;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    activate = true;
    dataSource = new  MatTableDataSource<any>([]);
    dialogRef: MatDialogRef<TaskInfoComponent>;
    taskList: any[];
    taskListLength: number;
    displayedColumnsNoAction: string[];
    displayedColumns: string[];
    translate = {
        'fecha': 'Fecha',
        'usuarioAutor': 'Autor',
        'tipoAviso': 'Tipo',
        'asunto': 'Asunto',
    };
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;
    constructor(
        public _domSanitizer: DomSanitizer,
        public userService: UserService,
        public dialog: MatDialog,
        private router: Router
    )
    {
    }

    ngOnInit(): void {
      this.loadFromApi();
    }
    translateColumn(string): string {
        return this.translate[string];
    }

    ngAfterViewInit(): void {
        /*this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadEvalPage())
            )
            .subscribe();
        this.paginator.page
        .pipe(
            tap(() => this.loadEvalPage())
        )
        .subscribe();*/
    }

    loadFromApi(): void {
        this.userService.getTaskList().subscribe(taskList => {
            if (taskList.length === 0) {
                this.router.navigate(['profile']);
            } else {
                this.taskList = taskList;
                this.taskListLength = taskList.length;
                this.displayedColumnsNoAction = ['fecha', 'usuarioAutor', 'tipoAviso', 'asunto'];
                this.displayedColumns = [...this.displayedColumnsNoAction, 'acciones'];
                this.loadEvalPage();
            }
        });
    }

    loadEvalPage(): void {
        /*
        let pageNumber = this.paginator.pageIndex;
        let pageSize = this.paginator.pageSize;
        const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
        const final = initial + pageSize;*/
        this.dataSource.data = this.taskList;
    }

    openTasks = (data) => {
        this.userService.markAsRead(data.idAviso).subscribe((result) => {
            if (result === true) {
                    this.loadFromApi();
            }
        });
        if (this.activate === true) {
            if (!this.isMobileDevice()) {
                // Si no es un dispositivo móvil, usa el alerta con normalidad.
                    this.dialogRef = this.dialog.open(TaskInfoComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => this.activate = false);
                this.dialogRef.afterClosed().subscribe(() => this.activate = true);
            } else {
                // Si es un dispositivo móvil, tenemos que controlar dos casos:
                // 1.   El usuario cierra la alerta mediante los botones de la alerta.
                // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
                // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
                // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

                

                if (typeof device !== 'undefined' && device !== null && typeof device.platform !== 'undefined' && typeof device.platform !== null){
                    document.addEventListener("backbutton", this.onMobileBackButton, false);
                }

                this.dialogRef = this.dialog.open(TaskInfoComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                    data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => {
                    successResult => { this.activate = false; };
                    errorResult => {}
                });
                this.dialogRef.afterClosed().subscribe(() => {
                    document.removeEventListener('backbutton', this.onMobileBackButton, false);
                    this.activate = true;
                });
            }
        }

    }

    onMobileBackButton = () => {
        if (this.dialogRef){
            let dialogRef = this.dialog.open(TaskInfoComponent);
            dialogRef.close();
            this.dialogRef.close();
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }
    
}
