import { AlertService } from 'app/services/alert.service';
import { CreateEvaluation } from './../models/create-evaluation.model';
import { IDbService } from './idb.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OfflineData } from 'app/models/offline-data.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { formatDate } from '@angular/common';
import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { EvaluationResidentService } from 'app/main/pages/stepper-resident/evaluation-resident.service';

/** 
 * Cuando hay conexión, sincroniza los cambios entre Frontend y Backend
*/

@Injectable({
    providedIn: 'root'
})
export class SyncService {

    private getOfflineDataUrl = `${environment.backendUrl}/offline/getData`;
    private postOfflineDataUrl = `${environment.backendUrl}/offline/postData`;
    public lastRefreshDateTime: Date = this.getDateFormatted();
    public _lastRefreshDateTime$ = new BehaviorSubject<Date>(this.lastRefreshDateTime);
    readonly lastRefreshDateTime$ = this._lastRefreshDateTime$.asObservable();

    constructor (
        private http: HttpClient,
        private dbService: IDbService,
        private alert: AlertService,
        private evalService: EvaluationService,
        private evalResidentService: EvaluationResidentService,
        private idbService: IDbService
    ) {
    }

    /** 
     * Llama al macro-endpoint para obtener todos los datos que se van a utilizar en el modo offline.
    */
    loadOfflineData(): Observable<OfflineData> {
        return this.http.get<OfflineData>(this.getOfflineDataUrl);
    }

    /** 
     * Guarda todas las evaluaciones que queden por sincronizar.
     * Puede devolver un array de errores junto a un código 202.
    */
    saveOfflineData(evaluations: any): Observable<any> {
        return new Observable<any>(observer => {
        this.http.post<any>(this.postOfflineDataUrl, evaluations, {observe: 'response'}).subscribe((res) => {
            observer.next(res.status); observer.complete();
        });

        });
    }

   getDateFormatted(): Date{
        if (localStorage.getItem('lastRefreshDateTime') == null || localStorage.getItem('lastRefreshDateTime') == ""){
            return null;
        }
        // console.log(localStorage.getItem('lastRefreshDateTime')  + " Sync service");
        let d = new Date(localStorage.getItem('lastRefreshDateTime'));
        return d;
   }
   formatDate(date: Date) {
    //CHANGE THIS.

    let hours =  (date.getHours()<10?'0':'') + date.getHours() ;
    let day =  (date.getDate()<10?'0':'') + date.getDate() ;
    let minutes =  (date.getMinutes()<10?'0':'') + date.getMinutes() ;
    let month = date.getMonth() +1;
    let fmonth = (month<10 ? '0':'')  + month;
    let year = date.getFullYear();
    let fechaFormateada = `${year}-${fmonth}-${day}T${hours}:${minutes}:00`;
    //let fechaFormateada = `${hours}:${minutes}, ${day}/${month}`;
    localStorage.setItem('lastRefreshDateTime', fechaFormateada);
   }
    /** 
     * Sincroniza los cambios del frontend con el backend.
    */
    syncData(): Observable<boolean> {

        return new Observable(o => {

            // Paso 1: Guardar todos los datos locales que necesiten sincronizarse en el servidor.
            this.dbService.get('evaluationsToUpload').then(evaluations => {
                if (evaluations !== undefined && evaluations.length !== 0) {
                    this.saveOfflineData(evaluations).subscribe(result => {
                        this.evalService._pendingEvaluations$.next(0);//no me odies Carlos
                        this.evalResidentService._pendingEvaluations$.next(0);
                        this.idbService.set("evaluationsToUpload",[]).then(() => {
                        if (result == 200) {
                            this.alert.showSuccess('Evaluaciones guardadas con éxito');
                        }
                        if (result == 202) {
                            this.alert.showWarning('Evaluaciones guardadas, aunque en algunas ha fallado la certificación');
                        }
                        // Paso 2: obtenemos los datos del servidor que utilizaremos de forma offline.
                    
                        this.loadOfflineData().subscribe(data => {
                            // Al recargar los datos, ponemos el array de evaluaciones como vacío (pues ya se han sincronizado)
                            // y volvemos a construir la BD con los nuevos datos.
                            this.dbService.populate({ evaluationsToUpload: [] }, data).then((a) => {
                                // console.log(a);
                                o.next(true); o.complete();
                                this.lastRefreshDateTime = new Date();
                                this._lastRefreshDateTime$.next(this.lastRefreshDateTime);
                            },
                                
                                error => { console.error(error); o.next(false); o.complete(); });
                        }, error => { console.error(error); o.next(false); o.complete(); });});
                    }, error => { console.error(error); o.next(false); o.complete(); });
                } else {
                    this.loadOfflineData().subscribe(data => {
                        // Al recargar los datos, ponemos el array de evaluaciones como vacío (pues ya se han sincronizado)
                        // y volvemos a construir la BD con los nuevos datos.
                        this.dbService.populate({ evaluationsToUpload: [] }, data).then(() => {
                            o.next(true); o.complete();
                            this.lastRefreshDateTime = new Date();
                            this.formatDate(this.lastRefreshDateTime);
                            this._lastRefreshDateTime$.next(this.lastRefreshDateTime);
                        },
                            
                            error => { console.error(error); o.next(false); o.complete(); });
                    }, error => { console.error(error); o.next(false); o.complete(); });
                }
            }, error => { console.error(error); o.next(false); o.complete(); });
        });
    }
}
