import { ConfigService } from '../config.service';
import { Injectable, OnInit } from '@angular/core';

import { AuthService } from './auth.service';
import { CordovaService } from './cordova.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

declare var window;
interface window {
    plugins: any;
}

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    constructor (
        private _cordovaService: CordovaService,
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {}

    show(): void {
        if (this._cordovaService.getDeviceready()) {
            const adviceTitle = 'Cargando, espere';
            window.plugins.spinnerDialog.show(adviceTitle, null, true);
        } else {
            this._fuseSplashScreenService.show();
        }
    }

    hide(): void {
        if (this._cordovaService.getDeviceready()) {
            window.plugins.spinnerDialog.hide();
        } else {
            this._fuseSplashScreenService.hide();
        }
    }
}
