import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  public getSemillaPwd = (idusr) => null;
  public getBaseUrl = () => null;
  public getWordPressUrl = (language) => null;
  public getUsrAdminLogin_wp = (language) => null;
}
