<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    <h3 class="tittle" *ngIf='residente !== null && residente !== undefined'>
        <!--<mat-icon>refresh</mat-icon>-->
        Rotaciones del Residente: {{ residente.nombre_completo }}
    </h3>

   <!-- <div class="filtro" fxLayout="row">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
    </div> -->
    <!-- SEARCH-->
    <div style="max-width: 45%; width:45%; margin-left: 2%">
        <app-search-bar #inputSearch (keydown)="onKey($event)"  [allItems]="allRotaciones" [searchAttributes]="searchAtributes" (search)="onSearchResult($event)"></app-search-bar> 
    </div>

        <mat-table #table matSort [dataSource]="dataSource"
           [@animateStagger]="{value:'50'}">
           <ng-container matColumnDef="year">
                <mat-header-cell *matHeaderCellDef  >Año</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Año:</span>
                        <p>{{rotacion.year}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="hospitalName">
                <mat-header-cell *matHeaderCellDef >Centro</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Centro:</span>
                        <p>{{rotacion.hospitalName}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="service">
                <mat-header-cell *matHeaderCellDef >Rotación</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Rotación:</span>
                        <p>{{rotacion.service}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="rotationTutor">
                <mat-header-cell *matHeaderCellDef >Tutor</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Tutor:</span>
                        <p>{{rotacion.rotationTutor}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <mat-header-cell *matHeaderCellDef >Fecha Inicio</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Fecha Inicio:</span>
                        <p>{{rotacion.startDate | date: 'dd/MM/yyyy'}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <mat-header-cell *matHeaderCellDef >Fecha Fin</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Fecha Fin:</span>
                        <p>{{rotacion.endDate | date: 'dd/MM/yyyy'}}</p>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="evalutationDoc">
                <mat-header-cell *matHeaderCellDef >Documento de Evaluación</mat-header-cell>
                <mat-cell *matCellDef="let rotacion">
                        <span class="mobile-label">Documento de Evaluación:</span>
                        <!-- <p>{{rotacion.evalutationDoc}}</p> -->
                        <img class="pdf" *ngIf="rotacion.evalutationDoc !== null && rotacion.evalutationDoc !== undefined" src='assets/images/pdf.png' (click)='downloadFile(rotacion.evalutationDoc)'>
                            
                </mat-cell>
            </ng-container>
            <!--     class="rotacion"
                     (click)="editContact(contact)"
                     [ngClass]="{'accent-50':checkboxes[rotacion.id]}" -->
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let rotacion; columns: displayedColumns;"
                     matRipple
                     [@animate]="{value:'*',params:{y:'100%'}}">
            </mat-row>
        </mat-table>

       <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
       