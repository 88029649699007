<div class="custom-container" *ngIf="form">
    <div class="row" [formGroup]="form">
        <div class="col-xs-12 col-sm-10 col-sm-offset-1">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Tipo Método</mat-label>
                <mat-select [formControl]="evalTypeControl" [disabled]="true">
                    <mat-option *ngFor="let option of evalTypeOptions" [value]="option.id">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xs-12 col-sm-10 col-sm-offset-1" *ngIf="canShow(fs.ITEMS_PROF) && form.get('idMetodoEval') !== null ">
            <mat-form-field appearance="outline" class="full-width" >
                <mat-label>Ítems de profesionalismo</mat-label>
                <mat-select formControlName="idMetodoEval" [disabled]="true">
                    <mat-option *ngFor="let profItem of profItems" [value]="profItem.id">
                        {{ profItem.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xs-9" *ngIf="canShow(fs.RESIDENTE) && form.get('idResidente') !== null ">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Residente</mat-label>
                <mat-select formControlName="idResidente" disabled="true">
                    <mat-option *ngFor="let resident of residents" [value]="resident.id">
                        {{ resident.nombre_completo }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xs-3" *ngIf="canShow(fs.ANYO) && form.get('anyo') !== null ">
            <mat-form-field appearance="outline"class="full-width">
                <mat-label>Año</mat-label>
                <input matInput formControlName="anyo">
            </mat-form-field>
        </div>
        <div class="col-xs-9" *ngIf="canShow(fs.SERV_HOSP) && form.get('servHosp') !== null ">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Servicio y Hospital</mat-label>
                <input matInput formControlName="servHosp">
            </mat-form-field>
        </div>
        <div class="col-xs-9" *ngIf="canShow(fs.EVALUADOR) && form.get('evaluador') !== null ">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Evaluador</mat-label>
                <mat-select formControlName="evaluador">
                    <mat-option *ngFor="let evaluador of evaluadores" [value]="evaluador.id">
                        {{ evaluador.nombre_completo }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-xs-3" *ngIf="canShow(fs.FECHA) && form.get('fecha') !== null ">
            <mat-form-field (click)="picker.open()" appearance="outline" class="full-width">
                <mat-label>Fecha</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="fecha" readonly>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-xs-12" style="margin-bottom: 10px" >
            <span style="font-size: 14px"><b class="col-xs-5"> Datos de Paciente </b></span>
            <div class="col-xs-12 row" style="align-items: center" *ngIf="canShow(fs.EDAD) && form.get('edadPaciente') !== null ">
                <mat-form-field id="edades" class="col-xs-5" appearance="outline">
                    <mat-label>Edad</mat-label>
                    <input matInput formControlName="edadPaciente"> <!---->
                </mat-form-field>
                <div style="display:flex; flex-direction:column" class="col-xs-3" *ngIf="canShow(fs.SEXO) && form.get('sexoPaciente') !== null ">
                    <mat-radio-group class="col-xs-12" formControlName="sexoPaciente" [disabled]="disableSexo"> <!---->

                        <mat-radio-button value="H" style="margin-right: 6px;">
                                Hombre
                        </mat-radio-button>
                        <mat-radio-button value="M" style="margin-right: 6px;">
                                Mujer
                        </mat-radio-button>
                        <mat-error *ngIf="form.get('sexoPaciente').hasError('required') && form.get('sexoPaciente').touched"> Campo obligatorio </mat-error>
                    </mat-radio-group>
                </div>
                <div style="display:flex; flex-direction:column" class="col-xs-3" *ngIf="canShow(fs.NUEVO) && form.get('nuevo') !== null  && form.get('seguimiento') !== null ">
                    <mat-checkbox matInput class="col-xs-12" formControlName="nuevo" [disabled]="disableNuevo"> Nuevo </mat-checkbox> <!---->
                    <mat-checkbox matInput class="col-xs-12" formControlName="seguimiento" [disabled]="disableSeguimiento"> Seguimiento </mat-checkbox> <!---->
                </div>
            </div>
            <div *ngIf="canShow(fs.COMPLEJIDAD) && form.get('complejidad') !== null ">
                <mat-radio-group class="col-xs-12" formControlName="complejidad" [disabled]="disableComplejidad"> <!---->
                    Complejidad: 
                    <mat-radio-button value="1" style="margin-right: 6px;">
                        Baja
                    </mat-radio-button>
                    <mat-radio-button value="2" style="margin-right: 6px;">
                        Media
                    </mat-radio-button>
                    <mat-radio-button value="3" style="margin-right: 6px;">
                        Alta
                    </mat-radio-button>
                </mat-radio-group>

            </div>
        </div>

        <div class="col-xs-12" *ngIf="canShow(fs.PUESTO_EVAL) && form.get('puestoEvaluador') !== null ">
            <p class="eval-role-label">Puesto Evaluador</p>
            <mat-radio-group formControlName="puestoEvaluador">
                <mat-radio-button
                    *ngFor="let option of evaluatorRole"
                    [value]="option.id"
                    style="margin-right: 6px">
                        {{ option.label }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-xs-12" *ngIf="canShow(fs.RESUMEN_CASO) && form.get('resumenCaso') !== null ">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Resumen del caso</mat-label>
                <textarea matInput formControlName="resumenCaso"></textarea>
                <mat-hint>Máximo 750 caracteres</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-xs-12" *ngIf="canShow(fs.MANEJO_CASO) && form.get('manejoCaso') !== null ">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Manejo del caso</mat-label>
                <textarea matInput formControlName="manejoCaso"></textarea>
                <mat-hint>Máximo 1000 caracteres</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-xs-12" *ngIf="canShow(fs.DISCUSION) && form.get('discusion') !== null ">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Discusión</mat-label>
                <textarea matInput formControlName="discusion"></textarea>
                <mat-hint>Máximo 2250 caracteres</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-xs-12" *ngIf="canShow(fs.CONCLUSION) && form.get('conclusion') !== null ">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Conclusión</mat-label>
                <textarea matInput formControlName="conclusion"></textarea>
                <mat-hint>Máximo 500 caracteres</mat-hint>
            </mat-form-field>
        </div>
        <div class="col-xs-12" *ngIf="canShow(fs.BIBLIOGRAFIA) && form.get('bibliografia') !== null ">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>Bibliografía</mat-label>
                <textarea matInput formControlName="bibliografia"></textarea>
                <mat-hint>Máximo 500 caracteres</mat-hint>
            </mat-form-field>
        </div>

        <div class="col-xs-12" *ngIf="canShow(fs.COBATRICE) && cobatrices">
            <h4 class="eval-cobatrice-label">CoBaTriCE</h4>
            <ul>
                <li *ngFor="let cobatrice of cobatrices.cobatrices">
                    {{ cobatrice.number }}: {{ cobatrice.text }}
                </li>
            </ul>  
        </div>
    
        <div class="col-xs-12">
            <div class="stepper-navigation">
              <!--  <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="goBack()">
                    <mat-icon>arrow_back</mat-icon> Anterior
                </button> -->
                <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="onSubmit()">
                    Siguiente <mat-icon>arrow_forward</mat-icon>
                </button>
            </div>        
        </div>
    </div>
</div>

<!--
    <mat-form-field class="full-width">
        <mat-label>Ítem profesionalismo</mat-label>
        <mat-select formControlName="itemsProfesionalismo">
            <mat-option *ngFor="let item of selectedTipoMetodoEval.items" [value]="item.idMetodoEval">
                {{item.nombre}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
-->