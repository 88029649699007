import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { PortfolioComponent } from './portfolio.component';
import { MatButtonModule, MatRadioModule, MatCheckboxModule, MatInputModule , MatRippleModule, 
MatTableModule, MatButtonToggleModule, MatSliderModule, MatIconModule, MatListModule, MatMenuModule, MatSelectModule, 
MatSlideToggleModule, MatTabsModule, MatCardModule, MatExpansionModule, MatPaginatorModule, MatPaginatorIntl } from '@angular/material';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';
import { PortEvaluationsComponent } from './port-evaluations/port-evaluations.component';
import { PortActivitiesComponent } from './port-activities/port-activities.component';
import { PortProgressComponent } from './port-progress/port-progress.component';
import { ShowActivityModalComponent } from './show-activity-modal/show-activity-modal.component';
import { PortProgressDomainsComponent } from './port-progress-domains/port-progress-domains.component';
import { PortProgressCompetsComponent } from './port-progress-compets/port-progress-compets.component';
import { MatPaginatorIntlEsp } from 'app/MatPaginatorIntlEsp';
import { ActivityInfoComponent } from './port-activities/activityInfo/activityInfo.component';

const routes = [
    {
        path     : 'miportafolio',
        component: PortfolioComponent,
        //canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        PortfolioComponent,
        ActivityInfoComponent,
        PortEvaluationsComponent,
        PortActivitiesComponent,
        PortProgressComponent,
        ShowActivityModalComponent,
        PortProgressDomainsComponent,
        PortProgressCompetsComponent,
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatCardModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseHighlightModule,
        MatCheckboxModule,
        MatRadioModule,
        MatRippleModule,
        MatInputModule,
        MatSliderModule,
        MaterialFileInputModule,
        MatTabsModule,
        MatTableModule,
        MatExpansionModule,
        MatPaginatorModule,
    ],
    exports     : [
        PortfolioComponent
    ],
    entryComponents: [
        ActivityInfoComponent
    ],  
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    ],
})

export class PortfolioModule
{ }
