/** 
 * Representa de forma estática los ids que tiene cada tipo de evaluación en el backend.
 * Dado que el backend devuelve unos datos u otros según el tipo, es necesario que el frontend pueda
 * identificarlos. Además no son datos demasiado cambiantes en el tiempo.
*/
export enum EvaluationTypeIds {
    ECLOE = 1,
    EPOE = 2,
    SPECIAL = 3,
    SIMULATION = 4,
    v360 = 5
}
