import { EvDocService } from './../ev-doc.service';
import { Component, OnInit, Input } from '@angular/core';
import { EvDocProtocol } from '../models/ev-doc-protocol.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-evaluation-protocol',
  templateUrl: './evaluation-protocol.component.html',
  styleUrls: ['./evaluation-protocol.component.scss']
})
export class EvaluationProtocolComponent implements OnInit {

    @Input() idMethodEval: string;
    
    data: EvDocProtocol = null;
    
    constructor (
        private evDocService: EvDocService,
        protected sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.evDocService.getActuationProtocol(this.idMethodEval).subscribe(data => this.data = data);
    }

    sanitizeHtml = (text) => this.sanitizer.bypassSecurityTrustHtml(text);

}
