import { NetworkService } from './../../services/network.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { RegNotebookDomain } from './reg-notebook-domain.model';

@Injectable({
    providedIn: 'root'
})
export class RegNotebookService {
    
    private entityUrl = `${environment.backendUrl}/cuadernoRegistro`;
    residenteConsulta: BehaviorSubject<any>;

    constructor (
        private http: HttpClient,
        private network: NetworkService
    ) {
        this.residenteConsulta = new BehaviorSubject(null);
    }
    
    getDataResident(): Observable<RegNotebookDomain> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<RegNotebookDomain>(`${this.entityUrl}/residente`);
            } else {
                return of(null);
            }
        });

        /*return this.http.get<RegNotebookDomain>(`${this.entityUrl}/residente`);
        */
    }

    getDataForTutor(id): Observable<RegNotebookDomain> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<RegNotebookDomain>(`${this.entityUrl}/getCuadernoRegistro/${id}`);
            } else {
                return of(null);
            }
        });
    }

    emitResidenteConsulta(residente): void {
        this.residenteConsulta.next(residente);
    }
}
