import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import swal from 'sweetalert2';

declare var device;

/** 
 * Represents the input of 'showConfirmWithInput'.
*/
export interface ConfirmTextInput {
    title: string;
    message: string;
    confirmText: string;
}

/**
 * Encapsulates the alerts that are presented to the user.
*/

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    private durationInSeconds = 3;
    private confirmColor = '#26a69a';
    private customConfig = {
        confirmButtonColor: this.confirmColor,
        customClass: 'swal2-custom'
    };
    private customToastConfig = {
        showConfirmButton: false,
        timer: this.durationInSeconds * 1000, // Toasts always have timers.
        toast: true,
        position: 'bottom',
        customClass: {
            content: 'swal2-custom-toast-content',
            icon: 'swal2-custom-toast-icon'
        }
    };

    constructor() {}

    public close = () => {
        swal.close();
    }
    /** 
     * Controla un caso de excepción de dispositivos móviles.
    */
    private showAlert = (config) => {

        if (!this.isMobileDevice()) {
            // Si no es un dispositivo móvil, usa el alerta con normalidad.
            swal.fire(config as any);
        } else {
            // Si es un dispositivo móvil, tenemos que controlar dos casos:
            // 1.   El usuario cierra la alerta mediante los botones de la alerta.
            // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
            // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
            // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

            const onMobileBackButton = () => {
                swal.close();
                window.removeEventListener('backbutton', onMobileBackButton, false);
            };

            document.addEventListener('backbutton', onMobileBackButton, false);

            swal.fire(config as any).then(
                successResult => window.removeEventListener('backbutton', onMobileBackButton, false),
                errorResult => window.removeEventListener('backbutton', onMobileBackButton, false)
            );
        }
    }

    showSuccess = (message: string, title = 'Éxito') => {
        this.showAlert({ ...this.customConfig, text: message, title: title, type: 'success' });
    }

    showError = (message: string, title = 'Error') => {
        this.showAlert({ ...this.customConfig, text: message, title: title, type: 'error' });
    }

    showWarning = (message: string, title = 'Aviso') => {
        this.showAlert({ ...this.customConfig, text: message, title: title, type: 'warning' });
    }

    showValidationErrors = (title: string, messages: string[]) => {
        let messagesHtml = '<ul style="list-style-type:none;">';
        messagesHtml += messages.map(m => `<li> ${m} </li>`);
        messagesHtml += '</ul>';

        const config = {
            ...this.customConfig,
            title: title,
            html: messagesHtml,
            type: 'error' };
        swal.fire(config as any);
    }

    showSuccessToast = (message = 'Operación realizada con éxito.'): void => {
        swal.fire({ ...this.customToastConfig, text: message, type: 'success' } as any);
    }

    showErrorToast = (message: string): void => {
        swal.fire({ ...this.customToastConfig, text: message, type: 'error' } as any);
    }

    showWarningToast = (message: string): void => {
        swal.fire({ ...this.customToastConfig, 
            timer: 2 * this.durationInSeconds * 1000,
            text: message, type: 'warning'
        } as any);
    }
    
    /**
     * Show an alert confirmation and returns the response.
     * The implementation uses the Sweet Alert plugin.
     */
    showConfirm = (data): Observable<boolean> => {
        return new Observable<boolean>(observer => {
            swal.fire({
                title: data.title || 'Precaución',
                text:
                    data.message ||
                    'La acción que intenta es irreversible. ¿Está seguro de que quiere realizarla?',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: this.confirmColor,
                customClass: {
                    content: 'swal2-custom-toast-content',
                },
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar'
            }).then(result => {
                //window.removeEventListener('backbutton', onMobileBackButton, false),
                observer.next(result.value);
                observer.complete();
            });
        });
    }

    /** 
     * Show a confirm alert with a textarea.
    */
    showConfirmWithInput = (data: ConfirmTextInput): Observable<string> => {
        return new Observable<string>(observer => {
            swal.fire({
                title: data.title,
                text: data.message,
                input: 'textarea',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: this.confirmColor,
                cancelButtonColor: '#d33',
                confirmButtonText: data.confirmText,
                cancelButtonText: 'Cancelar',
                customClass: {
                    content: 'swal2-custom-toast-content',
                },
                inputValidator: (value) => {
                    if (!value) {
                      return 'Este campo es obligatorio.';
                    }
                  }  
            }).then(result => {
                observer.next(result.value);
                observer.complete();
            });
        });
    }

    /** 
     * Comprueba si la app se está ejecutando en un dispositivo móvil.
    */
    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }
}
