import { FuseNavigation } from '@fuse/types';


export const navigation: FuseNavigation[] = [

    {
        id       : 'space',
        title    : '',
        type     : 'group'
    },
    {
        id       : 'logout',
        title    : 'Logout',
        translate: 'NAV.LOGOUT.TITLE',
        type     : 'item',
        icon     : 'account_box',
        url      : '/login',
    },    
    {
        id       : 'home',
        title    : 'Home',
        translate: 'NAV.LOGOUT.TITLE',
        type     : 'item',
        icon     : 'account_box',
        url      : '/home',
    },
    {
        id       : 'rotacion',
        title    : 'Rotacion',
        translate: 'Rotaciones',
        type     : 'item',
        icon     : 'refresh',
        url      : '/rotaciones',
    },   
];
