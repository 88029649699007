import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Subject, Observable, from, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'app/api.service';
import { MatStepper } from '@angular/material';
import { NetworkService } from './network.service';
import { IDbService } from './idb.service';


@Injectable({
    providedIn: 'root'
})
export class StepperService {
    token: string='';
    language: string='en';
    public url: string; // = 'http:...';

    public idTipoMetodoEval: string; // Contiene el id.
    public idMetodoEval: string;
    public idResidente: string;
    public nombreEvaluador: string;
    public docEvalFlag: boolean; /* Flag para detectar que viene de documentos de evaluación */
    public form: FormGroup;
    public fechaCert;

    public evalTypeChange$ = new Subject<string>();
    public itemTypeChange$ = new Subject<string>();

    public stepperInfoArray: any[] = [];
    public stepperInfoObject;

    public stepper: MatStepper;

    constructor(public http: HttpClient, private config: ApiService, 
        private network: NetworkService,
        private dbService: IDbService) {
        this.url = this.config.getBackEndUrl();
        if(localStorage){
          this.token = localStorage.getItem('token');
          this.language = localStorage.getItem('language');
        }
    }

    stepperInfoRewrite(info): void {
        this.stepperInfoObject = {...this.stepperInfoObject, ...info};
    }

    stepperInfoPush(info): void {
        this.stepperInfoArray.push(info);
    }

    stepperClearAll(): void {
        this.stepperInfoArray = [];
        this.stepperInfoObject = null;
        this.idTipoMetodoEval = null; /* Esto estaba comentado antes del cambio del 29-01-2020 de acceder desde evaluacion */
        this.idMetodoEval = null;
        this.docEvalFlag = false;
        this.fechaCert = null;
    }

    stepperInfoGetArray(): any[] {
        return this.stepperInfoArray;
    }

    stepperInfoGetObject(): any {
        return this.stepperInfoObject;
    }

    getEvaluacion(idEvaluacion: string, idTipo: string): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                const params = { params: { idTipo: idTipo } };
                return this.http.get(`${this.url}/evaluacion/getEvaluacion/${idEvaluacion}`, params);
            } else {
                return from(this.dbService.get('evals')).switchMap(evals => {
                    return evals && evals[idTipo] && evals[idTipo][idEvaluacion] ? of(evals[idTipo][idEvaluacion]) : of([]);
                });
            }
        });
        
        /*if (this.network.isConnected()) {
            return this.http.get(`${this.url}/evaluacion/getEvaluacion/${id}`);
        } else {
            return from(this.dbService.get('evals')).switchMap(evals => {
                return evals && evals[id]  ? of(evals[id]) : of([]);
            });
        }*/
    }

    getEvaluacionPortfolio(idEvaluacion: string, idTipo: string): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                const params = { params: { idTipo: idTipo } };
                return this.http.get(`${this.url}/evaluacion/getEvaluacionPortfolio/${idEvaluacion}`, params);
            } else {
                return from(this.dbService.get('evals')).switchMap(evals => {
                    return evals && evals[idTipo] && evals[idTipo][idEvaluacion] ? of(evals[idTipo][idEvaluacion]) : of([]);
                });
            }
        });
        
        /*if (this.network.isConnected()) {
            return this.http.get(`${this.url}/evaluacion/getEvaluacion/${id}`);
        } else {
            return from(this.dbService.get('evals')).switchMap(evals => {
                return evals && evals[id]  ? of(evals[id]) : of([]);
            });
        }*/
    }

    setStepper(stepper): void {
        this.stepper = stepper;
    }

    getStepper(): any {
        return this.stepper;
    }
}
