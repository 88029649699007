import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';


export interface NotificationConfig {
    trainTime:boolean,
    noActivity:boolean,
    unreadMessages:boolean,
    news:boolean
  }
  
  export interface LanguageConfig {
    language: string
  }
  
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'privacyPolicy',
    templateUrl: './privacyPolicy.component.html',
    styleUrls  : ['./privacyPolicy.component.scss'],
    animations   : fuseAnimations
})

export class PrivacyPolicyComponent implements OnInit { 

    constructor (
        public dialogRef: MatDialogRef<PrivacyPolicyComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
    }

    close(): void {
        this.dialogRef.close();
    }

}