<div class="row">
    <div class="col-xs-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">                
            
            <ng-container [matColumnDef]="column.prop" *ngFor="let column of columns">
                <th mat-header-cell *matHeaderCellDef>{{ column.title }}</th>
                <td mat-cell *matCellDef="let row">{{ render(row, column) }}</td>
            </ng-container>

            <ng-container matColumnDef="opciones">
                <th mat-header-cell *matHeaderCellDef>Opciones</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-raised-button style="background-color:#428bca; color: white;" (click)="openInfo(row)">
                        <mat-icon>remove_red_eye</mat-icon>
                        Ver
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #paginator [length]="dataLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
    </div>
</div>