import { UtilsService } from './../../../../services/utils.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatDialogRef } from '@angular/material';
import { PortActivity } from '../models/port-activity.model';
import { EvaluationService } from '../../stepper/evaluation.service';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { ActivitiesService } from 'app/services/activities.service';
import { SpinnerService } from 'app/services/spinner.service';
import { tap } from 'rxjs/operators';
import { ActivityInfoComponent } from './activityInfo/activityInfo.component';
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;
declare var device;

@Component({
  selector: 'app-port-activities',
  templateUrl: './port-activities.component.html',
  styleUrls: ['./port-activities.component.scss']
})
export class PortActivitiesComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;

    dataSource = new MatTableDataSource<PortActivity>();
    allData;
    dataLength;
    activate = true;
    columns = [
        { prop: 'nombre', title: 'Nombre' },
        { prop: 'fecha', title: 'Fecha'},
        { prop: 'tipo', title: 'Tipo' },
    ];
    displayedColumns: string[] = [];

    constructor (
        private activitiesService: ActivitiesService,
        private auth: AuthService,
        private utils: UtilsService,
        private evaluationsService: EvaluationService,
        private spinner: SpinnerService,
        public dialog: MatDialog

    ) { }

    ngOnInit(): void {
        // console.log("creandose el portfolio actividades");
        this.spinner.show();

        if (this.getRol() === '2') {
            this.activitiesService.getPortfolioActivities().subscribe((acts) => {
                 //this.dataSource.data = acts
                 this.allData = acts;
                 this.dataLength = acts.length;
                 this.loadEvalPage();
                 this.spinner.hide();
                });
        }
        else if (this.getRol() === '3') {
            this.evaluationsService.residenteConsulta.subscribe((resident) => {
                this.activitiesService.getPortfolioActivities(resident.id).subscribe((acts) => {
                    this.allData = acts;
                    this.dataLength = acts.length;
                    this.loadEvalPage();
                    this.spinner.hide();
                });
            }).unsubscribe();
        }
        this.displayedColumns = this.columns.map(c => c.prop);
        this.displayedColumns.push('opciones');
    }
    ngAfterViewInit(): void {
        this.paginator.page.pipe(tap(() => 
        this.loadEvalPage())).subscribe();
    }
    onView(activity: PortActivity): void {
        const data = { id: activity.id };
    }
    dialogRef:MatDialogRef<ActivityInfoComponent>;
    onMobileBackButton = () => {
        if(this.dialogRef){
            console.log("cerrandose");
            let dialogRef = this.dialog.open(ActivityInfoComponent);
            dialogRef.close();
            this.dialogRef.close();

        }
    }
    openInfo = (data) => {
        // console.log("entrando en el openInfo");
        // console.log(data);
        if (this.activate === true) {
            if (!this.isMobileDevice()) {
                // Si no es un dispositivo móvil, usa el alerta con normalidad.
                this.dialogRef = this.dialog.open(ActivityInfoComponent, {
                    hasBackdrop: false,
                    width: '80vw',
                    data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => this.activate = false);
                this.dialogRef.afterClosed().subscribe(() => this.activate = true);
            } else {
                // Si es un dispositivo móvil, tenemos que controlar dos casos:
                // 1.   El usuario cierra la alerta mediante los botones de la alerta.
                // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
                // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
                // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

                if(typeof device !== 'undefined' && device !== null && typeof device.platform !== 'undefined' && typeof device.platform!==null){
                    // console.log("añadiendo listener");
                    document.addEventListener("backbutton", this.onMobileBackButton, false);
                }

            
                this.dialogRef = this.dialog.open(ActivityInfoComponent, {
                    hasBackdrop: false,
                    width: '80vw',
                    data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => {
                    successResult => { this.activate = false; };
                    errorResult => {};
                });
                this.dialogRef.afterClosed().subscribe(() => {
                 // console.log("eliminando listener");
                 document.removeEventListener('backbutton', this.onMobileBackButton, false);
                 this.activate = true});
            }
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }

    loadEvalPage(): void {
            const pageNumber = this.paginator.pageIndex;
            const pageSize = this.paginator.pageSize;
            const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
            const final = initial + pageSize;
            this.dataSource.data = this.allData.slice(initial, final);
    }   
    getRol(): string {
        return localStorage.getItem('rol');
    }
    render = (row, column) => column.formatter ? column.formatter(row[column.prop]) : row[column.prop];

}
