import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { RotacionesComponent, /*RotacionesViewDialog */} from './rotaciones.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';

import { AuthService } from 'app/services/auth.service';
import { MatDialogModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatIconModule } from '@angular/material';
import { SearchBarModule } from '../search-bar/search-bar.module';

const routes = [
    {
        path     : 'rotaciones',
        component: RotacionesComponent,
       // canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        RotacionesComponent,
        //RotacionesViewDialog
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatIconModule,
        SearchBarModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseHighlightModule,
    ],
    exports     : [
        RotacionesComponent
    ],
    entryComponents: [
        //RotacionesViewDialog
    ]
})

export class RotacionesModule
{
}