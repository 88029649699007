<div class="custom-container">
    <div class="row main-row">
        <div *ngIf="resident !== undefined" style="background-color:#1CAF9A; color:white; font-size: 18px; margin-left: 15px; margin-bottom: 3px; padding: 0px 10px">
            {{resident.residente}}
        </div>
            
        <div class="col-xs-12">
            <mat-card class="separate-bottom" style="width: 100%">
                <mat-card-content>
                        <div class="row" *ngIf="resident !== undefined">
                            <div class ="col-xs-6">
                                <p>Rotación {{resident.rotacion}}</p>
                            </div>
                            <div class ="col-xs-6">
                                <p>Fecha Inicio Residencia {{resident.fechaInicioResidencia}}</p>
                            </div>
                        </div>
                        <div class = "row" *ngIf="resident !== undefined">
                            <div class ="col-xs-6">
                                <p>Tutor {{resident.tutor}}</p>
                            </div>
                            <div class ="col-xs-6">
                                <p>Año {{resident.anyoResidencia}}</p>
                            </div>
                        </div>
                    <button *ngFor="let btn of navButtons" mat-raised-button class="nav-btn separate-btn"
                            (click)="onSelectView(btn.key)"
                            [ngClass]="{ 'nav-btn-selected': viewIs(btn.key) }">
                        {{ btn.text }}
                    </button>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-xs-12" *ngIf="viewIs(portfolioViews.EVALUATIONS)">
            <app-port-evaluations></app-port-evaluations>
        </div>
        <div class="col-xs-12" *ngIf="viewIs(portfolioViews.ACTIVITIES)">
            <app-port-activities></app-port-activities>
        </div>
        <div class="col-xs-12" *ngIf="viewIs(portfolioViews.PROGRESS) && resident !== undefined">  
            <app-port-progress [anyo] = "resident.anyoResidencia"></app-port-progress>
        </div>
    </div>
</div>