import { EvaluationResidentService } from './../evaluation-resident.service';
import { StepperResidentService } from './../../../../services/stepper-resident.service';
import { UtilsService } from './../../../../services/utils.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef, EventEmitter, Output, Input } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import { ToolbarService } from '../../../../layout/components/toolbar/toolbar.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Profile } from '../../../../models/profile';
import { ProfileService } from '../../../../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { ThrowStmt } from '@angular/compiler';
import { MatRadioChange } from '@angular/material';


import { SpinnerService } from '../../../../services/spinner.service';
import { DialogService } from '../../../../services/dialog.service';
import { EvaluationTypeIds } from 'app/models/evaluation-type-ids.enum';

export interface NotificationConfig {
    trainTime: boolean;
    noActivity: boolean;
    unreadMessages: boolean;
    news: boolean;
  }
  
  export interface LanguageConfig {
    language: string;
  }
  
export interface Residentes {
    id: string;
    nombre_completo: string;
    nombre: string;
    apellidos: string;
    centro: string;
    localidad: string;
    provincia: string;
    especialidad: string;
    servicio: string;
    rotandoPor: string;
    anoResidencia: string;
    nombre_completo_tutor: string;
    activo: string;
    imagen: string;
}
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'stepper-two-resident',
    templateUrl: './stepper-two-resident.component.html',
    styleUrls  : ['./stepper-two-resident.component.scss'],
    animations   : fuseAnimations
})
export class StepperTwoResidentComponent implements OnInit
{   
    @Output() complete = new EventEmitter<void>();
    @Output() goback = new EventEmitter<void>();
    @Output() changeSelectedTipoMetodoEval = new EventEmitter();
    @Input() selectedTipoMetodoEval;
    displayedColumns: string[] = ['competencia', 'puntuacion', 'nivelPrevio', 'nivelAdquirido'];
    dataSource;
    isLinear = true;
    form: FormGroup;
    keys: any[];
    idTipoMetodoEval: number;
    nivelMedio = 0;
    nivelPrevioGlobal = 0;
    ayuda = '';
    cobatrice = '';
    stepper: any;

    constructor (
        public _domSanitizer: DomSanitizer,
        public userService: UserService,
        private stepperService: StepperResidentService,
        private evaluationService: EvaluationResidentService,
        private spinner: SpinnerService,
    )
    { }

    ngOnInit(): void {
        this.form = this.stepperService.form;
        this.stepper = this.stepperService.getStepper();
        this.spinner.show();

        const stepperData = this.stepperService.stepperInfoGetObject();
        forkJoin([
            this.evaluationService.getQuestionsPerCompetences(Number(stepperData.idMetodoEval)),
            this.evaluationService.getPreviousLevelxCompetence(Number(this.stepperService.idResidente), Number(stepperData.idMetodoEval)),
            this.evaluationService.getPreviousLevel(Number(this.stepperService.idResidente), Number(stepperData.idMetodoEval))
        ]).subscribe(([relations, previousLevels, previousGlobalLevel]) => {
            
            const auxDataSource = [];
            this.nivelPrevioGlobal = previousGlobalLevel.nivelAnterior;

            relations.forEach(relation => {
                const nivelPrevioInfo = previousLevels.find(t => Number(t.idConocimiento) === Number(relation.cono));
                let nivelPrevio;
                if (nivelPrevioInfo !== undefined && nivelPrevioInfo !== null) {
                    nivelPrevio = nivelPrevioInfo.nivelAnterior;
                } else {
                    nivelPrevio = '0';
                }

                const mean = this.calculateMean(relation);
                const element = {
                    competencia: relation.texto, 
                    puntuacion: mean,
                    nivelPrevio: nivelPrevio,
                    nivelAdquirido: this.calculateLevel(mean)
                };
                auxDataSource.push(element);    
            });
            this.dataSource = auxDataSource;

            this.nivelMedio = !this.isSimulationCase() ? this.calculateAvgLevel() : this.calculateMean(2);
            
            this.spinner.hide();
        });
    }

    onSubmit(): void {
        const values = {};
        values['puntuacion'] = this.showFixed(this.nivelMedio, 2);
        values['nivelAlcanzado'] = this.calculateLevel(this.nivelMedio);

        this.stepperService.stepperInfoRewrite(values);
        this.complete.next();
    }

    isSimulationCase = () => Number(this.stepperService.idTipoMetodoEval) === EvaluationTypeIds.SIMULATION;

    goBack(): void {
        this.stepper.previous();
    }

    extractValues(form: FormGroup, array): number[] {
        const values = [];
        array.forEach(element => values.push(form.get(element).value));
        return values;
    }

    /** 
     * Calcula la media según si el tipo de evaluación es simulado o no.
    */
    calculateMean(relation): number {

        let mean = 0;

        if (!this.isSimulationCase()) {
            // Si no es simulación, la media depende de sus apartados.
            const apartados = relation.idsApartado.split(',');
            const values = this.extractValues(this.form, apartados);
            let i = 0;
            values.forEach(element => {
                if (Number(element) !== 0 && element !== null) {
                    i += 1;
                    mean += Number(element);
                } 
            });
            if (mean !== 0) {
                mean = mean / i;
            }

        } else {
            // En el caso de simulación la media siempre va a depender del fichero (6 si lo tiene, 0 si no).
            const pdfData = this.stepperService.stepperInfoGetObject();
            mean = (pdfData && pdfData['evaluationFile']) ? 6 : 0;
        }
        return mean;
    }

    /** 
     * Calcula el nivel medio para todos los casos menos para el de simulación.
    */
    calculateAvgLevel(): number {
        let i = 0;
        let level = 0;
        Object.keys(this.form.getRawValue()).forEach(key => {
            if (key !== 'disabled') {
                const value = Number(this.form.get([key]).value);
                if (value !== 0 && value !== null) {
                    i += 1;
                    level += value;
                }
            }
        });
        if (level !== 0) {
            level = level / i;
        }
        return level;
    }

    calculateLevel(value: number): number {
        if (!this.isSimulationCase()) {
            if (value < 3) {
                return 1;
            }
            if (value < 4) {
                return 2;
            }
            if (value < 5) {
                return 3;
            }
            return 4;    
        } else {
            // Para el caso de simulación, lo único que influye en los puntos es si tiene el pdf o no.
            // Si tiene pdf la puntuación es 6 (la máxima y por tanto el nivel adquirido es 4)
            // de lo contrario es 0 y el nivel es 0 (aunque se pinte al lado del nivel 1, en bd se guarda 0) porque no llega a 1 punto.
            const pdfData = this.stepperService.stepperInfoGetObject();
            return (pdfData && pdfData['evaluationFile']) ? 4 : 0;
        }
    }

    showFixed(number: number, decimals: number): string {
        return number.toFixed(decimals);
    }
}
