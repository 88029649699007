import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';


  
  export interface LanguageConfig {
    language: string
  }
  
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'activityInfo',
    templateUrl: './activityInfo.component.html',
    styleUrls  : ['./activityInfo.component.scss'],
    animations   : fuseAnimations
})

export class ActivityInfoComponent implements OnInit { 
    downloadUrl = environment.activityUrl;

    constructor (
        public dialogRef: MatDialogRef<ActivityInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}

    ngOnInit(): void {
        // console.log("Modal creandose");
        console.log(this.data);
    }
    openFile(){
        const urlDownload = `${this.downloadUrl}/${this.data.idResidente}/${this.data.pdfCertificado}`;
        this.dialogRef.close();

        window.open(urlDownload, '_system', 'location=yes');
    }

    close(): void {
        this.dialogRef.close();
    }

    isCertificated(data): string {
        return +data.certificado === 1 ? 'Sí' : 'No';
    }
}