<!-- Representación para tablets -->
<div class="medium-display" *ngIf="!items[0].type">
    <div class="row table-header">
        <div class="col-xs-1"><h4><b>Nº</b></h4></div>
        <div class="col-xs-4"><h4><b>Procedimiento</b></h4></div>
        <div class="col-xs-1"><h4><b>R1</b></h4></div>
        <div class="col-xs-1"><h4><b>R2</b></h4></div>
        <div class="col-xs-1"><h4><b>R3</b></h4></div>
        <div class="col-xs-1"><h4><b>R4</b></h4></div>
        <div class="col-xs-1"><h4><b>R5</b></h4></div>
        <div class="col-xs-2"><h4><b>Acciones</b></h4></div>
    </div>
    <div class="row item-row" *ngFor="let item of items">
        <div class="col-xs-1"> {{ item.numero}} </div>
        <div class="col-xs-4"> {{ item.texto }} </div>
        <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R1'}"> {{ item.countPerYear.R1 }} </div></div>
        <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R2'}"> {{ item.countPerYear.R2 }} </div></div>
        <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R3'}"> {{ item.countPerYear.R3 }} </div></div>
        <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R4'}"> {{ item.countPerYear.R4 }} </div></div>
        <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R5'}"> {{ item.countPerYear.R5 }} </div></div>
        <div class="col-xs-2">  <button *ngIf="getRol() === '2'" mat-raised-button class="certButton" (click)="openRequest({idTipoActo: idTipoActo, idActo: item.idConocimiento})">Solicitar certificación</button> </div>
    </div>
</div>
<div class="medium-display" *ngIf="items[0].type">
        <div class="row table-header">
            <div class="col-xs-4 col-xs-offset-1"><h4><b>Tipo</b></h4></div>
            <div class="col-xs-1"><h4><b>R1</b></h4></div>
            <div class="col-xs-1"><h4><b>R2</b></h4></div>
            <div class="col-xs-1"><h4><b>R3</b></h4></div>
            <div class="col-xs-1"><h4><b>R4</b></h4></div>
            <div class="col-xs-1"><h4><b>R5</b></h4></div>
            <div class="col-xs-2"><h4><b>Acciones</b></h4></div>
        </div>
        <div class="row item-row" *ngFor="let item of items">
            <div class="col-xs-4 col-xs-offset-1"> {{ item.type}} </div>
            <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R1'}"> {{ item.countPerYear.R1 }} </div></div>
            <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R2'}"> {{ item.countPerYear.R2 }} </div></div>
            <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R3'}"> {{ item.countPerYear.R3 }} </div></div>
            <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R4'}"> {{ item.countPerYear.R4 }} </div></div>
            <div class="col-xs-1"><div [ngClass]="{'colorYear': year === 'R5'}"> {{ item.countPerYear.R5 }} </div></div>
            <div class="col-xs-2" *ngIf="hardcodeLogic(item)" > 
                <button  *ngIf="getRol() === '2'" mat-raised-button class="certButton" (click)="openRequest({idTipoActo: idTipoActo, idActo: item.idType})">Solicitar certificación</button> </div>
        </div>
    </div>

<!-- Representación para dispositivos pequeños -->
<!--<div class="small-display">
    <div class="row" *ngFor="let item of items">
        <div class="col-xs-4 table-header"><h4><b>Nº</b></h4></div>
        <div class="col-xs-8"> {{ item.number}} </div>

        <div class="col-xs-4 table-header"><h4><b>Procedimiento</b></h4></div>
        <div class="col-xs-8"> {{ item.procedure }} </div>

        <div class="col-xs-4 table-header"><h4><b>R1</b></h4></div>
        <div class="col-xs-8"> {{ item.r1 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R2</b></h4></div>
        <div class="col-xs-8"> {{ item.r2 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R3</b></h4></div>
        <div class="col-xs-8"> {{ item.r3 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R4</b></h4></div>
        <div class="col-xs-8"> {{ item.r4 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R5</b></h4></div>
        <div class="col-xs-8"> {{ item.r5 }} </div>

        <div class="col-xs-4 table-header"><h4><b>Acciones</b></h4></div>
        <div class="col-xs-8"> ? </div>
    </div>
</div>-->
