import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-evaluation-protocol-modal',
  templateUrl: './evaluation-protocol-modal.component.html',
  styleUrls: ['./evaluation-protocol-modal.component.scss']
})
export class EvaluationProtocolModalComponent implements OnInit {

    constructor (
        public dialogRef: MatDialogRef<EvaluationProtocolModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
    ) { }

    ngOnInit(): void {
    }
}
