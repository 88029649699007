import { IDbService } from './../../../../services/idb.service';
import { FuseSplashScreenService } from './../../../../../@fuse/services/splash-screen.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { WebStorageService } from 'app/services/web-storage.service';
import { UserService } from 'app/services/users.service';
import { AuthService } from 'app/services/auth.service';
import { AlertService } from 'app/services/alert.service';
import { SyncService } from 'app/services/sync.service';
export interface LogAnswer {
    token: string;
    idUserType: number;
}

@Component({
    selector     : 'login',
    templateUrl  : './login.component.html',
    styleUrls    : ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})

export class LoginComponent implements OnInit
{
    loginForm: FormGroup;
    spanError = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private webStorageService: WebStorageService,
        private router: Router,
        private authservice: AuthService,
        private alert: AlertService,
        private splashScreen: FuseSplashScreenService,
        private idbService: IDbService,
        private sync: SyncService,
        private alertService: AlertService
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        this.loginForm = this._formBuilder.group({
            login   : ['', [Validators.required]],
            password: ['', [Validators.required]]
        });
    }


    ngOnInit(): void  {
        // console.time("login")
        this.splashScreen.hide();
        // console.timeEnd("login")

    }

    async login() {

        if (this.loginForm.valid) {
            const usuario = this.loginForm.get('login').value;
            const pass = this.loginForm.get('password').value;

            this.authservice.logIn(usuario, pass).subscribe( (respuesta: LogAnswer) => {
                this.webStorageService.setAccessToken(respuesta.token);
                window.localStorage.setItem('rol', String(respuesta.idUserType));
                this.idbService.checkDB().then((active) => {
                    if(!active) {
                        this.alertService.showWarning("No tiene datos sincronizados, recuerde sincronizarlos para un uso offline");
                    }
                if (respuesta.idUserType === 3) { // Si tres, sí
                    this.router.navigate(['profile']);
                } else { // Si no, también
                    this.router.navigate(['cuaderno-registro']); // se acabó el chiste, vuelven a tener entradas separadas
                }
                })
            }, error => {this.alertService.showError(error.error.message)});
        }
    }

    translateidTipoUsuario(idTipoUsuario: number): string {
        switch (idTipoUsuario){
            case 2: {  
                return 'Residente';
            }
            case 3: {
                return 'Tutor';
            }
        } 
    }
}
