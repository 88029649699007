<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}" 
[ngClass]="{'hideMe': dataSource === null || dataSource === undefined}">
    <div class="h2" style="background-color:#d9534f; color: white; width: 100%; padding: 12px 0px 12px 12px">
        Tareas Pendientes
    </div>
    <div class="user-form" style="display: flex; flex-wrap: wrap; justify-content: space-around">
        <mat-table #table [dataSource]="dataSource">
            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumnsNoAction">
                <mat-header-cell *matHeaderCellDef class="mat-center-style">{{translateColumn(column)}}</mat-header-cell>
                <mat-cell *matCellDef="let task" class="mat-center-style" style="width: 100%; min-height: 25px !important; padding-left: 10px !important">
                    {{task[column]}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="acciones">
                <mat-header-cell *matHeaderCellDef class="mat-center-style" style="max-width: 15%">Acciones</mat-header-cell>
                <mat-cell *matCellDef="let task" class="mat-center-style" style="max-width: 15%; min-height: 25px !important; padding-left: 10px !important">
                    <button (click)="openTasks(task)" mat-raised-button style="background-color:#428bca; color: white; margin-top: 3px; max-width: 180px"> 
                        <mat-icon style="color: white; font-size: 15px">remove_red_eye</mat-icon> 
                            Ver
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns" style="width:100%"></mat-header-row>
            <mat-row *matRowDef="let tasks; columns: displayedColumns;" style="width:100%; min-height: 15px !important"
                    matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
            </mat-row>
        </mat-table>
    </div>  
</div>
