import { ConfigService } from '../config.service';
import { Injectable,OnInit } from '@angular/core';
import { Observable } from 'rxjs-compat';

declare let device: any;

declare var window;
interface window {
    cordovaReady: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CordovaService implements OnInit{


  deviceready:boolean=false;
  platform:string='';



  constructor(private configService: ConfigService) {

  }


  ngOnInit() {

  }

  initDeviceInfo(){
    if(typeof device !== 'undefined' && device !== null && typeof device.platform !== 'undefined' && typeof device.platform!==null){
      this.deviceready=true;
      this.platform=device.platform;
    }  
  }

  getDevice():any{
    return device;
  }

  getDeviceready(){
    if((typeof window !== 'undefined' &&  window !== null && typeof window.cordovaReady !== 'undefined' &&  window.cordovaReady !== null && window.cordovaReady==true ) && this.deviceready){
      return true;
    }else {
      return false;
    }  
    //return this.deviceready;
  }

  getPlatform(){
    // Depending on the device, a few examples are:
//   - "Android"
//   - "BlackBerry 10"
//   - "browser"
//   - "iOS"
//   - "WinCE"
//   - "Tizen"
//   - "Mac OS X"
    return this.platform;
  } 
  
   /**
     * 
     */

    /*updateProfile(profile): Observable<any>
    {
        
        let json = JSON.stringify(profile);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
    
        return this._http.post(this.url+this.urlClass+'/'+profile.id+this.urlToken+this.token+this.urlLang+this.language, json, { headers: headers });
        
    }*/

}