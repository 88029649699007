export const locale = {
    lang: 'en',
    data: {
        'cuenta': {
            'cuenta': 'Account',
            'perfil': 'My Profile',
            'programa': 'My Program',
            'cerrarSesion': 'Log Out'
        },

        'otro': {
            'otro': 'Other'
        }
    }
};
