<div id="modal-Events" class="page-layout blank p-10" style="max-height:100vh; overflow-y: scroll; overflow-x: hidden" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    
    <div style="display: flex; justify-content: space-between; align-items: center; background-color:#f0f8ff; margin-bottom: 5px">
        <div style="margin-left: 5px"><b>Datos del Formulario</b></div>
        <button mat-icon-button (click)="close()" disableRipple><mat-icon 
         style="display: flex; align-items: center; justify-content: center;">close</mat-icon></button>
    </div>
   <!-- <h2 class="text-center titulo-pag">Pérfil </h2> -->

    <div style="background-color:#eeeeee">
        <mat-card>
            <mat-card-content>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Asunto:</b></span> {{data.asunto}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Texto:</b></span> {{translate(data.idTipoAviso)}} <a (click)="openRequest(data.idItem)"> Pinche aquí</a>
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Fecha:</b></span> {{data.fecha}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Tipo Aviso:</b></span> {{data.tipoAviso}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Autor:</b></span> {{data.usuarioAutor}}
                </div>
            </mat-card-content>
        </mat-card>

    </div>

    

</div>
