import { ConfigService } from '../config.service';
import { Injectable,OnInit } from '@angular/core';

import { AuthService } from './auth.service'
import { CordovaService } from './cordova.service';	
import { callbackify } from 'util';


declare var navigator:any;
interface navigator {
  notification: any;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService implements OnInit{

  constructor(
    private configService: ConfigService,
    private _cordovaService:CordovaService,
    private _authService:AuthService
    ) {
  }


  ngOnInit() {

  }

  dialog(txt:string, title:string='', btntxt:string='OK'){
    if(this._cordovaService.getDeviceready()){
      navigator.notification.alert(txt, ()=>{}, title, btntxt);
    }else{
      alert(txt);
    }
  }

  confirma(txt:string, title:string=''): Promise<any>{
    return new Promise((resolve, reject) => {
          if(this._cordovaService.getDeviceready()){

                  navigator.notification.confirm(
                    txt,
                    (buttonIndex)=>{
                      if(buttonIndex == 1){
                        resolve(true);
                      }else{
                        resolve(false);
                      }
                    },
                    title,
                    ['OK', 'NO']
                  );


          }else{

                  var r = confirm(txt);
                  if (r == true) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }

          }
      });    
    
  }

  beep(){
    if(this._cordovaService.getDeviceready()){
      navigator.notification.beep(1);
    }
  }

}