<!-- Representación para tablets -->
<div class="medium-display">
    <div class="row table-header">
        <div class="col-xs-6 col-xs-offset-1"><h4><b>Residente</b></h4></div>
        <!--<div class="col-xs-5"><h4><b>Procedimiento</b></h4></div>-->
        <div class="col-xs-1"><h4><b>R1</b></h4></div>
        <div class="col-xs-1"><h4><b>R2</b></h4></div>
        <div class="col-xs-1"><h4><b>R3</b></h4></div>
        <div class="col-xs-1"><h4><b>R4</b></h4></div>
        <div class="col-xs-1"><h4><b>R5</b></h4></div>
        <!--<div class="col-xs-2"><h4><b>Acciones</b></h4></div>-->
    </div>
    <div class="row item-row" *ngFor="let item of items">
        <div class="col-xs-6 col-xs-offset-1"> {{ item.usuario.nombre}} </div>
        <!--<div class="col-xs-5"> {{ item.procedure }} </div>-->
        <div class="col-xs-1"> <div [ngClass]="{'colorYear': item.usuario.anyoResidencia === 'R1'}">{{ item[type].R1 }}</div></div> <!-- asignar clase según R usuario-->
        <div class="col-xs-1"> <div [ngClass]="{'colorYear': item.usuario.anyoResidencia === 'R2'}"> {{ item[type].R2 }} </div></div>
        <div class="col-xs-1"> <div [ngClass]="{'colorYear': item.usuario.anyoResidencia === 'R3'}"> {{ item[type].R3 }} </div></div>
        <div class="col-xs-1"> <div [ngClass]="{'colorYear': item.usuario.anyoResidencia === 'R4'}"> {{ item[type].R4 }} </div></div>
        <div class="col-xs-1"> <div [ngClass]="{'colorYear': item.usuario.anyoResidencia === 'R5'}"> {{ item[type].R5 }} </div></div>
        <!--<div class="col-xs-2"> ? </div>-->
    </div>
</div>

<!-- Representación para dispositivos pequeños -->
<!--<div class="small-display">
    <div class="row" *ngFor="let item of items">
        <div class="col-xs-4 table-header"><h4><b>Residente</b></h4></div>
        <div class="col-xs-8"> {{ item.usuario.nombre}} </div>

        <div class="col-xs-4 table-header"><h4><b>Procedimiento</b></h4></div>
        <div class="col-xs-8"> {{ item.procedure }} </div>

        <div class="col-xs-4 table-header"><h4><b>R1</b></h4></div>
        <div class="col-xs-8"> {{ item.r1 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R2</b></h4></div>
        <div class="col-xs-8"> {{ item.r2 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R3</b></h4></div>
        <div class="col-xs-8"> {{ item.r3 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R4</b></h4></div>
        <div class="col-xs-8"> {{ item.r4 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R5</b></h4></div>
        <div class="col-xs-8"> {{ item.r5 }} </div>

        <div class="col-xs-4 table-header"><h4><b>Acciones</b></h4></div>
        <div class="col-xs-8"> ? </div>
    </div>
</div>-->
