import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { NetworkService } from '../network.service';
import { WebStorageService } from '../web-storage.service';
import { AlertService } from '../alert.service';
import { AuthService } from '../auth.service';
import { throwError } from 'rxjs';

@Injectable()
export class ResponseInterceptor /*implements HttpInterceptor*/ {

    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor (
        private _network: NetworkService,
        private router: Router,
        private webStorageService: WebStorageService,
        private alertService: AlertService,
        private authService: AuthService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Muestra un mensaje si no hay conexión a internet.
        // Ya no hace falta mostrarlo aquí porque tenemos un observable en AppComponent.
        /*
        if (!this._network.isConnected()) {
            this.alertService.showWarning(
                'Su dispositivo se ha desconectado de internet temporalmente y podría no acceder a los datos necesarios para usar esta aplicación correctamente'
            );
        }
        */

        // Añadimos el accessToken automáticamente.
        const accessToken = this.webStorageService.getAccessToken();
        if (accessToken) {
            request = request.clone({
                // url: request.url.split('?')[0],
                setParams: { access_token: accessToken }
            });
        }

        return next.handle(request).catch(
            // (event: HttpEvent<any>) => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    console.error(err);
                    // console.log("estoy aqui donde el error de código extrño ");

                    switch (err.status) {

                        case 401: 
                            
                            // Si ocurre un error de autenticación, redirigimos al login.
                            this.webStorageService.setAccessToken(null);
                            if (request.url.includes('/login')) {
                                this.alertService.showErrorToast('Usuario o contraseña incorrectos.');
                            } else {
                                //Aquí debemos rehacer el login
                                //NUEVO
                                const password = localStorage.getItem("password");
                                const login = localStorage.getItem("login");
                                if (password == undefined || password == null || password == "" || 
                                    login == undefined || login == null || login ==""){
                                    this.router.navigate(['/login']); 
                                    this.alertService.showErrorToast('Debe hacer login en la aplicación.');
                                    return throwError(err);
                                }
                                else {
                                    if (this.refreshTokenInProgress) {
                                        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                                        // – which means the new token is ready and we can retry the request again
                                        return this.refreshTokenSubject
                                            .filter(result =>  result !== null)
                                            .take(1)
                                            .switchMap(() => {
                                                return next.handle(this.addAuthenticationToken(request));
                                            });
                                    } else {
                                      this.refreshTokenSubject.next(null);
                                      this.refreshTokenInProgress = true;
                                      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          
                                      // Call auth.refreshAccessToken(this is an Observable that will be returned)
                                        return this.httpRefreshAccessToken(login, password)
                                          .switchMap((token: any) => {
                                              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                              // for the next time the token needs to be refreshed
                                              this.webStorageService.setAccessToken(token.token);
                                              // localStorage.setItem('accesstoken', token.token);
                                              //localStorage.setItem('idProgramme',token.idPrograma);
                                              this.refreshTokenInProgress = false;

                                              this.refreshTokenSubject.next(token);
          
                                              return next.handle(this.addAuthenticationToken(request));
                                          })
                                          .catch((err: any) => {
                                              this.refreshTokenInProgress = false;
                                             
                                              this.authService.logout();
                                              return throwError(err);
                                          }); // .subscribe();
          
          
                                    }
                                }
                                //
                            }
                          //  this.router.navigate(['login']);
                            break;

                        case 403: 
                            // Errores de autorización.
                            this.alertService.showError('No tiene permiso para realizar esa acción.');
                            break;

                        case 422:
                            // Errores de validación.
                            this.alertService.showError('Los datos introducidos no son válidos.');
                            /*
                            const valErrors: ApiValidationMessage = err.error;
                            if (!valErrors.errors) {
                                // Si solo hay un mensaje, lo mostramos.
                                this.alertService.showValidationErrors(valErrors.message, []);
                            } else {
                                const arrayOfArrays = Object.values(valErrors.errors);
                                let messages = [];
                                arrayOfArrays.forEach(array => messages = [ ...messages, ...array ] );
                                this.alertService.showValidationErrors(valErrors.message, messages);
                            }*/
                            break;
                       // case 202: this.alertService.showError('Error'); break;
                        case 500:
                        case 503:
                            // Errores de servidor.
                            this.alertService.showError('Ha ocurrido un error inesperado por parte del servidor. Contacte con el administrador.');
                            break;

                        default:
                            return throwError(err);
                            //const message = err.error.message !== undefined ? err.error.message : err.message;
                            //this.alertService.showError(message);
                    }
                }
        });
    }

    /* if (this.refreshTokenInProgress) {
        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // – which means the new token is ready and we can retry the request again
        return this.refreshTokenSubject
            .filter(result => result !== null)
            .take(1)
            .switchMap(() => next.handle(this.addAuthenticationToken(request)));
        } else {
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            return this.httpRefreshAccessToken(seed)
                .switchMap((token: any) => {
                    //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                    // for the next time the token needs to be refreshed
                    // console.log(token)
                    // console.log(token.token)
                    localStorage.setItem('token',token.token);
                    localStorage.setItem('idProgramme',token.idPrograma);
                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(token);
    
                    return next.handle(this.addAuthenticationToken(request));
                })
                .catch((err: any) => {
                this.refreshTokenInProgress = false;
                this._authService.logout();
                    return Observable.throw(err);
                });
                }*/
    /*  }else{
                return Observable.throw(err);
            }*/

            
    httpRefreshAccessToken(login, password): any {
        return this.authService.logIn(login, password);
    }

    addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // Get access token from Local Storage
        const accessToken = this.webStorageService.getAccessToken();

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            // url: request.url.split('?')[0], 
            setParams: { access_token: accessToken }
        });
    }
}
