import { AlertService } from 'app/services/alert.service';
import { UtilsService } from './../../../services/utils.service';
import { UserService } from './../../../services/users.service';
import { AuthService } from './../../../services/auth.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatStepper } from '@angular/material';
import { Component, OnInit, Input, Inject, ViewChild } from '@angular/core';
// import { RegNotebookItem } from '../reg-notebook.model';
import * as moment from 'moment';

export interface CertificateModel {
    idTipoActo: number;
    idActo: number;
    idUsuarioCertificador: number;
    fechaSolicitud: any;
    fechaRealizacion: any;
    numerosActosRealizados: number;
    login: string;
    password: string;
    numerosActosCertificados: number;
}
@Component({
  selector: 'app-certificate-request',
  templateUrl: './certificate-request.component.html',
  styleUrls: ['./certificate-request.component.scss']
})
export class CertificateRequestComponent implements OnInit {
    formStepOne: FormGroup;
    formStepTwo: FormGroup;
    isLinear = true;
    tutorList: any[];
    dataToSend: CertificateModel;
    certificado;
    @ViewChild('stepper') stepper: MatStepper;

    constructor(public dialogRef: MatDialogRef<CertificateRequestComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private auth: AuthService,
        private fb: FormBuilder,
        private userService: UserService,
        private utils: UtilsService,
        private alert: AlertService) { }

    ngOnInit(): void {
        // console.log('items', this.items);
        // console.log(typeof this.year);
        // console.log(this.data);
        if (this.userService.getRol() === '2' && typeof this.data != 'string') {
            this.userService.getTutores().subscribe((tutores) => {
                this.tutorList = tutores;
                this.formBuild();
            });
        } else if (this.userService.getRol() === '3' || typeof this.data== 'string') {
            this.formBuild();
        }

    }

    close(): void {
        this.dialogRef.close();
    }

    formBuild(): void {
        const V = Validators;
        if (this.userService.getRol() === '2' && typeof this.data != 'string') {
            this.formStepOne = this.fb.group({
                'residente': [this.auth.getCurrentUserName(), [V.required]], 
                'idUsuarioCertificador': [this.tutorList[0].id, [V.required]],
                'fechaSolicitud': [new Date(), [V.required]],
                'numerosActosRealizados': [null, [V.required, V.max(127)]],
                'fechaRealizacion': [null, [V.required]]
            });
            this.formStepTwo = this.fb.group({
                'login': [null,[]],
                'password': [null, []],
                'numerosActosCertificados': [this.formStepOne.get('numerosActosRealizados').value, [V.required, V.max(127)]]
            });
        } else if (this.userService.getRol() === '3' || typeof this.data== 'string') {
            this.userService.getCertificacion(this.data).subscribe(respuesta => {
                this.certificado = respuesta.certificado;
                this.formStepOne = this.fb.group({
                    'residente': [respuesta.residente, [V.required]], 
                    'idUsuarioCertificador': [respuesta.evaluador, [V.required]],
                    'fechaSolicitud': [moment(respuesta.fechaSolicitud, 'DD/MM/YYYY'), [V.required]],
                    'numerosActosRealizados': [respuesta.solicitadas, [V.required, V.max(127)]],
                    'fechaRealizacion': [moment(respuesta.fechaRealizacion, 'DD/MM/YYYY'), [V.required]]
                });
                this.formStepTwo = this.fb.group({
                    'numerosActosCertificados': [respuesta.solicitadas, [V.required, V.max(respuesta.solicitadas)]]
                });
            });
        }
        /*this.form.addControl('residente', new FormControl(this.auth.getCurrentUserName(), [ V.required ]));
        this.form.addControl('evaluador', new FormControl('Pitingo', [V.required]));*/
    }
    goBack(): void {
        this.stepper.previous();
    }

    next(): void {
        this.formStepTwo.get('numerosActosCertificados').setValue(this.formStepOne.get('numerosActosRealizados').value);
        this.stepper.next();
    }

    onSubmit(): void {
        if(this.getRol() === '2') {
            if (this.formStepOne.valid && this.formStepTwo.valid) {
                this.dataToSend = {...this.data, ...this.formStepOne.getRawValue(), ...this.formStepTwo.getRawValue()};
                this.dataToSend.fechaSolicitud = moment(this.dataToSend.fechaSolicitud).format('DD/MM/YYYY');
                this.dataToSend.fechaRealizacion = moment(this.dataToSend.fechaRealizacion).format('DD/MM/YYYY');
                // console.log('This is the data that will be send to the Backend: ', this.dataToSend);
                this.userService.certificate(this.dataToSend).subscribe(res => {
                    console.log(res);
                    if (res.status === 200 || res.status === 202) {
                        this.alert.showSuccess(res.body);
                        this.dialogRef.close();
                    } else {
                        this.alert.showError('Se ha producido un error.');
                        this.dialogRef.close();
                    }
                });
            } else {
                this.utils.validateAllFormFields(this.formStepOne);
                this.utils.validateAllFormFields(this.formStepTwo);
            }
        } else if (this.getRol() === '3') {
            if (this.formStepOne.valid && this.formStepTwo.valid) {
            this.userService.certificatePut(
                {idCertificacion: this.data, numeroActosCertificados: this.formStepTwo.get('numerosActosCertificados').value})
                .subscribe(response => {
                    this.alert.showSuccess('Certificado con éxito.');
                    this.dialogRef.close();
                }, error => this.alert.showError('Se ha producido un error.'));
            } else {
                this.utils.validateAllFormFields(this.formStepOne);
                this.utils.validateAllFormFields(this.formStepTwo);
            }
        }
    }
    getRol2 = () => {
        let rol = this.userService.getRol();
        if(rol == '2' && typeof this.data == 'string'){
            return '3';
        }
        else {
            return this.userService.getRol();
        }
    }
    getRol = () => this.userService.getRol();
    cantEdit(): boolean {
        if (this.userService.getRol() === '3') {
            return true;
        } else if (this.userService.getRol() === '2') {
            return false;
        }
    }
    viewStr() {
        let rol = this.userService.getRol();

        if(rol == '2' && typeof this.data == 'string'){
            return true;
        }
        else {
            return false;
        }
    }
    isCertified(): boolean {
        if (this.certificado === '1') {
            return true;
        } else {
            return false;
        }
    }
}
