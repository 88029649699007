<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}"
[formGroup]="form" *ngIf="form !== undefined && form !== null">
    <ng-container>
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px; background-color:#428BCA; color:white;" mat-raised-button (click)="goBack()">
                Anterior
            </button>
           <!-- <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="onSubmit()">
                Siguiente
            </button> -->
        </div>
        <div style="background-color:#FAEBD7; padding-left:2%"> COMENTARIOS Y PLAN 
        </div>
        <fieldset style="width: 99.9%; height:30%; border-radius: 7px; padding: 0.1em 0.75em 0.525em !important;">
            <legend>Aspectos Positivos</legend>
            <textarea formControlName="comentariosPositivos" [readonly]='isView' matInput 
            rows="5"
            style="display: flex; flex-direction: column; width: 100%; height: 100%; resize: none">
            </textarea>
        </fieldset>
        <fieldset style="width: 99.9%; height:30%; border-radius: 7px;padding: 0.1em 0.75em 0.525em !important;">
            <legend>Aspectos a mejorar</legend>
            <textarea formControlName="areasMejora" [readonly]='isView' matInput 
            rows="5"
            style="display: flex; flex-direction: column; width: 100%; height: 100%; resize: none">
            </textarea>
        </fieldset>
        <fieldset style="width: 99.9%; height:30%; border-radius: 7px;padding: 0.1em 0.75em 0.525em !important;">
            <legend>Plan</legend>
            <textarea formControlName="plan" [readonly]='isView' matInput 
            rows="5"
            style="display: flex; flex-direction: column; width: 100%; height: 100%; resize: none">
            </textarea>
        </fieldset>
        <ng-container *ngIf="metodoEvalComparator === evalType.ECLOE || metodoEvalComparator === evalType.EPOE">
            <div style="display: flex; justify-content: space-between">
                <fieldset style="width: 23vw; border-radius: 7px;padding: 0.1em 0.75em 0.525em !important;">
                    <legend>Nivel de satisfacción del Residente con este ejercicio</legend>
                    <mat-select formControlName = "satisfacionResidente" [disabled]='isView'>
                        <mat-option *ngFor="let option of valueOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </fieldset>
                <fieldset style="width: 23vw; border-radius: 7px;padding: 0.1em 0.75em 0.525em !important;">
                    <legend>Nivel de satisfacción del Tutor con este ejercicio</legend>
                    <mat-select formControlName = "satisfacionEvaluador" [disabled]='isView'>
                        <mat-option *ngFor="let option of valueOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </fieldset>
                <fieldset [ngClass]="{'redError': form.get('minutosObservacion').hasError('required') && form.get('minutosObservacion').touched}" style="width: 23vw; border-radius: 7px;padding: 0.1em 0.75em 0.525em !important;">
                    <legend>Tiempo (minutos) empleados en observación directa</legend>
                    <input matInput type="number" formControlName = "minutosObservacion" [readonly]="isView" min="0">
                </fieldset>
                <fieldset [ngClass]="{'redError': form.get('minutosEvaluacion').hasError('required') && form.get('minutosEvaluacion').touched}" style="width: 23vw; border-radius: 7px;padding: 0.1em 0.75em 0.525em !important;">
                    <legend>Tiempo (minutos) empleados en el análisis de la actuación</legend>
                    <input matInput type="number" formControlName = "minutosEvaluacion" [readonly]='isView' min="0">
                </fieldset>
            </div>
            <div style="display: flex;">
            </div>
        </ng-container>
        <fieldset *ngIf="!isView" style="width: 99.9%; height:30%; border-radius: 7px; padding: 0.1em 0.75em 0.525em !important;">
            <legend>Firma del residente</legend>
            <mat-form-field appearance="outline" style="margin-right: 6px">
                <mat-label>Usuario</mat-label>
                <input  matInput formControlName="login" autocorrect="off" autocapitalize="none" autocomplete="off">
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>
                    Usuario requerido
                </mat-error>
            </mat-form-field>
<!--   -->
            <mat-form-field appearance="outline">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password"  formControlName="password">
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error>                            
                    Contraseña requerida
                </mat-error>
            </mat-form-field>
        </fieldset>
        <div style="background-color:#FAEBD7; padding-left:2%; margin-top: 7px;" *ngIf="fechaCert"> Fecha de Certificación: {{fechaCert}}
        </div>
        <div fxLayout='row' style="display:flex; padding-top:5px; justify-content: space-between">
            <div style="display:flex; padding-bottom:5px; padding-top: 5px; justify-content: space-between" *ngIf='!isView'>
                <button style="margin-right:5px; background-color:#17A08C; color:white;" mat-raised-button (click)="onSubmit()">
                    Guardar
                </button>
            </div>
            <div (click)="exitView()" style="display:flex; padding-bottom:5px; padding-top: 5px; justify-content: space-between" *ngIf='isView'>
                <button style="margin-right:5px; background-color:#17A08C; color:white;" mat-raised-button>
                    Salir
                </button>
            </div>
           <!-- <div style="display:flex; padding-bottom:5px; justify-content: space-between">
                <button style="margin-right:5px; background-color:#428BCA; color:white;" mat-raised-button (click)="goBack()">
                    Anterior
                </button> 
            </div>-->
        </div>
    </ng-container>
</div>
