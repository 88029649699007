import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { NetworkService } from 'app/services/network.service';
import { ConnectionService } from 'ng-connection-service';
import { AlertService } from 'app/services/alert.service';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent  implements OnInit
{
    /**
     * Constructor
     */
    public connection = true;
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private networkService: NetworkService,
        private alertService: AlertService

    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    }
    ngOnInit(): void {
        this.networkService.connectionChange$.subscribe((connection) => {
            this.connection = connection;
        })
    }
    /*
    checkClick(){
        if(!this.connection){
            this.alertService.showErrorToast("Pantalla no disponible sin conexión")
        }
    }
    */
    getRoute(): string {
        const rol = this.getRol();
        if (rol === '2') {
            return '/cuaderno-registro';
        } else if (rol === '3') {
            return '/cuaderno-registro-tutor';
        }
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }
}
