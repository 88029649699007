import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { MenuComponent } from './menu.component';
import { AuthService } from 'app/services/auth.service';

import { MatButtonModule, MatButtonToggleModule, MatIconModule, MatListModule, MatMenuModule, MatSelectModule, MatSlideToggleModule, MatTabsModule } from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';


const routes = [
    {
        path     : 'menu',
        component: MenuComponent,
        canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        MenuComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseHighlightModule
       
    ],
    exports     : [
        MenuComponent
    ]
})

export class MenuModule
{
}
