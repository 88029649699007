import { NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatProgressSpinnerModule, MatBadgeModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent, SyncroModalComponent } from 'app/layout/components/toolbar/toolbar.component';

@NgModule({
    declarations: [
        ToolbarComponent,
        SyncroModalComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        MatProgressSpinnerModule,
        MatBadgeModule

    ],
    exports     : [
        ToolbarComponent,
        SyncroModalComponent,
    ],

    entryComponents: [
        SyncroModalComponent
    ]
})
export class ToolbarModule
{
}
