import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MatIconModule, MatTableModule, MatCheckboxModule, MatMenuModule, MatButtonModule, MatToolbarModule, MatInputModule, MatCardModule, MatSelectModule, MatStepperModule, MatPaginatorModule, MatSortModule, MatDialogModule } from '@angular/material';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SearchBarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NgxChartsModule,
    MatTableModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    FormsModule,

  ],
  exports: [
    SearchBarComponent
  ]
})
export class SearchBarModule { }
