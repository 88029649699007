<div id="modal-Events" class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    
    <div style="display: flex; justify-content: space-between; align-items: center; background-color:#f0f8ff; margin-bottom: 5px">
        <div style="margin-left: 5px"><b>Datos del Formulario</b></div>
        <button mat-icon-button (click)="close()" disableRipple><mat-icon 
         style="display: flex; align-items: center; justify-content: center;">close</mat-icon></button>
    </div>
   <!-- <h2 class="text-center titulo-pag">Pérfil </h2> -->

    <div style="background-color:#eeeeee">
        <mat-card>
            <mat-card-content>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Tipo Actividad:</b></span> {{data.tipoActividad}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Nombre:</b></span> {{data.nombre}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Fecha:</b></span> {{data.fecha}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Duración:</b></span> {{data.duracion}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Lugar:</b></span> {{data.lugar}}
                </div>
                <div style="display: flex; max-height: 19px">
                    <span class="col-xs-5"><b>PDF Certificado: </b></span> <a *ngIf="data.pdfCertificado!=null" (click)="openFile()">Ver Certificado</a>
                        <p *ngIf="data.pdfCertificado==null"></p>
                </div>
                <div style="display: flex" *ngIf="+data.idTipoActividad === 3">
                    <span class="col-xs-5"><b>Certificado:</b></span> {{isCertificated(data)}}
                </div>
                <div style="display: flex" *ngIf="+data.idTipoActividad === 3">
                    <span class="col-xs-5"><b>Certificador:</b></span> {{data.apellidosCertificador}}, {{data.nombreCertificador}}
                </div>
            </mat-card-content>
        </mat-card>

    </div>

    

</div>
