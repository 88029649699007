<div *ngIf="hasError()" class="error">
  <span class="span-error">
    <strong> {{ message.message }} </strong>
  </span>
</div>
<div *ngIf="hasSuccess()" class="success">
  <span class="span-error">
    <strong> {{ message.message }} </strong>
  </span>
</div>
