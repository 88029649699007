import { CertificateRequestComponent } from './../../../register-notebook/certificate-request/certificate-request.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';


export interface NotificationConfig {
    trainTime:boolean,
    noActivity:boolean,
    unreadMessages:boolean,
    news:boolean
  }
  
  export interface LanguageConfig {
    language: string
  }
  
  

declare let window: any;
declare let device: any;
@Component({
    selector   : 'certificateInfo',
    templateUrl: './certificateInfo.component.html',
    styleUrls  : ['./certificateInfo.component.scss'],
    animations   : fuseAnimations
})

export class CertificateInfoComponent implements OnInit { 

    constructor (
        public dialogRef: MatDialogRef<CertificateInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
        console.log(this.data);
    }

    close(): void {
        this.dialogRef.close();
    }

    translate(string: string): string {
        const number = Number(string);
        switch (number) {
            case 0:
                return 'No';
            case 1:
                return 'Si';
        }
    }
    openRequest(data: object): void {
        this.dialogRef.close();
        if (!this.isMobileDevice()) {
            // Si no es un dispositivo móvil, usa el alerta con normalidad.
            const dialogRef = this.dialog.open(CertificateRequestComponent, {
                hasBackdrop: false,
                width: '80vw',
                data: data,

            });
        } else {
            // Si es un dispositivo móvil, tenemos que controlar dos casos:
            // 1.   El usuario cierra la alerta mediante los botones de la alerta.
            // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
            // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
            // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

            const onMobileBackButton = () => {
                dialogRef.close();
                window.removeEventListener('backbutton', onMobileBackButton, false);
            };

            document.addEventListener('backbutton', onMobileBackButton, false);
            const dialogRef = this.dialog.open(CertificateRequestComponent, {
                hasBackdrop: false,
                width: '80vw',
                data: data,
            });
            dialogRef.afterOpened().subscribe(() => {
                successResult => {window.removeEventListener('backbutton', onMobileBackButton, false);};
                errorResult => window.removeEventListener('backbutton', onMobileBackButton, false);
            });
        }
    }

    onMobileBackButton = () => {
        if (this.dialogRef){
            let dialogRef = this.dialog.open(CertificateRequestComponent);
            dialogRef.close();
            this.dialogRef.close();
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }
    getRol(): string {
        return localStorage.getItem('rol');
    }
    isCertified(): boolean {
        if (this.data.certificado === '1' ||  this.getRol() === '2')  {
            return true;
        } else {
            return false;
        }
    }

}
