<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}"
[formGroup]="form" *ngIf="form !== undefined && form !== null">
    <ng-container *ngIf="!isSimulationCase()">
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px;" class="next-btn" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button class="next-btn" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
        <div *ngIf="!is360()">
            <mat-radio-group *ngFor="let key of keys" [formControlName]="key.key" [disabled]="disableRadioButtons" style="display: flex; flex-direction: column">
                <mat-label style="background-color:#D0E0D0; padding-left: 1%">{{key.text}}
                    <!--<mat-icon [matTooltip]="cobatrice" matTooltipPosition="right">info</mat-icon>
                    <mat-icon [matTooltip]="ayuda" matTooltipPosition="right">help</mat-icon>-->
                </mat-label>
                    <span>
                        <mat-radio-button value=1>1</mat-radio-button>
                        <mat-radio-button value=2>2</mat-radio-button>
                        <mat-radio-button value=3>3</mat-radio-button>
                        <mat-radio-button value=4>4</mat-radio-button>
                        <mat-radio-button value=5>5</mat-radio-button>
                        <mat-radio-button value=6>6</mat-radio-button>
                        <mat-radio-button value=0>N/A</mat-radio-button>
                    </span>
                    <mat-error *ngIf="form.controls[key.key].invalid && form.controls[key.key].touched">
                        Este campo es obligatorio
                    </mat-error>
            </mat-radio-group>
        </div>
        <div *ngIf="is360()">
            <mat-radio-group *ngFor="let key of keys" [formControlName]="key.key" [disabled]="disableRadioButtons" style="display: flex; flex-direction: column">
                <mat-label style="background-color:#D0E0D0; padding-left: 1%">{{key.text}}
                    <!--<mat-icon [matTooltip]="cobatrice" matTooltipPosition="right">info</mat-icon>
                    <mat-icon [matTooltip]="ayuda" matTooltipPosition="right">help</mat-icon>-->
                </mat-label>
                    <span>
                        <mat-radio-button value=2>
                            Preocupación importante al respecto
                        </mat-radio-button>
                        <mat-radio-button value=4>
                            Alguna preocupación al respecto
                        </mat-radio-button>
                        <mat-radio-button value=6>
                            Totalmente de acuerdo
                        </mat-radio-button>
                    </span>
                    <mat-error *ngIf="form.controls[key.key].invalid && form.controls[key.key].touched">
                        Este campo es obligatorio
                    </mat-error>
            </mat-radio-group>
        </div>
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px" class="next-btn" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button class="next-btn" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
    </ng-container>
    <!--<ng-container *ngIf="idTipoMetodoEval === 5">
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px; background-color:#428BCA; color:white;" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
        <mat-radio-group formControlName="button" style>
            <mat-label>Array Pregunta</mat-label>
                <mat-radio-button value=2>
                    Preocupación importante al respecto
                </mat-radio-button>
                <mat-radio-button value=4>
                    Alguna preocupación al respecto
                </mat-radio-button>
                <mat-radio-button value=6>
                    Totalmente de acuerdo
                </mat-radio-button>
        </mat-radio-group>
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px; background-color:#428BCA; color:white;" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
    </ng-container>-->
</div>

<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}" *ngIf="isSimulationCase()">
    <ng-container>
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px" class="next-btn" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button class="next-btn" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
        <div *ngIf="disableRadioButtons" style="padding-bottom: 12px">
            <h3>PDF Certificado:<a *ngIf="evFile!=null" (click)="openFile()">Link pdf certificado</a>
                <p *ngIf="evFile==null"> Documento no disponible en este momento </p>
            </h3>
        </div>
        <div *ngIf="!disableRadioButtons" style="padding-bottom: 12px">
            <h3>PDF Certificado:</h3>
            <div *ngIf="!fileIsSelected()">
                <input
                  type="file"
                  ng2FileSelect
                  [uploader]="uploader"
                  [disabled]="disableRadioButtons"
                  (onFileSelected)="onFileDrop($event)"
                />
            </div>
            <div style="width: 100%" *ngIf="uploader.progress !== 0">
                <mat-progress-bar mode="determinate" [value]="uploader.progress"></mat-progress-bar>
            </div>
            <div *ngIf="fileIsSelected()">
                <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 95%; margin-left: 6px">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Nombre</th>
                        <td mat-cell *matCellDef="let file">{{ file.name }}</td>
                    </ng-container>
                    <ng-container matColumnDef="size">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Tamaño</th>
                        <td mat-cell *matCellDef="let file">{{ file.sizeKb }} KB</td>
                    </ng-container>
                    <ng-container matColumnDef="options">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Opciones</th>
                        <td mat-cell *matCellDef="let file">
                        <button mat-raised-button (click)="emptyQueue()"><mat-icon>cancel</mat-icon> Eliminar</button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
        <div style="display:flex; justify-content: space-between">
            <button style="margin-right:5px" class="next-btn" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button class="next-btn" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
    </ng-container>
</div>
