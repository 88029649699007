import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { RegNotebookService } from './../../register-notebook/reg-notebook.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from '../../../services/spinner.service';
import { RotacionesService } from 'app/services/rotaciones.service';
import { ResidentsService } from 'app/services/residents.service';
import { environment } from 'environments/environment';
import { NetworkService } from 'app/services/network.service';

export interface NotificationConfig {
    trainTime: boolean;
    noActivity: boolean;
    unreadMessages: boolean;
    news: boolean;
  }
  
  export interface LanguageConfig {
    language: string;
  }
  
export interface Residentes {
    id: string;
    nombre_completo: string;
    nombre: string;
    apellidos: string;
    centro: string;
    localidad: string;
    provincia: string;
    especialidad: string;
    servicio: string;
    rotandoPor: string;
    anoResidencia: string;
    nombre_completo_tutor: string;
    activo: string;
    imagen: string;
}
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;


@Component({
    selector   : 'residentes-ppal',
    templateUrl: './residentes.component.html',
    styleUrls  : ['./residentes.component.scss'],
    animations   : fuseAnimations
})

export class ResidentesComponent implements OnInit
{
    // profileForm: FormGroup;
    // profile: Profile;
    @ViewChild('inputSearch') inputSearch;

    urlBaseImages:string;
    selectedLanguage: any;
    selfieImage: any;
    // profileUrl: String[];
    notificationConfigForm: FormGroup;
    notificationConfig: NotificationConfig = {
        trainTime: true,
        noActivity: true,
        unreadMessages: true,
        news: true
    };
    residents: any[];
    allResidents: any[];
    languageConfigForm: FormGroup;
    languageConfig: LanguageConfig = {
        language: 'es'
    };
    connection: boolean;
    searchText: string = '';
    typingTime;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        public _domSanitizer: DomSanitizer,
        public userService: UserService,
        private rotacionesService: RotacionesService,
        private router: Router,
        private spinner: SpinnerService,
        private residentesService: ResidentsService,
        private networkService: NetworkService,
        private regService: RegNotebookService,
        private evalService: EvaluationService
    )
    {
    }

    ngOnInit(): void {
        this.networkService.isConnected().subscribe(conn => this.connection = conn);
        this.networkService.connectionChange$.subscribe((connection) => {
            this.connection = connection;
        });
        this.urlBaseImages =  environment.imagesResidentsUrl;
        // this.profileUrl = ['../../../../assets/images/avatars/residentes'];
        this.spinner.show();
        this.residentesService.getAllActive().subscribe( residents => {
            this.allResidents = residents;
            this.residents = residents;
            this.spinner.hide();
        });
    }

    mostrarRotaciones(residente): void {
        this.rotacionesService.emitResidente(residente);
        this.router.navigate(['rotaciones']);
    }
    onKey(e) {
        if (e.keyCode == 13) {
            this.inputSearch.nativeElement.blur()

        }
    }
    clearSearch(): void {
        this.searchText = '';
        this.onSearch();
    }

    onSearch(): void { 
        this.residents = this.allResidents.filter(t => t.nombre_completo.includes(this.searchText) === true );
    }

    typingTimer(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchText === '') {
                const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                this.residents = this.allResidents.filter(t => t.nombre_completo.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                .includes(searchText));
            }
        }, 1000);
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }

    goRegisterNotebook(resident): void {
        this.regService.emitResidenteConsulta(resident);
        this.router.navigate(['cuaderno-registro']);
    }

    goPortfolio(resident): void {
        this.evalService.emitResidenteConsulta(resident);
        this.router.navigate(['miportafolio']);
    }

    goStrengths(resident): void {
        this.evalService.emitResidenteConsulta(resident);
        this.router.navigate(['fortalezas-y-debilidades']);
    }
}
