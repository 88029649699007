import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  private backendUrl = environment.backendUrl;

  private fileUploadEndpoint = '/media/documents/upload';

  getBackEndUrl() {
    return this.backendUrl;
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.backendUrl + '/login/', { login: username, password: password})
      .map(user => {
        if (user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return username;
      });
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
  
  getFileUploadEndpoint() {
    return this.backendUrl + this.fileUploadEndpoint;
  }

}
