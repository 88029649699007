import { AlertService } from 'app/services/alert.service';
import { ResidentsService } from 'app/services/residents.service';
import { SpinnerService } from './../../../../services/spinner.service';
import { EvaluationService } from './../evaluation.service';
import { EvaluationTypeIds } from './../../../../models/evaluation-type-ids.enum';
import { StepperService } from './../../../../services/stepper.service';
import { UtilsService } from './../../../../services/utils.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, Validators, FormControl, FormGroup} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Resident } from 'app/models/resident.model';
import { AuthService } from 'app/services/auth.service';
import { FormSection } from '../models/form-section.enum';
import { ProfItem } from '../models/prof-item.model';
import { CobatriceItems } from '../models/cobatrice-numbers.model';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

export interface Residentes {
    id: string;
    nombre_completo: string;
    nombre: string;
    apellidos: string;
    centro: string;
    localidad: string;
    provincia: string;
    especialidad: string;
    servicio: string;
    rotandoPor: string;
    anoResidencia: string;
    nombre_completo_tutor: string;
    activo: string;
    imagen: string;
}
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'stepper-zero',
    templateUrl: './stepper-zero.component.html',
    styleUrls  : ['./stepper-zero.component.scss'],
    animations   : fuseAnimations
})
export class StepperZeroComponent implements OnInit
{   
    @Output() complete = new EventEmitter<void>();
    @Output() goback = new EventEmitter<void>();
    isLinear = true;
    viewMode = false;
    loaded = false;
    // Form control que nos ayuda a escuchar los cambios del tipo de evaluación.
    evalTypeControl: FormControl;
    
    // Los posibles tipos de evaluación.
    evalTypeOptions = [
        { id: EvaluationTypeIds.ECLOE, name: 'ECLOE' },
        { id: EvaluationTypeIds.EPOE, name: 'EPOE' },
        { id: EvaluationTypeIds.SPECIAL, name: 'Casos Especiales' },
        { id: EvaluationTypeIds.v360, name: '360º' },
        { id: EvaluationTypeIds.SIMULATION, name: 'Simulaciones, cursos o talleres' }
    ];
    // El tipo de evaluación seleccionado actualmente.
    selectedEvalType = this.stepperService.idTipoMetodoEval === undefined ? EvaluationTypeIds.ECLOE : Number(this.stepperService.idTipoMetodoEval);

    form: FormGroup = new FormGroup({});

    fs = FormSection;

    // Opciones de los select
    evaluatorRole = [
        { id: '1', label: 'Médico' },
        { id: '2', label: 'Enfermero/a' },
        { id: '3', label: 'Auxiliar Enfermería' },
        { id: '4', label: 'Auxiliar Administrativo' }
    ];
    profItems: ProfItem[] = [];
    residents: Resident[] = [];
    cobatrices: CobatriceItems = null;

    alwaysShowFields = {};
    showHideMatrix = {};
    evaluacion;
    formConfig = [];

    sub;
    idEvaluacion = null;
    tipoEval = null;
    idTipo: string = null; // El tipo de evaluación que enviamos para obtener la evaluación actual, si es que existe
    
    disableIdResidente: boolean = false;
    disableSexo: boolean = false;
    disableComplejidad: boolean = false;
    disableNuevo: boolean = false;
    disableSeguimiento: boolean = false;
    disableMetodoEval: boolean = false;
    docEvalFlag = this.stepperService.docEvalFlag;
    createNew = true;
    stepper: any;

    constructor (
        public domSanitizer: DomSanitizer,
        public userService: UserService,
        private fb: FormBuilder,
        private utils: UtilsService,
        private stepperService: StepperService,
        private evaluationService: EvaluationService,
        private spinner: SpinnerService,
        private residentsService: ResidentsService,
        private auth: AuthService,
        private _route: ActivatedRoute,
        private alert: AlertService
    )
    {
        const et = EvaluationTypeIds;
        const fs = FormSection;

        // Estos campos se muestran en todos los tipos de evaluación.
        this.alwaysShowFields = {
            [fs.RESIDENTE]: true,
            [fs.ANYO]: true,
            [fs.SERV_HOSP]: true,
            [fs.EVALUADOR]: true,
            [fs.FECHA]: true,
            [fs.COBATRICE]: true
        };

        // Declaramos, para cada tipo de evaluación, qué campos del form hay que considerar.
        this.showHideMatrix = {
            [et.v360]: {
                ...this.alwaysShowFields,
                [fs.ITEMS_PROF]: true,
                [fs.PUESTO_EVAL]: true,
            },
            [et.SPECIAL]: {
                ...this.alwaysShowFields,
                [fs.RESUMEN_CASO]: true,
                [fs.MANEJO_CASO]: true,
                [fs.DISCUSION]: true,
                [fs.CONCLUSION]: true,
                [fs.BIBLIOGRAFIA]: true     
            },
            [et.ECLOE]: {
                ...this.alwaysShowFields,
                [fs.EDAD]: true,
                [fs.SEXO]: true,
                [fs.NUEVO]: true,
                [fs.ITEMS_PROF]: true,
                [fs.DATOS_PACIENTE]: true,
                [fs.COMPLEJIDAD]: true,
            },
            [et.EPOE]: {
                ...this.alwaysShowFields,
                [fs.EDAD]: true,
                [fs.SEXO]: true,
                [fs.NUEVO]: true,
                [fs.ITEMS_PROF]: true,
                [fs.DATOS_PACIENTE]: true,
                [fs.COMPLEJIDAD]: true,
            },
            [et.SIMULATION]: {
                ...this.alwaysShowFields,
            }
        };
    }

    ngOnInit(): void {
        // Inicializamos el tipo de método de evaluación
        const methodEval = this.stepperService.idTipoMetodoEval;
        if (methodEval === null || methodEval === undefined) {
            this.evalTypeControl = new FormControl(null, [ Validators.required ]);
            this.selectedEvalType = EvaluationTypeIds.ECLOE;
        } else {
            this.evalTypeControl = new FormControl(Number(methodEval), [ Validators.required ]);
            this.selectedEvalType = Number(methodEval);
        }
              
        this.stepperService.evalTypeChange$.next(String(this.selectedEvalType));

        this.idEvaluacion = this._route.snapshot.queryParamMap.get('id');
        this.tipoEval = this._route.snapshot.queryParamMap.get('tipo');
        this.idTipo = this._route.snapshot.queryParamMap.get('idTipo');
        // CREAR EVALUACIÓN
        if (this.idEvaluacion === null) {
            this.residentsService.getAllActive().subscribe(residents => this.residents = residents);
            this.buildForm();

            // Carga los datos cada vez que cambia.
            this.evalTypeControl.valueChanges.subscribe(current => {
                this.selectedEvalType = current;
                this.stepperService.idTipoMetodoEval = current;
                this.stepperService.evalTypeChange$.next(current);
                this.buildForm();
            });

        // VISUALIZAR EVALUACIÓN
        } else {
            this.spinner.show();
            if (this.idTipo === '1' || this.idTipo === '3'){
                this.residentsService.getAllResidents().subscribe(residents => {
                    this.residents = residents;
                    this.stepperService.getEvaluacionPortfolio(this.idEvaluacion, this.idTipo).subscribe(evaluacion => {
                        this.evaluacion = evaluacion;
                        this.stepperService.fechaCert = evaluacion.fechaCertificado != null ? moment(evaluacion.fechaCertificado).format('DD/MM/YYYY') : null;
                        // console.log(this.stepper.fechaCert);
                        // console.log(moment(evaluacion.fechaCertificado).format('DD-MM-YYYY'));
                        // Tipo Eval
                        this.stepperService.idTipoMetodoEval = this.evaluacion.idTipoMetodoEval;
                        this.evalTypeControl.setValue(+this.evaluacion.idTipoMetodoEval);
                        this.stepperService.idResidente = this.evaluacion.idResidente;
                        this.selectedEvalType = this.evaluacion.idTipoMetodoEval;
                        this.stepperService.stepperInfoPush(+this.evaluacion.idTipoMetodoEval);
                        this.stepperService.evalTypeChange$.next(this.evaluacion.idTipoMetodoEval);
                        this.evalTypeControl.disable();

                        this.buildForm(this.evaluacion);

                        this.form.controls['idResidente'].setValue(this.evaluacion.idResidente);
                        this.spinner.hide();
                    });
                });
            } else {
                this.residentsService.getAllResidents().subscribe(residents => {
                    this.residents = residents;
                    this.stepperService.getEvaluacion(this.idEvaluacion, this.idTipo).subscribe(evaluacion => {
                        this.evaluacion = evaluacion;
                        this.stepperService.fechaCert = evaluacion.fechaCertificado != null ? moment(evaluacion.fechaCertificado).format('DD/MM/YYYY') : null;
                        // Tipo Eval
                        this.stepperService.idTipoMetodoEval = this.evaluacion.idTipoMetodoEval;
                        this.evalTypeControl.setValue(+this.evaluacion.idTipoMetodoEval);
                        this.stepperService.idResidente = this.evaluacion.idResidente;
                        this.selectedEvalType = this.evaluacion.idTipoMetodoEval;
                        this.stepperService.stepperInfoPush(+this.evaluacion.idTipoMetodoEval);
                        this.stepperService.evalTypeChange$.next(this.evaluacion.idTipoMetodoEval);
                        this.evalTypeControl.disable();

                        this.buildForm(this.evaluacion);

                        this.form.controls['idResidente'].setValue(this.evaluacion.idResidente);
                        this.spinner.hide();
                    });
                });
            }
        }
        this.stepper = this.stepperService.getStepper();
    }

    /** 
     * Utiliza la matriz de secciones que podemos mostrar para decir si podemos mostrar cierta 
     * sesión para el tipo de evaluación actual.
    */
    canShow = (fs: FormSection) => this.showHideMatrix[this.selectedEvalType][fs] === true;

    /** 
     * Renderiza un formulario con diferentes campos según el tipo de evaluación actual.
    */
    buildForm(evaluacion?: any): void {
        const fs = FormSection;
        const V = Validators;
        this.cobatrices = null;
        // Reseteamos el form.
        this.form = new FormGroup({});
        if (evaluacion) {
            if (this.canShow(fs.ITEMS_PROF)) {
                this.form.addControl('idMetodoEval', new FormControl(evaluacion.idMetodoEval, [ V.required ]));
                this.disableMetodoEval = true;
                // Actualizamos los datos compartidos por todos los pasos del stepper cada vez que el item de prof cambia.
                this.evaluationService.getCobatricesOfProfItem(this.form.get('idMetodoEval').value).subscribe(data => {
                    this.cobatrices = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                /*this.form.get('idMetodoEval').valueChanges.subscribe(professionalismId => {
                    this.stepperService.idTipoMetodoEval = this.evalTypeControl.value;
                    this.stepperService.idMetodoEval = this.form.get('idMetodoEval').value;
                    this.stepperService.evalTypeChange$.next();
                    this.stepperService.itemTypeChange$.next(professionalismId);
        
                    // Cuando cambia el item de profesionalismo, cambian las cobatrices.
                    // console.log("Carga cobatrices a partir del item de profesionalismo del método de evaluación")
                    this.spinner.show();
                    this.evaluationService.getCobatricesOfProfItem(professionalismId).subscribe(data => {
                        this.cobatrices = data;
                        this.spinner.hide();
                    }, () => this.spinner.hide());
                });*/
        
                // Cargamos los items de profesionalismo.
                this.spinner.show();
                this.profItems = [];
                this.evaluationService.getProfItems(this.selectedEvalType).subscribe(data => {
                    this.profItems = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
            } else {
                // Si no hay items de profesionalismo, directamente obtenemos las cobatrices.
                // Solo va a entrar aquí si es de tipo SPECIAL o SIMULATION.
                this.spinner.show();
                // console.log("Carga cobatrices a partir del método de evaluación")
                this.evaluationService.getCobatrices(this.selectedEvalType).subscribe(data => {
                    this.cobatrices = data;
                    this.stepperService.idMetodoEval = data['idMetodo'];
                    this.spinner.hide();
                }, () => this.spinner.hide());
            }
            let values = this.stepperService.stepperInfoGetObject();
            if (values == undefined){
                values = [];
            } else {
                if(values['oldStepper'] != undefined){
                    this.stepperService.stepperClearAll();
                }
            }
            if (values['cobatrices']!= undefined){
                this.cobatrices = values['cobatrices'];
            }
            if (this.canShow(fs.RESIDENTE)) {
                this.form.addControl('idResidente', new FormControl(evaluacion.idResidente != undefined ? evaluacion.idResidente : null, [ V.required ]));
                this.disableIdResidente = true;
            }
            const resident = this.residents.find(r => r.id === evaluacion.idResidente);
            if (this.canShow(fs.ANYO)) {
                this.form.addControl('anyo', new FormControl(resident != undefined ? 'R' + resident.anoResidencia : null, [ V.required ]));
                this.form.get('anyo').disable();
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.SERV_HOSP)) {
                this.form.addControl('servHosp', new FormControl(resident != undefined ? `${resident.servicio} - ${resident.centro}` : null, [ V.required ]));
                this.form.get('servHosp').disable();
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.EVALUADOR)) {
                this.form.addControl('evaluador', new FormControl(evaluacion.nombreTutor != null ? evaluacion.nombreTutor : null, [ V.required ]));
                this.form.get('evaluador').disable();
            }
    
            if (this.canShow(fs.FECHA)) {
                this.form.addControl('fecha', new FormControl(evaluacion.fecha != undefined ? evaluacion.fecha : new Date(), [ V.required ]));
                this.form.controls['fecha'].disable();
            }
    
            if (this.canShow(fs.PUESTO_EVAL)) { //?
                this.form.addControl('puestoEvaluador', new FormControl(evaluacion.puestoEvaluador != undefined ? evaluacion.puestoEvaluador : null, [ V.required ]));
                this.form.controls['puestoEvaluador'].disable();
            }
    
            if (this.canShow(fs.RESUMEN_CASO)) {
                this.form.addControl('resumenCaso', new FormControl(evaluacion.resumenCaso != undefined ? evaluacion.resumenCaso : null, [ V.required ]));
                this.form.controls['resumenCaso'].disable();
            }
    
            if (this.canShow(fs.MANEJO_CASO)) {
                this.form.addControl('manejoCaso', new FormControl(evaluacion.manejoCaso != undefined ? evaluacion.manejoCaso : null, [ V.required ]));
                this.form.controls['manejoCaso'].disable();
            }
    
            if (this.canShow(fs.DISCUSION)) {
                this.form.addControl('discusion', new FormControl(evaluacion.discusion != undefined ? evaluacion.discusion : null, [ V.required ]));
                this.form.controls['discusion'].disable();
            }
    
            if (this.canShow(fs.CONCLUSION)) {
                this.form.addControl('conclusion', new FormControl(evaluacion.conclusion != undefined ? evaluacion.conclusion : null, [ V.required ]));
                this.form.controls['conclusion'].disable();
            }
    
            if (this.canShow(fs.BIBLIOGRAFIA)) {
                this.form.addControl('bibliografia', new FormControl(evaluacion.bibliografia != undefined ? evaluacion.bibliografia : null, [ V.required ]));
                this.form.controls['bibliografia'].disable();
            }
    
            /*if (this.canShow(fs.DATOS_PACIENTE)) {
                this.form.addControl('datosPaciente', new FormControl(evaluacion.datosPaciente != undefined ? evaluacion.datosPaciente : null, [ V.required ]));
                this.form.controls['datosPaciente'].disable();
            }*/
    
            if (this.canShow(fs.COMPLEJIDAD)) {
                this.form.addControl('complejidad', new FormControl(evaluacion.complejidad != undefined ? evaluacion.complejidad : 0, [ V.required ]));
                this.disableComplejidad = true;
            }
    
            // En base al residente seleccionado se autocompletan algunos campos.
            // Ponemos este código después de todos los addControls para asegurarnos que existen.
            if (this.canShow(fs.RESIDENTE)) {
                this.form.get('idResidente').valueChanges.subscribe(residentId => {
                    this.stepperService.idResidente = residentId;
                    const resident = this.residents.find(r => r.id === residentId);
                    if (resident) {
                        if (this.canShow(fs.ANYO)) {
                            this.form.get('anyo').setValue(`R${resident.anoResidencia}`);
                        }
                        if (this.canShow(fs.SERV_HOSP)) {
                            this.form.get('servHosp').setValue(`${resident.servicio} - ${resident.centro}`);
                        }
                    }
                    
                });
            }
    
            if (this.canShow(fs.EDAD)) {
                this.form.addControl('edadPaciente', new FormControl(+evaluacion.edadPaciente != undefined ? +evaluacion.edadPaciente : null, [ V.required ]));
                this.form.controls['edadPaciente'].disable();
            }
    
            if (this.canShow(fs.SEXO)) {
                this.form.addControl('sexoPaciente', new FormControl(evaluacion.sexoPaciente != undefined ? evaluacion.sexoPaciente : null, [ V.required ]));
                this.disableSexo = true;
            }
    
            if (this.canShow(fs.NUEVO)) {
                this.form.addControl('nuevo', new FormControl(+evaluacion.nuevo != 0 ? true : false, [ V.required ]));
                this.disableNuevo = true;
                this.form.addControl('seguimiento', new FormControl(+evaluacion.seguimiento != 0 ? true : false, [ V.required ]));
                this.disableSeguimiento = true;
            }
        } else {
            if (this.canShow(fs.ITEMS_PROF)) { // implementación a partir de aquí 29-01-2020
                this.form.addControl('idMetodoEval', new FormControl(this.stepperService.idMetodoEval, [ V.required ]));
                // Actualizamos los datos compartidos por todos los pasos del stepper cada vez que el item de prof cambia.
                this.form.updateValueAndValidity();
                this.form.get('idMetodoEval').valueChanges.subscribe(professionalismId => {
                    this.stepperService.idTipoMetodoEval = this.evalTypeControl.value;
                    this.stepperService.idMetodoEval = this.form.get('idMetodoEval').value;
                    this.stepperService.evalTypeChange$.next();
                    this.stepperService.itemTypeChange$.next(professionalismId);
        
                    // Cuando cambia el item de profesionalismo, cambian las cobatrices.
                    // console.log("Carga cobatrices a partir del item de profesionalismo del método de evaluación")
                    this.spinner.show();
                    this.evaluationService.getCobatricesOfProfItem(professionalismId).subscribe(data => {
        
                        this.cobatrices = data;
                        this.spinner.hide();
                    }, () => this.spinner.hide());
                });
        
                // Cargamos los items de profesionalismo.
                this.spinner.show();
                this.profItems = [];
                this.evaluationService.getProfItems(this.selectedEvalType).subscribe(data => {
                    this.profItems = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
            } else {
                // Si no hay items de profesionalismo, directamente obtenemos las cobatrices.
                // Solo va a entrar aquí si es de tipo SPECIAL o SIMULATION.
                this.spinner.show();
                // console.log("Carga cobatrices a partir del método de evaluación")
                this.evaluationService.getCobatrices(this.selectedEvalType).subscribe(data => {
                    // console.log(data);
                    this.cobatrices = data;
                    this.stepperService.idMetodoEval = data['idMetodo'];
                    this.spinner.hide();
                }, () => this.spinner.hide());
            }
            let values = this.stepperService.stepperInfoGetObject();
            // console.log(values);
            if( values == undefined){
                values = [];
            }
            if (values['cobatrices']!= undefined){
                this.cobatrices = values['cobatrices'];
            }
            if (this.canShow(fs.RESIDENTE)) {
                this.form.addControl('idResidente', new FormControl(values['idResidente'] != undefined ? values['idResidente'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
            
            const resident = this.residents.find(r => r.id === values['idResidente']);
            // console.log(resident);
            if (this.canShow(fs.ANYO)) {
                this.form.addControl('anyo', new FormControl(resident != undefined ? 'R' + resident.anoResidencia : 
                values['anyo'] != undefined ? values['anyo'] : null, [ V.required ]));
                this.form.get('anyo').disable();
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.SERV_HOSP)) {
                this.form.addControl('servHosp', new FormControl(resident != undefined ? `${resident.servicio} - ${resident.centro}` : 
                values['servHosp'] != undefined ? values['servHosp'] : null, [ V.required ]));
                this.form.get('servHosp').disable();
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.EVALUADOR)) {
                this.form.addControl('evaluador', new FormControl(this.auth.getCurrentUserName2(), [ V.required ]));
                this.form.get('evaluador').disable();
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.FECHA)) {
                this.form.addControl('fecha', new FormControl(values['fechaNoFormat'] != undefined ? values['fechaNoFormat'] : new Date(), [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.PUESTO_EVAL)) {
                this.form.addControl('puestoEvaluador', new FormControl(values['puestoEvaluador'] != undefined ? values['puestoEvaluador'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.RESUMEN_CASO)) {
                this.form.addControl('resumenCaso', new FormControl(values['resumenCaso'] != undefined ? values['resumenCaso'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.MANEJO_CASO)) {
                this.form.addControl('manejoCaso', new FormControl(values['manejoCaso'] != undefined ? values['manejoCaso'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.DISCUSION)) {
                this.form.addControl('discusion', new FormControl(values['discusion'] != undefined ? values['discusion'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.CONCLUSION)) {
                this.form.addControl('conclusion', new FormControl(values['conclusion'] != undefined ? values['conclusion'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.BIBLIOGRAFIA)) {
                this.form.addControl('bibliografia', new FormControl(values['bibliografia'] != undefined ? values['bibliografia'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            /*if (this.canShow(fs.DATOS_PACIENTE)) {
                this.form.addControl('datosPaciente', new FormControl(values['datosPaciente'] != undefined ? values['datosPaciente'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }*/
    
            if (this.canShow(fs.COMPLEJIDAD)) {
                this.form.addControl('complejidad', new FormControl(values['complejidad'] != undefined ? values['complejidad'] : 0, []));
                this.form.updateValueAndValidity();
            }
    
            // En base al residente seleccionado se autocompletan algunos campos.
            // Ponemos este código después de todos los addControls para asegurarnos que existen.
            if (this.canShow(fs.RESIDENTE)) {
                this.form.get('idResidente').valueChanges.subscribe(residentId => {
                    this.stepperService.idResidente = residentId;
                    const resident = this.residents.find(r => r.id === residentId);
                    if (resident) {
                        if (this.canShow(fs.ANYO)) {
                            this.form.get('anyo').setValue(`R${resident.anoResidencia}`);
                            this.form.updateValueAndValidity();
                        }
                        if (this.canShow(fs.SERV_HOSP)) {
                            this.form.get('servHosp').setValue(`${resident.servicio} - ${resident.centro}`);
                            this.form.updateValueAndValidity();
                        }
                    }
                });
            }
    
            if (this.canShow(fs.EDAD)) {
                this.form.addControl('edadPaciente', new FormControl(values['edadPaciente'] != undefined ? values['edadPaciente'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.SEXO)) {
                this.form.addControl('sexoPaciente', new FormControl(values['sexoPaciente'] != undefined ? values['sexoPaciente'] : null, [ V.required ]));
                this.form.updateValueAndValidity();
            }
    
            if (this.canShow(fs.NUEVO)) {
                this.form.addControl('nuevo', new FormControl(values['nuevo'] != undefined ? values['nuevo'] : false, [ V.required ]));
                this.form.addControl('seguimiento', new FormControl(values['seguimiento'] != undefined ? values['seguimiento'] : false, [ V.required ]));
                this.form.updateValueAndValidity();
            }
        }
    }

    /*
    loadData(): void {
        this.spinner.show();
        this.profItems = [];
        this.formConfig = [];
        switch (this.selectedEvalType) {
            case EvaluationTypeIds.ECLOE:
                this.evaluationService.getEcloeItems().subscribe(data => {
                    this.profItems = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvaluationTypeIds.EPOE:
                this.evaluationService.getEpoeItems().subscribe(data => {
                    this.profItems = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvaluationTypeIds.SPECIAL:
                this.evaluationService.getSpecialItems().subscribe(data => {
                    this.formConfig = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvaluationTypeIds.SIMULATION:
                this.evaluationService.getSimulationItems().subscribe(data => {
                    this.formConfig = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvaluationTypeIds.v360:
                this.evaluationService.get360Items().subscribe(data => {
                    this.profItems = data;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            default:
                this.spinner.hide();
        }
        
    }*/

    onSubmit(): void {            
        const valores = this.form.getRawValue();
        // console.log(valores);
        if (this.evaluacion) {
            valores['idTipoMetodoEval'] = this.evalTypeControl.value;
            valores['fechaNoFormat'] = valores['fecha'];
            valores['fecha'] = moment(valores['fecha']).format('DD/MM/YYYY');
            valores['cobatrices'] = this.cobatrices;
            valores['checking'] = true;
            valores['questionsCheck'] = this.evaluacion.questions;
            valores['comentariosPositivos'] = this.evaluacion.comentariosPositivos;
            valores['areasMejora'] = this.evaluacion.areasMejora;
            valores['plan'] = this.evaluacion.plan;
            valores['satisfaccionResidente'] = this.evaluacion.satisfacionResidente;
            valores['satisfaccionEvaluador'] = this.evaluacion.satisfacionEvaluador;
            valores['minutosEvaluacion'] = this.evaluacion.minutosEvaluacion;
            valores['minutosObservacion'] = this.evaluacion.minutosObservacion;
            valores['evaluationFile'] = this.evaluacion.pdfCertificado;
            valores['tipoEval'] = this.tipoEval;
            valores['oldStepper'] = true;
            valores['idMetodoEval'] =  valores['idMetodoEval'] == null ? this.stepperService.idMetodoEval : valores['idMetodoEval']

        } else {
            valores['oldStepper'] = true;
            valores['idTipoMetodoEval'] = this.evalTypeControl.value;
            valores['fechaNoFormat'] = valores['fecha'];
            valores['fecha'] = moment(valores['fecha']).format('DD/MM/YYYY');
            valores['cobatrices'] = this.cobatrices;
            valores['idMetodoEval'] =  valores['idMetodoEval'] == null ? this.stepperService.idMetodoEval : valores['idMetodoEval']
        }
        const myConst = this.validatorForDisabled(this.form);
        // flag de evaluar
        if (this.evalTypeControl.valid && this.evaluacion === undefined && myConst) {
            this.stepperService.stepperInfoRewrite(valores);
            this.complete.next();
        } else if (this.evaluacion === undefined) {
            this.utils.validateAllFormFields(this.form);
            this.evalTypeControl.markAsTouched({onlySelf: true});
            this.alert.showError('Por favor, rellene todos los campos obligatorios.');
        }
        else if(this.evaluacion != undefined){
            this.stepperService.stepperInfoRewrite(valores);
            this.complete.next();
        }
    }

    goBack(): void {
        this.stepper.previous();
    }

    validatorForDisabled(formGroup: FormGroup): boolean {
        let validate = 1;
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);           
            if (control.disabled) {
            } else if (control.valid) {  
                validate *= 1;
            } else if (control.invalid) {
                validate *= 0;
            }
        });
        if (validate === 1) {
            return true;
        } else { 
            return false;
        }
    }
}
