import { UserService } from 'app/services/users.service';
import { PortProgressDomain } from 'app/main/pages/portfolio/models/port-progress-domain.model';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { NetworkService } from './network.service';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ProgressService {
    
    private entityUrl = `${environment.backendUrl}/residentes`;

    constructor (
        private http: HttpClient,
        private network: NetworkService,
        private userService: UserService
    ) {}

    getAllPortfolio (residentId?: string): Observable<PortProgressDomain[]> {
        // return of([]);
        if (this.network.isConnected()) {
            if (this.userService.getRol() === '2') {
                return this.http.get<PortProgressDomain[]>(`${this.entityUrl}/getProgreso`);
            } else if (this.userService.getRol() === '3') {
                const params = { params: { idResidente: residentId }};
                return this.http.get<PortProgressDomain[]>(`${this.entityUrl}/getProgreso`, params);
            }   
        } else {
            return of([])
        }
    }

}
