<div id="modal-Events" class="page-layout blank p-10" style="max-height:100vh; overflow: scroll;" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    
    <div style="display: flex; justify-content: space-between; align-items: center; background-color:#f0f8ff; margin-bottom: 5px">
        <div style="margin-left: 5px"><b>Datos del Formulario</b></div>
        <button mat-icon-button (click)="close()" disableRipple><mat-icon 
         style="display: flex; align-items: center; justify-content: center;">close</mat-icon></button>
    </div>
   <!-- <h2 class="text-center titulo-pag">Pérfil </h2> -->

    <div style="background-color:#eeeeee">
        <mat-card>
            <mat-card-content>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Tipo Evento:</b></span> {{data.tipoEvento}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Recomendado por:</b></span> {{data.recomendadoPor}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Etapa Recomendada:</b></span> {{data.etapaRecomendada}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Fecha Desde:</b></span> {{data.fechaDesdeModal}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Fecha Hasta:</b></span> {{data.fechaHastaModal}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Nombre:</b></span> {{data.nombre}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Lugar:</b></span> {{data.lugarModal}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Localidad:</b></span> {{data.localidad}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Provincia:</b></span> {{data.provincia}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>País:</b></span> {{data.pais}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Texto:</b></span> {{data.texto !== '' ? data.texto : '-'}}
                </div>
                <div style="display: flex">
                    <span class="col-xs-5"><b>Enlace a más info:</b></span> <a *ngIf="data.url !== ''" [href]="'http://' + data.url">{{data.url}}</a>
                </div>
            </mat-card-content>
        </mat-card>

    </div>

    

</div>
