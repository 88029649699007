<div class="custom-container" style="width: 100%">
    <div class="row main-row">
        <div *ngIf="resident !== undefined && getRol() === '3'" style="background-color:#1CAF9A; color:white; font-size: 18px; margin-left: 15px; margin-bottom: 3px; padding: 0px 10px">
            {{resident.apellidos}}, {{resident.nombre}} se encuentra en R{{resident.anoResidencia}}
        </div>
        <div *ngIf="resident !== undefined && getRol() === '2'" style="background-color:#1CAF9A; color:white; font-size: 18px; margin-left: 15px; margin-bottom: 3px; padding: 0px 10px">
            Se encuentra en {{resident.anyoResidencia}}
        </div>
        <div class="col-xs-12" style="display: flex; justify-content: space-between">
            <mat-card class="separate-bottom col-xs-5-94">
                <mat-card-content *ngIf="strData.length > 0">
                        <mat-card *ngFor="let str of strData" style="background-color:#dff0d8; margin-bottom: 5px">
                            <mat-card-content style="display: flex" (click)="goEvaluation(str.idEvaluacion, str.idTipo)">
                                <a class="col-xs-4">{{formatDate(str.fecha)}}</a> <a class="col-xs-8">{{str.texto}}</a>
                            </mat-card-content>
                        </mat-card>
                </mat-card-content>
            </mat-card>
            <mat-card class="separate-bottom col-xs-5-94">
                <mat-card-content *ngIf="strData.length > 0">
                    <mat-card *ngFor="let weak of weakData" style="background-color:#f2dede; margin-bottom: 5px">
                        <mat-card-content style="display: flex" (click)="goEvaluation(weak.idEvaluacion, weak.idTipo)">
                            <a class="col-xs-4">{{formatDate(weak.fecha)}}</a> <a class="col-xs-8">{{weak.texto}}</a>
                        </mat-card-content>
                    </mat-card>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>