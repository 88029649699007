import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';
//import { CordovaService } from './app/services/cordova.service';

if ( environment.production )
{
    enableProdMode();
}

declare var window;
interface window {
    cordovaReady: boolean;
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

window.cordovaReady=false;

if (typeof window['cordova'] !== 'undefined') {
    // console.log('SI CORDOVA');
    // console.time("Inicio cordova");
    document.addEventListener('deviceready', () => {
            // console.timeEnd("Inicio cordova");
            // console.time("Inicio bootstrap");
            bootstrap().catch(err => console.log(err));
            window.cordovaReady=true;
            // console.timeEnd("Inicio bootstrap");
            //console.timeEnd("Inicio app");
        }, false);

} else {
    //console.log('NO CORDOVA') 
        if ( environment.hmr ){
            if ( module['hot'] )
            {
                hmrBootstrap(module, bootstrap);
            }
            else
            {
                console.error('HMR is not enabled for webpack-dev-server!');
                console.log('Are you using the --hmr flag for ng serve?');
            }
        }else{
            // console.time("bootstrap despliegue")
            bootstrap().catch(err => console.log(err));
            // console.timeEnd("bootstrap despliegue")

        }

}






