import { ToolbarService } from './../../../layout/components/toolbar/toolbar.service';
import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ResidentsService } from 'app/services/residents.service';

export enum PortfolioViews {
    EVALUATIONS = 0,
    ACTIVITIES = 1,
    PROGRESS = 2
}

@Component({
    selector   : 'portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls  : ['./portfolio.component.scss'],
    animations   : fuseAnimations
})
export class PortfolioComponent implements OnInit, OnDestroy {   

    navButtons = [
        { key: PortfolioViews.EVALUATIONS, text: 'Evaluaciones' },
        { key: PortfolioViews.ACTIVITIES, text: 'Actividades' },
        { key: PortfolioViews.PROGRESS, text: 'Progreso' },
    ];
    resident;
    selectedView: PortfolioViews = PortfolioViews.EVALUATIONS;
    portfolioViews = PortfolioViews;

    constructor(
        private evalService: EvaluationService,
        private resService: ResidentsService,
        private toolbarService: ToolbarService
    ) {}

    ngOnInit(): void {
        if (this.getRol() === '2') {
            this.resService.getResidentInfo().subscribe((res) => {
                this.resident = res;
            });
        }
        else if(this.getRol() === '3') {
            this.toolbarService.setStatus(true);
            this.evalService.residenteConsulta.subscribe(resident => {
                this.resService.getResidentInfo(resident.id).subscribe((res) => {
                    this.resident = res;
                    this.evalService.emitTutorPortfolioView(true);
                });
            }).unsubscribe();
        }
    }

    onSelectView = (view: PortfolioViews) => this.selectedView = view;

    viewIs = (view: PortfolioViews) => this.selectedView === view;

    
    getRol(): string {
        return localStorage.getItem('rol');
    }
    ngOnDestroy(): void {
        this.toolbarService.setStatus(false);
        // this.evalService.emitTutorPortfolioView(false);
    }
}
