import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AlertService } from 'app/services/alert.service';

export default class AlertComponentInput {
  type: string;
  message: AlertService;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  type: string; // "success" or "error"
  message: AlertService;

  constructor (@Inject(MAT_SNACK_BAR_DATA) public data: AlertComponentInput) {
    this.type = data.type;
    this.message = data.message;
  }

  ngOnInit() {
  }

  /**
   * Checks if there is a message to display and if it's an error message.
   */
  hasError = () => this.message && this.type === 'error';

  /**
   * Checks if there is a message to display and if it's a success message.
   */
  hasSuccess = () => this.message && this.type === 'success';

}
