import { CertificateRequestComponent } from './../../register-notebook/certificate-request/certificate-request.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { TareasPendientesComponent } from './tareaspendientes.component';


import { MatButtonModule, MatRadioModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule , MatRippleModule, 
MatTableModule, MatToolbarModule, MatButtonToggleModule, MatSliderModule, MatIconModule, MatListModule, 
MatMenuModule, MatSelectModule, MatSlideToggleModule, MatTabsModule, MatCardModule, MatPaginatorModule, MatSortModule } from '@angular/material';
import { MaterialFileInputModule } from 'ngx-material-file-input';


import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';

import { AuthService } from 'app/services/auth.service';
import { TaskInfoComponent } from './taskInfo/taskInfo.component';

const routes = [
    {
        path     : 'tareaspendientes',
        component: TareasPendientesComponent,
        // canActivate: [AuthService]
    }
 
];

@NgModule({
    declarations: [
        TareasPendientesComponent,
        TaskInfoComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        MatSortModule,
        TranslateModule,
        MatPaginatorModule,
        FuseSharedModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatTableModule,

        NgxChartsModule,

        FuseSharedModule,
        FuseHighlightModule,
        MatCheckboxModule,
        MatRadioModule,
        MatRippleModule,
        MatInputModule,
        MatSliderModule,
        MaterialFileInputModule,
        MatCardModule
    ],
    exports     : [
    ],
    entryComponents: [
        TaskInfoComponent,
        CertificateRequestComponent
    ]
})

export class TareasPendientesModule
{
}
