import { EvaluationResidentService } from './../../../main/pages/stepper-resident/evaluation-resident.service';
import { NetworkService } from './../../../services/network.service';
import { SyncService } from './../../../services/sync.service';
import { AlertService } from './../../../services/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnDestroy, OnInit, ViewEncapsulation, Input, AfterViewInit, AfterViewChecked, ChangeDetectorRef, ViewRef, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {Location} from '@angular/common';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { ToolbarService } from './toolbar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IDbService } from 'app/services/idb.service';
import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';

declare var device;

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy, AfterViewChecked
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    pendingEvaluations = null;
    showBackButton: boolean;
    showIconProfileImg: boolean;
    showSyncButton: boolean;
    // Private
    private _unsubscribeAll: Subject<any>;

    lastRefreshDateTime: Date = null;
       
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _location: Location,
        private _toolbarService: ToolbarService,
        public _domSanitizer: DomSanitizer,
        private _changeDetectorRef: ChangeDetectorRef,
        public dialog: MatDialog,
        public sync: SyncService,
        private idbService: IDbService,
        private evalService: EvaluationService,
        private evalResidentService: EvaluationResidentService
    )
    {
        this.languages = [
            { id   : 'en', title: 'English', flag : 'us' },
            { id   : 'es', title: 'Español', flag : 'es' }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        
        this._toolbarService.showBackButton.subscribe(newval => this.showBackButton = newval);
        this._toolbarService.showIconProfileImg.subscribe(newval => {
            this.showIconProfileImg = newval;
        });
        this.sync.lastRefreshDateTime$.subscribe(lastDate =>  {
         this.lastRefreshDateTime = lastDate;
         // console.log(lastDate);
        });
        this._toolbarService.showSyncButton.subscribe(newval => {
            this.showSyncButton = newval;
        });

    }

    ngOnInit(): void
    {
        this.idbService.get("evaluationsToUpload").then((a) => {
            if (a !== undefined) {
                this.pendingEvaluations = a.length;
            } else {
                this.pendingEvaluations = 0;
            }
        });
        if (this.getRol() === '3') {
            this.evalService.pendingEvaluations$.subscribe((pendingEvaluations) => {
                // console.log(pendingEvaluations);
                this.pendingEvaluations = pendingEvaluations;
            });
        } else if (this.getRol() === '2') {
            this.evalResidentService.pendingEvaluations$.subscribe((pendingEvaluations) => {
                // console.log(pendingEvaluations);
                this.pendingEvaluations = pendingEvaluations;
            });
        }
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        this._changeDetectorRef.detectChanges();
    }

    ngAfterViewChecked() {
        
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._changeDetectorRef.detach();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

        /**
     * Volver pagina anterior
     * */
    back_clk() {     
        this._location.back();
      }

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    /*openDialog(): void {
        const dialogRef = this.dialog.open(SyncroModalComponent, {
            hasBackdrop: false,
            data: [{}, {}, {}, {}],
        });
    }*/
    openDialog = () => {

        if (!this.isMobileDevice()) {
            // Si no es un dispositivo móvil, usa el alerta con normalidad.
            const dialogRef = this.dialog.open(SyncroModalComponent, {
                hasBackdrop: false,
                data: [],
            });
        } else {
            // Si es un dispositivo móvil, tenemos que controlar dos casos:
            // 1.   El usuario cierra la alerta mediante los botones de la alerta.
            // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
            // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
            // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

            const onMobileBackButton = () => {
                dialogRef.close();
                window.removeEventListener('backbutton', onMobileBackButton, false);
            };

            document.addEventListener('backbutton', onMobileBackButton, false);
            const dialogRef = this.dialog.open(SyncroModalComponent, {
                hasBackdrop: false,
                data: [],
            });
            dialogRef.afterOpened().subscribe(() => {
                successResult => window.removeEventListener('backbutton', onMobileBackButton, false);
                errorResult => window.removeEventListener('backbutton', onMobileBackButton, false);
            });
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }
}


// SYNCRO NINJA COMPONENT !!

@Component({
    selector     : 'syncro',
    templateUrl  : './syncro.component.html',
    styleUrls    : ['./syncro.component.scss'],
})
export class SyncroModalComponent implements OnInit {

    isCompleted = false;

    constructor (
        public dialogRef: MatDialogRef<SyncroModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private syncService: SyncService,
        private alertService: AlertService,
        private network: NetworkService
    ) {}

    ngOnInit(): void {
        this.network.isConnected().subscribe(connected => {
            if (connected) {
                this.syncService.syncData().subscribe(result => {
                    this.isCompleted = result;
                    setTimeout(() => { 
                        this.dialogRef.close(); 
                        this.alertService.showSuccessToast('Datos sincronizados');
                    });
                }, error => {
                    this.dialogRef.close();
                    this.alertService.showWarningToast('Ocurrió un problema');
                });
            } else {
                this.dialogRef.close();
                this.alertService.showWarningToast('No tiene conexión a internet');
            }
        });
    }
}
