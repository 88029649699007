import { Component, OnInit, Input } from '@angular/core';
import { RegNotebookDomain } from '../reg-notebook-domain.model';

@Component({
  selector: 'app-notebook-domains',
  templateUrl: './notebook-domains.component.html',
  styleUrls: ['./notebook-domains.component.scss']
})
export class NotebookDomainsComponent implements OnInit {

    // Este componente renderiza tanto dominios como subdominios, según cual de los dos le llegue.
    @Input() domains: any[];
    @Input() isSubdomain = false;
    @Input() year;
    @Input() idTipoActo: number;
    constructor() { }

    ngOnInit(): void {
        // console.log(this.domains);
    }
}
