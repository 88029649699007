import { EvaluationResidentModule } from './main/pages/stepper-resident/evaluation-resident.module';
import { CertificacionesModule } from './main/pages/certificaciones/certificaciones.module';
import { TareasPendientesModule } from './main/pages/tareas pendientes/tareaspendientes.module';
import { FortalezasModule } from './main/pages/fortalezas/fortalezas.module';
import { PortfolioModule } from './main/pages/portfolio/portfolio.module';
import { SyncroModalComponent } from './layout/components/toolbar/toolbar.component';
import { EvaluacionesModule } from './main/pages/evaluaciones/evaluaciones.module';
import { EvaluationDocumentsModule } from './main/evaluation-documents/evaluation-documents.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from 'app/main/home/home.module';
import { LoginModule } from 'app/main/pages/authentication/login/login.module';
import { MenuModule } from 'app/main/menu/menu.module';
import { ProfileModule } from 'app/main/pages/profile/profile.module';

import { ResponseInterceptor } from 'app/services/interceptors/response.interceptor';


import { ScrollToBottomDirective } from './services/utils/scroll-to-bottom.directive';
import { AlertComponent } from './main/pages/alert/alert.component';
import { ApiService } from './api.service';
import { RotacionesModule } from './main/pages/rotaciones/rotaciones.module';
import { ResidentesModule } from './main/pages/residentes/residentes.module';
import { EvaluationModule } from './main/pages/stepper/evaluation.module';
import { RegisterNotebookModule } from './main/register-notebook/register-notebook.module';
import { RegisterNotebookTutorModule } from './main/register-notebook-tutor/register-notebook-tutor.module';
import { ConnectionServiceModule } from 'ng-connection-service';

// RouterModule.forRoot(appRoutes, { useHash: true})

const appRoutes: Routes = [
    {
        path      : '**',
        redirectTo: '/login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        ScrollToBottomDirective,
        AlertComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { useHash: true}),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        HomeModule,
        LoginModule,
        MenuModule,
        ProfileModule,
        RotacionesModule,
        ResidentesModule,
        EvaluationModule,
        EvaluationResidentModule,
        EvaluationDocumentsModule,
        RegisterNotebookModule,
        RegisterNotebookTutorModule,
        EvaluacionesModule,
        PortfolioModule,
        FortalezasModule,
        TareasPendientesModule,
        CertificacionesModule,
        
        ConnectionServiceModule

    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        ApiService,
        {
            provide : HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi   : true,
        }
    ],
    entryComponents: [
        AlertComponent,
        SyncroModalComponent
    ]
})
export class AppModule
{
}
