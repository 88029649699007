export class CreateEvaluation {
    idTipoMetodoEval: string;
    idResidente: string;
    fecha: string;
    idMetodo?: string;
    edadPaciente?: number;
    sexoPaciente?: string;
    nuevo?: number;
    seguimiento?: number;
    puestoEvaluador?: number;
    resumenCaso?: string;
    manejoCaso?: string;
    discusion?: string;
    conclusion?: string;
    bibliografia?: string;
    complejidad?: number;
    satisfacionResidente?: number;
    satisfacionEvaluador?: number;
    minutosEvaluacion?: number;
    minutosObservacion?: number;
    login?: string;
    password?: string;
    comentariosPositivos?: string;
    areasMejora?: string;
    plan?: string;
    nivelAlcanzado?:string = null;
    puntuacion?:string;
    questions: Question[]
} 

export class Question {
    idApartado: string;
    puntuacion: any;
}