import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

    export class WebStorageService {
    // Get the token by subscribing to the accessToken Observable (generated from subject)
    // BehaviorSubject is the same as Subject but with a default value.
    private _accessToken$: BehaviorSubject<string>;
    readonly accessToken$: Observable<string>;

    private _userId$: BehaviorSubject<number>;
    readonly userId$: Observable<number>;

    constructor() {
        const initialToken = localStorage.getItem('accessToken');
        this._accessToken$ = new BehaviorSubject(initialToken);
        this.accessToken$ = this._accessToken$.asObservable();

        const initialId = Number(localStorage.getItem('userId'));
        this._userId$ = new BehaviorSubject(initialId);
        this.userId$ = this._userId$.asObservable();
    }

    /**
    * When the token is set, the observable is fired and all places that subscribed to it automatically 
    * get the update.
    */
    setAccessToken(token: string) {
        localStorage.setItem('accessToken', token);
        this._accessToken$.next(token);
    }
    
    getAccessToken(): string {
        return localStorage.getItem('accessToken');
    }

    setCurrentUserId(id: number) {
        localStorage.setItem('userId', String(id));
        this._userId$.next(id);
    }

    getCurrentUserId(): number {
        const id = localStorage.getItem('userId');
        if (id === null || id === undefined || id === 'null') {
            return null;
        }
        return Number(id);
    }
    setCurrentLastName(lastName: string) {
        localStorage.setItem('lastName',lastName);
    }
    setCurrentLogin(login: string){
        localStorage.setItem('login',login);
    }
    setCurrentFirstName(firstName: string) {
        localStorage.setItem('firstName', firstName);
    }
    getCurrentLastName(): string {
        return localStorage.getItem('lastName');
    }
    getCurrentFirstName(): string {
        return localStorage.getItem('firstName');
    }
    getCurrentLogin(): string {
        return localStorage.getItem('login');
    }
}  