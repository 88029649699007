import { CertificateInfoComponent } from './certificateInfo/certificateInfo.component';
import { UserService } from './../../../services/users.service';
import { EvaluationTypeIds } from './../../../models/evaluation-type-ids.enum';
import { FormBuilder } from '@angular/forms';
import { SpinnerService } from './../../../services/spinner.service';
import { Component, ViewChild, OnInit, AfterViewInit, QueryList, ViewChildren } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MatDialogRef } from '@angular/material';
import { Subject, merge } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

declare let cordova: any;
declare let device: any;

@Component({
    selector   : 'app-certificaciones',
    templateUrl: './certificaciones.component.html',
    styleUrls  : ['./certificaciones.component.scss'],
    animations   : fuseAnimations
})

export class CertificacionesComponent implements OnInit, AfterViewInit {
    @ViewChild('inputSearch1') inputSearch1;
    @ViewChild('inputSearch2') inputSearch2;
    @ViewChild('inputSearch3') inputSearch3;
    activate = true;
    dataSource = new  MatTableDataSource<any>([]);
    downloadUrl = environment.evaluationsUrl;
    residente: any;
    searchText: string = '';
    searchTextCaso: string = '';
    searchMetodo: string = '';
    queryParams: {tipo: string} = {tipo: ''};
    flag = 0;
    displayedColumnsNoAction: string[];
    displayedColumns: string[];
    searchAtributes: string[];
    evaluaciones: any = [];
    allEvaluaciones: any = [];
    filtered = [];
    dialogRef: MatDialogRef<CertificateInfoComponent>;
    tipoEvaluacion;
    evaluationLength;
    form;
    typingTime;
    paginator: MatPaginator;
    paginator2: MatPaginator;
    evalTypeOptions = [
        { id: 0, name: 'Sin filtro' },
        { id: EvaluationTypeIds.ECLOE, name: 'ECLOE' },
        { id: EvaluationTypeIds.EPOE, name: 'EPOE' },
        { id: EvaluationTypeIds.SPECIAL, name: 'Casos Especiales' },
        { id: EvaluationTypeIds.v360, name: '360º' },
        { id: EvaluationTypeIds.SIMULATION, name: 'Simulaciones, cursos o talleres' }
    ];
    translate = {
        'fechaSolicitud': 'Fecha Solicitud', 
        'residente': 'Residente', 
        'tipoActo': 'Tipo', 
        'nombreActo': 'Nombre', 
        'solicitadas': 'Solicitadas', 
        'certificadas': 'Certificadas'
    };
    private _unsubscribeAll: Subject<any>;
    @ViewChildren(MatPaginator) paginatorList: QueryList<MatPaginator>;
    // @ViewChild(MatPaginator) paginator: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;
    
    constructor(private matDialog: MatDialog,
                public _dialog: MatDialog,
                // private rotacionesService: RotacionesService,
                private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private spinner: SpinnerService,
                private formB: FormBuilder,
                public dialog: MatDialog) {

                }
    /*loadDataSourceFormApi(){
        this.spinner.show();
        this.route.queryParams.subscribe((params: {tipo: string}) => {
            this.tipoEvaluacion = params.tipo;
            if (params.tipo === 'competencias') {          
                this.evaluationService.getEvaluacionesCompetenciasPag(
                    '',
                    'asc',
                    this.paginator.pageIndex,
                    this.paginator.pageSize
                ).subscribe(evaluaciones => {
                    this.evaluaciones = evaluaciones['evaluaciones'];
                    this.dataSource = new MatTableDataSource<Evaluaciones[]>(this.evaluaciones);
                    //this.dataSource.data = this.evaluaciones;
                    this.allEvaluaciones = [ ...this.evaluaciones ];
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.displayedColumns = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada', 'acciones'];
                    this.searchAtributes = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
                    this.paginator.page
                    .pipe(
                        tap(() => this.loadDataSourceFormApi())
                    )
                    .subscribe();
                    
                    this.spinner.hide();
                }, (error) => {
                    this.spinner.hide();
                    console.log('Error: ' + error);  
                });
            } else if (params.tipo === 'procedimientos') {
                this.evaluationService.getEvaluacionesProcedimientosPag(                    
                '',
                'asc',
                this.paginator.pageIndex,
                this.paginator.pageSize).subscribe(evaluaciones => {
                    this.evaluaciones = evaluaciones;
                    this.dataSource = new MatTableDataSource<Evaluaciones[]>(this.evaluaciones);
                    this.allEvaluaciones = [ ...evaluaciones ];
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.displayedColumns = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada', 'acciones'];
                    this.searchAtributes = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
                    this.paginator.page
                    .pipe(
                        tap(() => this.loadDataSourceFormApi())
                    )
                    .subscribe();
                    
                    this.spinner.hide();
                }, (error) => {
                    this.spinner.hide();
                    console.log('Error: ' + error);
                });
            }
        });
    }*/
    onKey(e) {
        if (e.keyCode == 13) {
            this.inputSearch1.nativeElement.blur()
            this.inputSearch2.nativeElement.blur()
            this.inputSearch3.nativeElement.blur()
        }
    }
    translateColumns(string) {
        return this.translate[string];
    }

    loadDataSourceFormApi(filters, pageIndex, pageSize): void{
        this.spinner.show();

            setTimeout(() => {
                    /*
                    this.dataSource = new EvaluationDataSource(this.evaluationService);  
                    this.dataSource.loadCompetencias(filters, order, pageIndex, pageSize);
                    this.dataSource.evaluationLength.subscribe( length => {
                        this.evaluationLength = length;

                    });
                    */
                   this.userService.getCertificaciones().subscribe((res) => {
                      this.evaluaciones = res;
                      this.evaluationLength = res.length;
                      this.loadEvalPage();
                      this.spinner.hide();
                   });
                    // this.dataSource.paginator = this.paginator;
                    // this.dataSource.sort = this.sort;
                    this.displayedColumnsNoAction = ['fechaSolicitud', 'residente', 'tipoActo', 'nombreActo', 'solicitadas', 'certificadas'];
                    this.displayedColumns = [...this.displayedColumnsNoAction, 'acciones']
                    this.searchAtributes = ['tipo', 'fecha', 'nombreResidente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
            });
    }

    ngOnInit(): void {
        this.form = this.formB.group({
            'searchBar': [null]
        });
        // this.loadDataSourceFormApi(this.searchText, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
    }


    ngAfterViewInit(): void {
        this.paginator2 = this.paginatorList.toArray()[0];
        this.paginator = this.paginatorList.toArray()[1];
        this.loadDataSourceFormApi(this.searchText, this.paginator.pageIndex, this.paginator.pageSize);
        // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        merge(/*this.sort.sortChange,*/ this.paginator.page, this.paginator2.page)
            .pipe(
                tap(() => this.loadEvalPage())
            )
            .subscribe();
        /*this.paginator.page
        .pipe(
            tap(() => this.loadEvalPage())
        )
        .subscribe();*/
        this.form.valueChanges.pipe(
            debounceTime(150),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                this.loadEvalPage();
            })
            ).subscribe();
    }

    downloadFile(evalutationDoc): void {
        const urlDownload = `${this.downloadUrl}${this.residente.id}/${evalutationDoc}`;
        // window.open(urlDownload);
        window.open(urlDownload, '_system', 'location=yes');
        /*
        cordova.plugins.fileOpener2.open(
            urlDownload,
            // 'application/vnd.android.package-archive'
        );
        */
    }

    format(string: string): string {
        if (Number(string) === 0) {
            return 'No';
        } else if (Number(string) === 1) {
            return 'Si';
        }
    }

    loadEvalPage() {
        /*
        this.loadDataSourceFormApi(
            this.searchText,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
            */
            let pageNumber = this.paginator.pageIndex;
            let pageSize = this.paginator.pageSize;
            const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
            const final = initial + pageSize;
            
            if ((this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== '') && this.filtered.length > 0) {
                this.dataSource.data = this.filtered.slice(initial, final);
            } else {
                this.dataSource.data = this.evaluaciones.slice(initial, final);
            }
    }

    clearSearch(): void {
        this.searchText = '';
        this.filtered = [];
        this.onSearch();
    }

    clearSearchCaso(): void {
        this.searchTextCaso = '';
        this.filtered = [];
        this.onSearch();
    }

    //TODO - CAMBIAR ESTO
    onSearch(): void {
        this.paginator.pageIndex = 0;
        this.paginator2.pageIndex = 0;
        this.loadDataSourceFormApi(this.searchText,             
            // this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    onSearchCaso(): void {
        this.paginator.pageIndex = 0;
        this.paginator2.pageIndex = 0;
        this.loadDataSourceFormApi(this.searchTextCaso,             
            // this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    /*typingTimer(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchText === '') {
                this.paginator.pageIndex = 0;
                this.loadDataSourceFormApi(this.searchText,             
                    this.sort.direction,
                    this.paginator.pageIndex,
                    this.paginator.pageSize);
            }
        }, 1000);
    }*/

    typingTimer(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchTextCaso.length >= 3 || this.searchMetodo.length >= 3 ||
                (this.searchText === '' && this.searchTextCaso === '' && this.searchMetodo === '')) {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                document.getElementById('top').scrollIntoView();
                let pageNumber = this.paginator.pageIndex;
                let pageSize = this.paginator.pageSize;
                const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
                const final = initial + pageSize;
                if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchMetodo !== ''){
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()))
                    .filter(r => r.tipoActo.toLowerCase()
                    .includes(this.searchTextCaso.toLowerCase()))
                    .filter(r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                } 
                
                else if (this.searchText !== '' && this.searchTextCaso !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()))
                    .filter(r => r.tipoActo.toLowerCase().
                    includes(this.searchTextCaso.toLowerCase()));
                }
                else if (this.searchText !== '' && this.searchMetodo !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()))
                    .filter(r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                }
                else if (this.searchMetodo !== '' && this.searchTextCaso !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()))
                    .filter(r => r.tipoActo.toLowerCase()
                    .includes(this.searchTextCaso.toLowerCase()));
                }
                
                
                else if (this.searchText !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()));
                } else if (this.searchTextCaso !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.tipoActo.toLowerCase()
                    .includes(this.searchTextCaso.toLowerCase()));
                } else if (this.searchMetodo !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                } else {
                    this.filtered = this.evaluaciones;
                }
                this.dataSource.data = this.filtered.slice(initial, final);
            }
        }, 1000);
    }

    typingTimerInstant(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchTextCaso.length >= 3 || this.searchMetodo.length >= 3 ||
                (this.searchText === '' && this.searchTextCaso === '' && this.searchMetodo === '')) {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                document.getElementById('top').scrollIntoView();
                let pageNumber = this.paginator.pageIndex;
                let pageSize = this.paginator.pageSize;
                const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
                const final = initial + pageSize;
                if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchMetodo !== ''){
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()))
                    .filter(r => r.tipoActo.toLowerCase()
                    .includes(this.searchTextCaso.toLowerCase()))
                    .filter(r => r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                } 
                
                else if (this.searchText !== '' && this.searchTextCaso !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()))
                    .filter(r => r.tipoActo.toLowerCase().
                    includes(this.searchTextCaso.toLowerCase()));
                }
                else if (this.searchText !== '' && this.searchMetodo !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()))
                    .filter(r => r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                }
                else if (this.searchMetodo !== '' && this.searchTextCaso !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()))
                    .filter(r => r.tipoActo.toLowerCase()
                    .includes(this.searchTextCaso.toLowerCase()));
                }
                
                
                else if (this.searchText !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.residente.toLowerCase()
                    .includes(this.searchText.toLowerCase()));
                } else if (this.searchTextCaso !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.tipoActo.toLowerCase()
                    .includes(this.searchTextCaso.toLowerCase()));
                } else if (this.searchMetodo !== '') {
                    this.filtered = this.evaluaciones.filter(r => r.nombreActo.toLowerCase()
                    .includes(this.searchMetodo.toLowerCase()));
                }



                else {
                    this.filtered = this.evaluaciones;
                }
                this.dataSource.data = this.filtered.slice(initial, final);
            }
        }, 1);
    }


/*
    hider(): void {
        if (this.dataSource) {
            setTimeout(() =>
                this.dataSource.loading$.subscribe(loading => {
                    if (!loading) {
                        this.spinner.hide();
                    } else {
                        this.spinner.show();
                    }
                })
            );
        }
    } */

    calculateLength(): number {
        if ((this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== '') && this.filtered.length > 0) {
            return this.filtered.length;
        } else if (this.filtered.length === 0 && (this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== '')){
            return 0;
        } else {
            return this.evaluationLength;
        }
    }

    /*cleaner(): void {
        this.searchText = '';
        this.filtered = [];
        this.filtered = this.evaluaciones.filter(r => r.competencia.includes(this.searchTextCaso));
    }
    cleanerCaso(): void {
        this.searchTextCaso = '';
        this.filtered = [];
        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.includes(this.searchText));
    }*/

    clean(): void {
        this.searchText = '';
        this.typingTimerInstant();
    }

    cleancaso(): void {
        this.searchTextCaso = '';
        this.typingTimerInstant();
    }

    cleanmetodo(): void {
        this.searchMetodo = '';
        this.typingTimerInstant();
    }

    allclean(): void {
        if (this.searchText === '' && this.searchTextCaso === '' && this.searchMetodo === '') {
            this.filtered = [];
        }
    }

    openCertificate = (data) => {
        if (this.activate === true) {
            if (!this.isMobileDevice()) {
                // Si no es un dispositivo móvil, usa el alerta con normalidad.
                    this.dialogRef = this.dialog.open(CertificateInfoComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => this.activate = false);
                this.dialogRef.afterClosed().subscribe(() => this.activate = true);
            } else {
                // Si es un dispositivo móvil, tenemos que controlar dos casos:
                // 1.   El usuario cierra la alerta mediante los botones de la alerta.
                // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
                // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
                // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

                

                if (typeof device !== 'undefined' && device !== null && typeof device.platform !== 'undefined' && typeof device.platform !== null){
                    document.addEventListener("backbutton", this.onMobileBackButton, false);
                }

                this.dialogRef = this.dialog.open(CertificateInfoComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                    data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => {
                    successResult => { this.activate = false; };
                    errorResult => {}
                });
                this.dialogRef.afterClosed().subscribe(() => {
                    document.removeEventListener('backbutton', this.onMobileBackButton, false);
                    this.activate = true;
                });
            }
        }

    }

    onMobileBackButton = () => {
        if (this.dialogRef){
            let dialogRef = this.dialog.open(CertificateInfoComponent);
            dialogRef.close();
            this.dialogRef.close();
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }

    syncPages(event, number) {
        document.getElementById('top').scrollIntoView();
        if (number === 1) {
            this.paginator2.pageIndex = event.pageIndex;
            this.paginator2.pageSize = event.pageSize;
            this.paginator2.length = event.length;
        } else if (number === 2) {
            this.paginator.pageIndex = event.pageIndex;
            this.paginator.pageSize = event.pageSize;
            this.paginator.length = event.length;
        }
    }

}
