import { PrivacyPolicyComponent } from './privacyPolicy/privacyPolicy.component';
import { EventInfoComponent } from './eventInfo/eventInfo.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { forkJoin } from 'rxjs';
import { Events } from './../../../models/events.model';
import { Links } from './../../../models/links.model';
import { Contacts } from './../../../models/contacts.model';
import { ProfileService } from './../../../services/profile.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from '../../../layout/components/toolbar/toolbar.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SpinnerService } from '../../../services/spinner.service';
import { AuthService } from 'app/services/auth.service';
import { environment } from 'environments/environment';
import { UserService } from 'app/services/users.service';
import { IDbService } from 'app/services/idb.service';
import { AlertService } from 'app/services/alert.service';
import { SyncService } from 'app/services/sync.service';

export interface NotificationConfig {
    trainTime: boolean;
    noActivity: boolean;
    unreadMessages: boolean;
    news: boolean;
  }
  
  export interface LanguageConfig {
    language: string;
  }
  
  
/*declare let navigator: any;
declare let Camera: any;*/
declare let window: any;
/*declare let LocalFileSystem: any;
declare let cordova: any;*/
declare var device;

@Component({
    selector   : 'profile-ppal',
    templateUrl: './profile.component.html',
    styleUrls  : ['./profile.component.scss'],
    animations   : fuseAnimations
})

export class ProfileComponent implements OnInit,  OnDestroy 
{
    selfieImage: string;
    name: string;
    puesto: string;
    provincia: string;
    localidad: string;
    imagenPerfil: string;
    tipoPerfil: string;
    activate = true;
    // contacts = [{name: 'Pedro'}, {name: 'Pablo'}, {name: 'Juan'}];
    // links = [{name:'Guía de Cádiz'}, {name: 'National Geographic'}];
    // events = [{name: 'Fiesta palangana'}, {name: 'Elecciones 2020'}];
    contacts: Contacts[];
    links: Links[];
    events: Events[];
    notifications: any [];
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(private _domSanitizer: DomSanitizer,
                private _toolbarService: ToolbarService,
                private _authService: AuthService,
                private _router: Router,
                private spinner: SpinnerService,
                private idbService:IDbService,
                private alertService: AlertService,
                private profile: ProfileService,
                private userService: UserService,
                private sync: SyncService,
                public dialog: MatDialog) {}

    ngOnInit(): void {
        const fotoAvatar = localStorage.getItem('avatarpath');
        if (fotoAvatar){
            this.selfieImage = fotoAvatar;
        } else {
            this.selfieImage = 'assets/images/avatars/profile.jpg';
        }
        
        setTimeout(() => {
            // this._toolbarService.setStatus(true); // mostrar flecha atras
            this._toolbarService.setIconProfileImg(false); // ocultar icono profile
            this._toolbarService.setSyncButton(true);
        });

        this.userService.getUserInfo().subscribe((info) => {
            this.name = info.completeName;
            this.puesto = info.job;
            this.provincia = info.province;
            this.localidad = info.locality;
            this.tipoPerfil = info.idTipoUsuario;
            this.imagenPerfil = info.image;
            this.spinner.hide();
        });
        /*
        this.name         = localStorage.getItem('nombrePropio');
        this.puesto       = localStorage.getItem('puesto');
        this.provincia    = localStorage.getItem('provincia');
        this.localidad    = localStorage.getItem('localidad');
        this.tipoPerfil   = localStorage.getItem('tipoPerfil');
        if (this.tipoPerfil === '3') {
            this.imagenPerfil = environment.imagesTutorUrl + '/' +  localStorage.getItem('userId') + '/' + localStorage.getItem('imagenPerfil');
        } else if (this.tipoPerfil === '2') {
            this.imagenPerfil = environment.imagesResidentsUrl + localStorage.getItem('userId') + '/' + localStorage.getItem('imagenPerfil');
        }
        }*/

        /*this.profile.getContacts().subscribe(contacts => {
            this.contacts = contacts;
            this.spinner.hide();
        });*/

        if (this.getRol() === '3') {
            forkJoin(this.profile.getContacts(), this.profile.getLinks(), this.profile.getEvents(), this.profile.getNotifications())
            .subscribe(([contacts, links, events, notifications]) => {
                this.contacts = contacts;
                this.links = links;
                this.events = events;
                this.notifications = notifications;
                this.spinner.hide();
            });
        } else {
            forkJoin(this.profile.getContacts(), this.profile.getLinks(), this.profile.getEvents())
            .subscribe(([contacts, links, events]) => {
                this.contacts = contacts;
                this.links = links;
                this.events = events;
                this.spinner.hide();
            });
        }
    }
    
    ngOnDestroy(): void {
        setTimeout(() => {
            // this._toolbarService.setStatus(false); // ocultar flecha atras
            this._toolbarService.setIconProfileImg(true); // mostrar icono profile
            this._toolbarService.setSyncButton(false);
        });
    } 
    
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    closeAlert = () => {
        this.alertService.close()
    }
    logout_clk(): void {
        this.idbService.get('evaluationsToUpload').then((res) => {
            if(res != undefined && res.length>0){
                let alerta = `Tiene ${res.length} evaluaciones sin sincronizar, si se desconecta sin sincronizarlas las perderá`;
                document.addEventListener('backbutton', this.closeAlert, false);
                this.alertService.showConfirm({message:alerta}).subscribe((ans)=> {
                    document.removeEventListener('backbutton', this.closeAlert, false);
                    if(ans){
                        this.spinner.show()
                        this.idbService.deleteDB().then((check)=> {
                            this.spinner.hide()
                            localStorage.removeItem('lastRefreshDateTime');
                            this.sync._lastRefreshDateTime$.next(null);
                            this._authService.logout()
                            this._router.navigate(['/login']);
                        }).catch((error)=> {
                            this.spinner.hide()
                        })
                    }
                })
            }
            else{
                this.spinner.show()
                        this.idbService.deleteDB().then((check)=> {
                            this.spinner.hide()
                            localStorage.removeItem('lastRefreshDateTime');
                            this.sync._lastRefreshDateTime$.next(null);
                            this._authService.logout()
                            this._router.navigate(['/login']);
                        }).catch((error)=> {
                            this.spinner.hide()
                        })
            }
        })
    }

    getUrl(link: Links): void {
        if (this.activate) {
            window.open(link.url);
        }
    }
    dialogRef:MatDialogRef<EventInfoComponent>;
    onMobileBackButton = () => {
        if(this.dialogRef){
            let dialogRef = this.dialog.open(EventInfoComponent);
            dialogRef.close();
            this.dialogRef.close();
        }
    }
    openInfo = (data) => {
        if (this.activate === true) {
            if (!this.isMobileDevice()) {
                // Si no es un dispositivo móvil, usa el alerta con normalidad.
                    this.dialogRef = this.dialog.open(EventInfoComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                    data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => this.activate = false);
                this.dialogRef.afterClosed().subscribe(() => this.activate = true);
            } else {
                // Si es un dispositivo móvil, tenemos que controlar dos casos:
                // 1.   El usuario cierra la alerta mediante los botones de la alerta.
                // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
                // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
                // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

                

                if(typeof device !== 'undefined' && device !== null && typeof device.platform !== 'undefined' && typeof device.platform !== null){
                    document.addEventListener("backbutton", this.onMobileBackButton, false);
                }

                this.dialogRef = this.dialog.open(EventInfoComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                    data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => {
                    // console.log("hi on open");
                    successResult => { this.activate = false; };
                    errorResult => {}
                });
                this.dialogRef.afterClosed().subscribe(() => {
                    // console.log("hi there");
                    document.removeEventListener('backbutton', this.onMobileBackButton, false);
                    this.activate = true;
                });
            }
        }
    }

    openPolicy = () => {
        if (this.activate === true) {
            if (!this.isMobileDevice()) {
                // Si no es un dispositivo móvil, usa el alerta con normalidad.
                    this.dialogRef = this.dialog.open(PrivacyPolicyComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                    // data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => this.activate = false);
                this.dialogRef.afterClosed().subscribe(() => this.activate = true);
            } else {
                // Si es un dispositivo móvil, tenemos que controlar dos casos:
                // 1.   El usuario cierra la alerta mediante los botones de la alerta.
                // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
                // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
                // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

                

                if (typeof device !== 'undefined' && device !== null && typeof device.platform !== 'undefined' && typeof device.platform !== null){
                    document.addEventListener("backbutton", this.onMobileBackButton, false);
                }

                this.dialogRef = this.dialog.open(PrivacyPolicyComponent, {
                    hasBackdrop: false,
                    width: '85vw',
                    // data: data,
                });
                this.dialogRef.afterOpened().subscribe(() => {
                    successResult => { this.activate = false; };
                    errorResult => {}
                });
                this.dialogRef.afterClosed().subscribe(() => {
                    document.removeEventListener('backbutton', this.onMobileBackButton, false);
                    this.activate = true;
                });
            }
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }

    goToPending(): void {
        this._router.navigate(['tareaspendientes']);
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }

}
