import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs-compat';

@Injectable({
    providedIn: 'root'
})
export class ToolbarService {

  private showBackButton_ob = new BehaviorSubject(false);
  private showIconProfileImg_ob = new BehaviorSubject(true);
  private showSyncButton_ob = new BehaviorSubject(true);
  private changeAvatar_ob = new BehaviorSubject('');
  showBackButton = this.showBackButton_ob.asObservable();
  showIconProfileImg = this.showIconProfileImg_ob.asObservable();
  showSyncButton = this.showSyncButton_ob.asObservable();
  changeAvatar = this.changeAvatar_ob.asObservable();
  
  constructor() { }
 
  setStatus(val:boolean) {
    this.showBackButton_ob.next(val);
  }

  setIconProfileImg(val:boolean) {
    this.showIconProfileImg_ob.next(val);
  }

  setSyncButton(val: boolean) {
      this.showSyncButton_ob.next(val);
  }

  setChangeAvatar(val:string) {
    val =localStorage.getItem('avatarpath');
    if(!val){
      val = 'assets/images/avatars/profile.jpg';
    }
    this.changeAvatar_ob.next(val);
  }

}