import { ToolbarService } from './../../../layout/components/toolbar/toolbar.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'app/services/spinner.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RegNotebookService } from '../reg-notebook.service';
import { RegNotebookDomain } from '../reg-notebook-domain.model';

@Component({
  selector: 'app-register-notebook-page',
  templateUrl: './register-notebook-page.component.html',
  styleUrls: ['./register-notebook-page.component.scss']
})
export class RegisterNotebookPageComponent implements OnInit, OnDestroy {
    residentInfo: any;
    registerInfo: RegNotebookDomain;
    registerInfoKeys = [
        {activity: 'procedimientos', text: 'RECUENTO DE PROCEDIMIENTOS (SOLICITADOS/CERTIFICADOS)', idTipoActo: 1}, 
        {activity: 'metodos', text: 'RECUENTO DE EVALUACIONES CERTIFICADAS Y ACTIVIDADES', idTipoActo: 2}, 
        {activity: 'actAsistenciales', text: 'RECUENTO DE ACTIVIDAD ASISTENCIAL (SOLICITADAS/CERTIFICADAS)', idTipoActo: 3}, 
        {activity: 'actividades', text: 'RECUENTO DE ACTIVIDAD DOCENTE E INVESTIGADORA', idTipoActo: 4}
    ];

    constructor (
        private regNotebookService: RegNotebookService,
        private spinner: SpinnerService,
        private router: Router,
        private toolbarService: ToolbarService
    ) { }

    ngOnInit(): void {

        this.spinner.show();
        if (this.getRol() === '2') {
            this.regNotebookService.getDataResident().subscribe(info => {
                this.registerInfo = info;
                this.spinner.hide();
            }, () => this.spinner.hide());
        } else if (this.getRol() === '3') {
            this.toolbarService.setStatus(true);
            this.regNotebookService.residenteConsulta.subscribe(resident => {
                if (resident === null) {
                    this.router.navigate(['misresidentes']);
                } else {
                    this.residentInfo = resident;
                    this.regNotebookService.getDataForTutor(this.residentInfo.id).subscribe(info => {
                        this.registerInfo = info;
                        this.spinner.hide();
                    }, () => this.spinner.hide());
                }
            }).unsubscribe();
        }
    }

    lookFor(search: string, activity: string): boolean {

        if (this.registerInfo[activity][0] !== undefined) {
            return this.registerInfo[activity][0].hasOwnProperty(search);
        } else {
            return false;
        }
    }
    getSolicitudCertif(){
        this.router.navigate(['miscertificaciones']);
    }
    getRol(): string {
        return localStorage.getItem('rol');
    }

    ngOnDestroy(): void {
        this.toolbarService.setStatus(false);
        this.regNotebookService.emitResidenteConsulta(null);
    }

}
