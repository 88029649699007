import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { ToolbarService } from './../../../layout/components/toolbar/toolbar.service';
import { StepperService } from './../../../services/stepper.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatRadioChange, MatStepper } from '@angular/material';


import { SpinnerService } from '../../../services/spinner.service';
import { DialogService } from '../../../services/dialog.service';
import { ResidentsService } from 'app/services/residents.service';
import { Resident } from 'app/models/resident.model';

export interface NotificationConfig {
    trainTime: boolean;
    noActivity: boolean;
    unreadMessages: boolean;
    news: boolean;
  }
  
  export interface LanguageConfig {
    language: string;
  }
  
export interface Residentes {
    id: string;
    nombre_completo: string;
    nombre: string;
    apellidos: string;
    centro: string;
    localidad: string;
    provincia: string;
    especialidad: string;
    servicio: string;
    rotandoPor: string;
    anoResidencia: string;
    nombre_completo_tutor: string;
    activo: string;
    imagen: string;
}
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'stepper-form',
    templateUrl: './stepper-form.component.html',
    styleUrls  : ['./stepper-form.component.scss'],
    animations   : fuseAnimations
})

export class StepperFormComponent implements OnInit, OnDestroy
{
    isLinear = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    forms: FormGroup[] = [];
    @ViewChild('stepper') stepper: MatStepper;

    constructor(
        public _domSanitizer: DomSanitizer,
        public userService: UserService,
        private fb: FormBuilder,
        private stepperService: StepperService,
        private toolbarService: ToolbarService,
        private evalService: EvaluationService
    )
    {
        const V = Validators;
        [0, 1, 2, 3, 4].forEach(index => { this.forms[index] = this.fb.group({ 'completed': [ null, [ V.required ]] }); });
    }

    ngOnInit(): void {
        this.toolbarService.setStatus(true);
        this.stepperService.setStepper(this.stepper);
    }

    setStepAsValid(stepId: number): void {
        const form = this.forms[stepId];
        if (form) {
            const ctrl = form.get('completed');
            if (!ctrl) {
                console.error('Un form de control de wizard debe tener el control "completed"');
            } else {
                ctrl.patchValue(true);
            }    
        }
    }

    /** Comprueba si un paso ya ha sido completado. */
    stepIsValid = (form: FormGroup): boolean => form.valid;

    continueTo(nextId: number): void {
        this.setStepAsValid(nextId);
        setTimeout(() => this.stepper.next());
    }

    goBack(): void {
        this.stepper.previous();
    }

    ngOnDestroy(): void {
        this.stepperService.form = new FormGroup({});
        this.stepperService.stepperClearAll();
        this.toolbarService.setStatus(false);
        // this.evalService.emitTutorPortfolioView(false);
        this.evalService.emitTutorStrWeak(false);
    }
}
