<div class="custom-container">
    <div class="row main-row">
        <div class="col-xs-12">
            <h1>
                <mat-icon>keyboard_arrow_right</mat-icon>Documentos de Evaluación de Competencia clínica (ECLOE)
            </h1>
        </div>
        <div *ngIf="connection" class="filtro" fxLayout="row" >
            <div style="margin-left:20px">
                <mat-form-field class="full-width"> <!--[formGroup]="form"-->
                    <mat-icon (click)="search()" matSuffix>search</mat-icon>
                    <input (keydown)="onKey($event)" #inputSearch style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por competencia" [(ngModel)]="searchText"><!--formControlName="searchBar"-->
                    <button mat-button *ngIf="searchText !== ''" matSuffix mat-icon-button (click)="clean()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field> 
            </div>
        </div>
        <div class="col-xs-12">
            <mat-card class="separate-bottom">
                <mat-card-content>
                    <button *ngFor="let btn of navButtons" mat-raised-button class="nav-btn separate-btn"
                            (click)="onSelectView(btn.key)"
                            [ngClass]="{ 'nav-btn-selected': isSelected(btn.key) }">
                        {{ btn.text }}
                    </button>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-xs-12" *ngIf="domains.length !== 0">
            <app-ev-doc-domains [domains]="domains" [selectedView]="selectedView" [idTipoMetodoEval]="idTipoMetodoEval" (hideIt)="hideSpinner();"></app-ev-doc-domains>
        </div>
        <div class="col-xs-12" *ngIf="series.length !== 0">
            <mat-card>
                <mat-card-content>
                    <app-ev-doc-competences [idTipoMetodoEval]="idTipoMetodoEval" (hideIt)="hideSpinner();" [competences]="series"></app-ev-doc-competences>
                </mat-card-content>
            </mat-card>
        </div>
        <div class="col-xs-12 text-center" *ngIf="domains.length === 0 && series.length === 0">
            <h3>No se ha encontrado ningún elemento.</h3>
        </div>
    </div>
</div>