<!-- Representación para tablets -->
<div id="modal-Events" class="page-layout blank p-10" style="max-height:100vh; overflow-y: scroll; overflow-x: hidden" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    <div>
        <mat-icon (click)="close()">close</mat-icon>
    </div>
    <mat-horizontal-stepper [linear]="isLinear" optional="false" #stepper>
            
        <mat-step [stepControl]="formStepOne">
            <ng-template matStepLabel>Paso 1:Datos</ng-template>
            <div class="medium-display" *ngIf="formStepOne !== undefined">
                <div [formGroup]="formStepOne" style="display: flex; flex-direction: row; flex-wrap: wrap">
                        <div style="display:flex; padding-bottom:5px; justify-content: space-between; width: 100%; padding-top: 5px">
                            <button style="margin-right:5px;" class="next-btn" mat-raised-button (click)="goBack()">
                                Anterior
                            </button>
                            <button mat-raised-button class="next-btn" (click)="next()">
                                Siguiente
                            </button>
                        </div>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Residente</mat-label>
                        <input matInput formControlName="residente" readonly> <!-- -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width" *ngIf="getRol2() === '2'">
                        <mat-label>Evaluador</mat-label>
                        <mat-select formControlName="idUsuarioCertificador"> <!-- -->
                            <mat-option *ngFor="let evaluador of tutorList" [value]="evaluador.id">
                                {{evaluador.apellidos}}, {{evaluador.nombre}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width" *ngIf="getRol2() === '3'">
                        <mat-label>Evaluador</mat-label>
                        <input matInput formControlName="idUsuarioCertificador" readonly> <!-- -->
                    </mat-form-field>
                    <mat-form-field (click)="getRol() === '2'? pickerPet.open() : null" appearance="outline" class="col-xs-12 col-md-6">
                        <mat-label>Día en que se realiza la petición</mat-label>
                        <input matInput [matDatepicker]="pickerPet" formControlName="fechaSolicitud" readonly> <!---->
                        <!--<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
                        <mat-datepicker #pickerPet></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field (click)="getRol() === '2'? picker.open() : null" appearance="outline" class="col-xs-12 col-md-6">
                        <mat-label>Fecha en la que se han realizado</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="fechaRealizacion" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Número de actos Realizados</mat-label>
                        <input matInput type="number" formControlName="numerosActosRealizados" [readonly]="cantEdit()"> <!---->
                    </mat-form-field>  
                </div>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Paso 2: Certificación del Tutor</ng-template>
            <div class="medium-display" *ngIf="formStepTwo !== undefined">
                <div style="background-color:#faebd7; display:flex; align-items:center; padding-left: 5px"> FIRMA DEL TUTOR (SI ESTÁ PRESENTE)</div>
                <div style="display:flex; padding-bottom:5px; justify-content: space-between; width: 100%; padding-top: 5px">
                    <button style="margin-right:5px;" class="next-btn" mat-raised-button (click)="goBack()">
                        Anterior
                    </button>
                    <button *ngIf="certificado !== '1' && !viewStr()" mat-raised-button class="next-btn" (click)="onSubmit()">
                        <span *ngIf="getRol() === '2'"> Guardar </span>
                        <span *ngIf="getRol() === '3' && !isCertified()"> Certificar </span>
                    </button>
                </div>
                <div [formGroup]="formStepTwo" style="display: flex; flex-direction: row; flex-wrap: wrap">
                    <mat-form-field appearance="outline" class="full-width" *ngIf="getRol2() === '2'">
                        <mat-label>Usuario</mat-label>
                        <input matInput formControlName="login"> <!-- -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width" *ngIf="getRol2() === '2'">
                        <mat-label>Contraseña</mat-label>
                        <input matInput type="password" formControlName="password"> <!-- -->
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label *ngIf="certificado !== '1'">Número de actos a certificar</mat-label>
                        <mat-label *ngIf="certificado === '1'">Número de actos certificados</mat-label>
                        <input matInput type="number" formControlName="numerosActosCertificados" [readonly]="isCertified()"> <!-- -->
                    </mat-form-field>
                </div>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
    <div class="medium-display">
    </div>
</div>

<!-- Representación para dispositivos pequeños -->
<!--<div class="small-display">
    <div class="row" *ngFor="let item of items">
        <div class="col-xs-4 table-header"><h4><b>Nº</b></h4></div>
        <div class="col-xs-8"> {{ item.number}} </div>

        <div class="col-xs-4 table-header"><h4><b>Procedimiento</b></h4></div>
        <div class="col-xs-8"> {{ item.procedure }} </div>

        <div class="col-xs-4 table-header"><h4><b>R1</b></h4></div>
        <div class="col-xs-8"> {{ item.r1 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R2</b></h4></div>
        <div class="col-xs-8"> {{ item.r2 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R3</b></h4></div>
        <div class="col-xs-8"> {{ item.r3 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R4</b></h4></div>
        <div class="col-xs-8"> {{ item.r4 }} </div>

        <div class="col-xs-4 table-header"><h4><b>R5</b></h4></div>
        <div class="col-xs-8"> {{ item.r5 }} </div>

        <div class="col-xs-4 table-header"><h4><b>Acciones</b></h4></div>
        <div class="col-xs-8"> ? </div>
    </div>
</div>-->
