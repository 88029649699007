<div class="page-layout blank p-24" fusePerfectScrollbar>

   <!-- HEADER -->
    <!--<h2 class="h2 text-center mt-20" style="font-size:22px; font-weight: 200;text-rendering: optimizeLegibility;">Residentes</h2>-->
  <!-- / HEADER -->
    <!--<h2 class="h2 text-center mt-20" style="font-size:22px; font-weight: 200;text-rendering: optimizeLegibility;">Evaluaciones</h2>-->
   <!-- <p class="mt-20 text-center mat-subheading-2" style="font-size:20px; font-weight: 200;text-rendering: optimizeLegibility;"></p> -->
    <div>
        <div style="display: flex; justify-content: space between">
            <mat-card class="col-xs-12" style="display: flex; flex-wrap: wrap; align-items: center">
                <img width=150 height= 150 style="border-radius: 50%" [src]="'assets/images/avatars/residentes/9/e2.jpg'">
                <div style="display: flex; flex-direction: column; margin-left: 5%">
                    <div style="margin-bottom: 5%; font-size: 18px; color:#428bca;">Nombre del Usuario</div>
                    <div style="display: flex;">
                        <div style="width:20px">
                            <mat-icon style="color:#777777; font-size: 15px">my_location</mat-icon>
                        </div>
                        <div>
                            Localidad, Provincia
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div style="width:20px">
                            <mat-icon style="color:#777777; font-size: 15px">person</mat-icon>
                        </div>
                        <div>
                            Cargo en Hospital
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <mat-accordion>
            <mat-expansion-panel *ngIf="rol === '3'" style="margin-top: 10px">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="text-center titulo-pag">RESIDENTES</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <residentes-ppal></residentes-ppal>
            </mat-expansion-panel>
            <mat-expansion-panel style="margin-top: 10px">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="text-center titulo-pag">EVALUACIONES</h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
