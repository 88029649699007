import { environment } from 'environments/environment';

import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { NetworkService } from './network.service';
import { IDbService } from './idb.service';

@Injectable({
    providedIn: 'root'
  })
export class UserService {

    private entityUrl = `${environment.backendUrl}/usuarios`;

    constructor (
        private http: HttpClient,
        private network: NetworkService,
        private dbService: IDbService
    ) {}
    
    getUserInfo(): Observable<any> {

        return this.network.isConnected().switchMap(connected => {
            if (connected) {  
                return this.http.get<any>(`${this.entityUrl}/getInfo`);
            } else {
                return from(this.dbService.get('info')).switchMap(info => {
                    return info ? of(info) : of([]);
                });
            }
        });    
    }

    getTutores(): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<any>(`${this.entityUrl}/getEvaluadores`);
            } else {
                return from(this.dbService.get('tutores')).switchMap(tutores => {
                    return tutores ? of(tutores) : of([]);
                });
            }
        }); 
    }

    certificate(data): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.post<any>(`${this.entityUrl}/createCertificacion`, data,  {observe: 'response'});
            } else {
                return of(null);
            }
        });
    }

    getTaskList(): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<any>(`${this.entityUrl}/getListaPendientes`);
            } else {
                return of(null);
            }
        });
    }

    markAsRead(idAviso): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<any>(`${this.entityUrl}/markAsRead?idAviso=${idAviso}`);
            } else {
                return of(null);
            }
        });
    }

    getCertificaciones(): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<any>(`${this.entityUrl}/getCertificaciones`);
            } else {
                return of(null);
            }
        });
    }

    getCertificacion(idCertificacion): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<any>(`${this.entityUrl}/getCertificacion?idCertificacion=${idCertificacion}`);
            } else {
                return of(null);
            }
        });
    }

    certificatePut(data): Observable<any> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.put<any>(`${this.entityUrl}/certificar`, data,  {observe: 'response'});
            } else {
                return of(null);
            }
        });
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }
}
