<div class="custom-container">
    <div class="row main-row">
        <div class="col-xs-12">
            <h1 style="color:#696969">
                <mat-icon>list</mat-icon>Cuaderno de Registro
            </h1>
        </div>
        <div  style="background-color:#1CAF9A; width:100%; color:white; font-size: 18px; margin-left: 15px; margin-bottom: 3px; padding: 0px 10px" *ngIf="registerInfo !== undefined && getRol()=== '2'">
            ATENCIÓN: se encuentra en {{registerInfo.anyoResidencia}}
            <button class= "col-xs-offset-10" style="margin-right:5px; float:right; background-color:#d9534f; color:white;" mat-raised-button (click)="getSolicitudCertif()">
                Ver mis solicitudes de certificación
            </button>
        </div>
        <div style="background-color:#1CAF9A; color:white; font-size: 18px; margin-left: 15px; margin-bottom: 3px; padding: 0px 10px" *ngIf="registerInfo !== undefined && getRol()=== '3'">
        {{residentInfo.apellidos}}, {{residentInfo.nombre}} se encuentra en {{registerInfo.anyoResidencia}}
        </div>
        <div class="col-xs-12" *ngIf="registerInfo !== undefined ">
            <ng-container *ngFor="let object of registerInfoKeys">
                <mat-accordion>
                    <mat-expansion-panel style="margin-bottom: 1px">
                        <mat-expansion-panel-header class="domain-header" style="background-color:#cccccc;">
                            <mat-panel-title>
                                <h3 style="color:#696969"><b>{{ object.text }}</b></h3>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-notebook-domains *ngIf="lookFor('subdomains', object.activity)" [domains]="registerInfo[object.activity]"
                         [year]="registerInfo.anyoResidencia" [idTipoActo]="object.idTipoActo"></app-notebook-domains>
                        <app-notebook-items *ngIf="!lookFor('subdomains', object.activity)" [items]="registerInfo[object.activity]"
                         [year]="registerInfo.anyoResidencia" [idTipoActo]="object.idTipoActo"></app-notebook-items>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-container>
        </div>
        <div class="col-xs-12 text-center" *ngIf="registerInfo === undefined">
            <h3>No se ha encontrado ningún elemento.</h3>
        </div>
        <div class="col-xs-12 text-center" *ngIf="registerInfo === null">
            <h3>Página no disponible sin conexión.</h3>
        </div>
    </div>
</div>