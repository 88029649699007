<div *ngIf="data">
    <h2 class="text-center"> {{ data.title }} </h2>
    <h4 class="text-center"> Tipo {{ data.evalNameType }} </h4>
    <div *ngFor="let section of data.sections">
        <div class="section-title">
            {{ section.text }}
        </div>
        <div class="section-content" [innerHtml]="sanitizeHtml(section.help)"></div>    
    </div>
</div>
