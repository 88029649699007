<mat-toolbar style="margin-top:1px" class="mat-toolbar-head p-0">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div class="open-toolbar">
            <div style="display: flex; justify-content: flex-start; width: 100%; margin-left: 20px" [ngClass]="{'iPadDate': lastRefreshDateTime}" class="iPad">
                <button (click)="back_clk()" *ngIf="showBackButton" class="btn-back-history" aria-label="add to favorites" mat-icon-button="">
                    <mat-icon class="mat-icon notranslate  material-icons mat-icon-no-color btn-history-back" role="img" aria-hidden="true">
                        arrow_back</mat-icon>
                </button>
            </div>
            <button (click)="back_clk()" *ngIf="showBackButton" class="btn-back-history" class="mobile-fixed" aria-label="add to favorites" mat-icon-button="">
                <mat-icon class="mat-icon notranslate  material-icons mat-icon-no-color btn-history-back" role="img" aria-hidden="true">
                    arrow_back</mat-icon>
            </button>
            <div style="display:flex; align-items: center; flex-direction: column; margin-top: 2vh;" [ngClass]="{'mobile': !lastRefreshDateTime, 'mobileSync': lastRefreshDateTime}">
                <button *ngIf="pendingEvaluations > 0" mat-icon-button aria-haspopup="true" (click)="openDialog()"
                    style=" background-color: #17a08c !important; margin-bottom: -5px"
                    [matBadge]="pendingEvaluations" matBadgePosition="before" matBadgeColor="warn">
                        <mat-icon style="color: white; font-size: 25px">sync</mat-icon>
                </button>
                <button *ngIf="pendingEvaluations === 0 || pendingEvaluations === null" mat-icon-button aria-haspopup="true" (click)="openDialog()"
                    style=" background-color: #17a08c !important; margin-bottom: -5px">
                        <mat-icon style="color: white; font-size: 25px">sync</mat-icon>
                </button>
                <div *ngIf="lastRefreshDateTime" class="refresh-date"> {{ lastRefreshDateTime | date:'dd/MM/yy HH:mm' }} </div>
                <div *ngIf="!lastRefreshDateTime" class="refresh-date"> <b>¡Sincronice cuanto antes!</b> </div>
            </div>

            <div></div>

            <!--fxlayout="column" fxlayoutalign="center center"
                style="flex-direction: row; box-sizing: border-box; display: flex; place-content: center; align-items: center;">-->

            <div style="display:flex; align-items: center; flex-direction: column; margin-top: 2vh;" [ngClass]="{'iPadDate': lastRefreshDateTime}" class="iPad">
                <button *ngIf="pendingEvaluations > 0" mat-icon-button aria-haspopup="true" (click)="openDialog()"
                    style=" background-color: #17a08c !important; margin-bottom: -5px"
                    [matBadge]="pendingEvaluations" matBadgePosition="before" matBadgeColor="warn">
                        <mat-icon style="color: white; font-size: 25px">sync</mat-icon>
                </button>
                <button *ngIf="pendingEvaluations === 0 || pendingEvaluations === null" mat-icon-button aria-haspopup="true" (click)="openDialog()"
                    style=" background-color: #17a08c !important; margin-bottom: -5px">
                        <mat-icon style="color: white; font-size: 25px">sync</mat-icon>
                </button>
                <div *ngIf="lastRefreshDateTime" class="refresh-date"> {{ lastRefreshDateTime | date:'dd/MM/yy HH:mm' }} </div>
                <div *ngIf="!lastRefreshDateTime" class="refresh-date"> <b>¡Sincronice cuanto antes!</b> </div>
            </div>

            <!--por algún motivo el botón de sincronía se desplaza enormemente cuando no está el de perfil. Para mantenerlo a raya, tenemos este otro.-->
            <!--<div *ngIf="!showIconProfileImg" style="display:flex; align-items: center; flex-direction: column; position: relative; right: 6.2vw; top: 1vh">   
                <button *ngIf="!showIconProfileImg" mat-icon-button aria-haspopup="true" (click)="openDialog()"
                style="margin-bottom: -5px; background-color: #17a08c !important">
                    <mat-icon style="color: white; font-size: 25px">sync</mat-icon>
                </button>
                <div *ngIf="lastRefreshDateTime" class="refresh-date"> {{ lastRefreshDateTime | date:'HH:mm, d/M' }} </div>
                <div *ngIf="!lastRefreshDateTime" class="refresh-date"> <b>¡Sincronice cuanto antes!</b> </div>
            </div>-->
            <div style="min-width: 40px; width: 40px;">
                <button routerLink="/profile" mat-icon-button *ngIf="showIconProfileImg" aria-haspopup="true" mat-button="">
                        <mat-icon style="font-size: 25px">more_vert</mat-icon>
                </button>
            </div>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>
        </div>
    </div>
</mat-toolbar>