import { AlertService } from './../../../../services/alert.service';
import { EvaluationTypeIds } from './../../../../models/evaluation-type-ids.enum';
import { EvaluationResidentService } from './../evaluation-resident.service';
import { StepperResidentService } from './../../../../services/stepper-resident.service';
import { UtilsService } from './../../../../services/utils.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef, EventEmitter, Output, Input, ViewChild } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from '../i18n/en';
import { locale as spanish } from '../i18n/es';
import { ToolbarService } from '../../../../layout/components/toolbar/toolbar.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Profile } from '../../../../models/profile';
import { ProfileService } from '../../../../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
// import { ThrowStmt } from '@angular/compiler';
import { MatRadioChange } from '@angular/material';
import { MatStepper } from '@angular/material/stepper';


import { SpinnerService } from '../../../../services/spinner.service';
import { DialogService } from '../../../../services/dialog.service';
import { CreateEvaluation } from 'app/models/create-evaluation.model';

export interface NotificationConfig {
    trainTime: boolean;
    noActivity: boolean;
    unreadMessages: boolean;
    news: boolean;
  }
  
  export interface LanguageConfig {
    language: string;
  }
  
export interface Residentes {
    id: string;
    nombre_completo: string;
    nombre: string;
    apellidos: string;
    centro: string;
    localidad: string;
    provincia: string;
    especialidad: string;
    servicio: string;
    rotandoPor: string;
    anoResidencia: string;
    nombre_completo_tutor: string;
    activo: string;
    imagen: string;
}
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'stepper-three-resident',
    templateUrl: './stepper-three-resident.component.html',
    styleUrls  : ['./stepper-three-resident.component.scss'],
    animations   : fuseAnimations
})

export class StepperThreeResidentComponent implements OnInit
{   
    @Output() complete = new EventEmitter<void>();
    @Output() goback = new EventEmitter<void>();
    @Output() changeSelectedTipoMetodoEval = new EventEmitter();
    @Input() selectedTipoMetodoEval;
    isLinear = true;
    evalType = EvaluationTypeIds;
    valueOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    metodoEvalComparator = Number(this.stepperService.idTipoMetodoEval);
    form: FormGroup;
    keys: any[];
    idTipoMetodoEval: number;
    ayuda = '';
    cobatrice = '';

    isView = false;
    tutorView;
    strWeakView;
    tipoEval: any;

    stepper;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        public _domSanitizer: DomSanitizer,
        public userService: UserService,
        private _formBuilder: FormBuilder,
        private utils: UtilsService,
        private stepperService: StepperResidentService,
        private evaluationService: EvaluationResidentService,
        private alertService: AlertService,
        private router: Router,
        private alert: AlertService,
        private spinner: SpinnerService
    )
    {

    }
    ngOnInit(): void {
        let values = this.stepperService.stepperInfoGetObject();
        if (values['checking'] != undefined) {
            this.isView = true;
            this.evaluationService.tutorPortfolioView.subscribe(view => this.tutorView = view).unsubscribe();
            this.evaluationService.tutorStrWeak.subscribe(view => this.strWeakView = view).unsubscribe();
        }
        this.tipoEval = values.tipoEval;
        if (this.metodoEvalComparator === this.evalType.ECLOE || 
        this.metodoEvalComparator === this.evalType.EPOE) {
            this.form = this._formBuilder.group({
                'login': [null],
                'password':[null],
                'comentariosPositivos': [values['comentariosPositivos']!= undefined ? values['comentariosPositivos']: null],
                'areasMejora': [values['areasMejora']!= undefined ? values['areasMejora']: null],
                'plan': [values['plan']!= undefined ? values['plan']: null],
                'satisfacionResidente': [values['satisfaccionResidente'] != undefined ? Number(values['satisfaccionResidente']): 1, [Validators.required]],
                'satisfacionEvaluador': [values['satisfaccionEvaluador'] != undefined ? Number(values['satisfaccionEvaluador']): 1, [Validators.required]],
                'minutosObservacion': [values['minutosObservacion']!= undefined ? values['minutosObservacion']: null, [Validators.min(0), Validators.required]],
                'minutosEvaluacion': [values['minutosEvaluacion']!= undefined ? values['minutosEvaluacion']: null, [Validators.min(0), Validators.required]]
            });
        } else {
            this.form = this._formBuilder.group({
                'login': [null],
                'password':[null],
                'comentariosPositivos': [values['comentariosPositivos']!= undefined ? values['comentariosPositivos']: null],
                'areasMejora': [values['areasMejora']!= undefined ? values['areasMejora']: null],
                'plan': [values['plan']!= undefined ? values['plan']: null],
            });
        }

        this.stepper = this.stepperService.getStepper();
    }

    buildForm(preguntas: any[]): FormGroup {
        let formly = {};
        if(this.isView) {
            preguntas.forEach((pregunta: any) => {
                const key = String(pregunta.idApartado);
                const object = {[key]: [null], disabled: true};
                this.keys.push({key: [key], text: pregunta.text});
                formly = {...formly, ...object};
            });
            // console.log(formly);
            return this._formBuilder.group(
                formly
            );
        } else {
            preguntas.forEach((pregunta: any) => {
                const key = String(pregunta.idApartado);
                const object = {[key]: [null]};
                this.keys.push({key: [key], text: pregunta.text});
                formly = {...formly, ...object};
            });
            return this._formBuilder.group(
                formly
            );
        }

    }


    onSubmit(): void {
        if (this.form.valid) {
            this.stepperService.stepperInfoRewrite(this.form.getRawValue());
            let values = this.stepperService.stepperInfoGetObject();
            // console.log(values)
            values["fechaNoFormat"] = null;
            this.spinner.show()
            this.evaluationService.postEvaluacionResidente(values).subscribe((result) => {
              //  this.stepperService.stepperClearAll();
                this.spinner.hide()
                this.alertService.showSuccess(result.toString());
                if (this.stepperService.docEvalFlag === true) {
                    this.router.navigate(['documentos-evaluacion']);
                } else {
                    this.router.navigate(['misevaluaciones'], { queryParams: { tipo: 'competencias' }});
                }
            }), error => {
                 this.spinner.hide();
                 this.alertService.showError('No se pudo guardar la evaluación');
            }

            this.complete.next();
        } else {
            // console.log(this.form);
+           this.utils.validateAllFormFields(this.form);
            this.alert.showError('Por favor, rellene todos los campos obligatorios.');
        }
    }

    exitView(): void {
       // console.log(this.router.navigate(['misevaluaciones', this.tipoEval]));
        this.stepperService.stepperClearAll();
        if (this.userService.getRol() === '3') {
            if (this.tutorView) {
                this.router.navigate(['miportafolio']);
            } else if (this.strWeakView) {
                this.evaluationService.emitTutorStrWeak(false);
                this.router.navigate(['fortalezas-y-debilidades']);
            } else {
                this.router.navigate(['misevaluaciones'], { queryParams: { tipo: 'competencias'}});
            }
        } else if (this.userService.getRol() === '2') {
            this.router.navigate(['miportafolio']);
        }
    }
    
    goBack(): void{
        this.stepperService.stepperInfoRewrite(this.form.getRawValue());
        this.stepper.previous();
    }

    submitAll(): void {
        // console.log('¡Felicidades! Te has ganado un pin por pensar que esto ya funcionaba.');
    }

}
