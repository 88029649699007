import { EvaluationTypeIds } from './../../../models/evaluation-type-ids.enum';
import { EvDocDomain } from './../models/ev-doc-domain.model';
import { EvDocService } from './../ev-doc.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EvDocViews } from '../ev-doc-views.enum';
import { SpinnerService } from 'app/services/spinner.service';
import { EvDocCompetence } from '../models/ev-doc-competence.model';
import { NetworkService } from 'app/services/network.service';

@Component({
  selector: 'app-evaluation-documents-page',
  templateUrl: './evaluation-documents-page.component.html',
  styleUrls: ['./evaluation-documents-page.component.scss']
})
export class EvaluationDocumentsPageComponent implements OnInit {

    navButtons = [
        { key: EvDocViews.DOC_EVAL, text: 'Ver Documentos de Evaluación' },
        { key: EvDocViews.ORG_PROCEDURES, text: 'Ver Organizado por Procedimientos' },
        { key: EvDocViews.ORG_CASES, text: 'Ver Organizado por Casos' },
        { key: EvDocViews.DEGREE_360, text: '360º' },
        { key: EvDocViews.SPECIAL_CASES, text: 'Casos Especiales' },
        { key: EvDocViews.SIMULATIONS_COURSES, text: 'Simulaciones, cursos o talleres' },
    ];

    selectedView: EvDocViews = EvDocViews.ORG_CASES;
    connection: boolean;
    idTipoMetodoEval: number;
    domains: EvDocDomain[] = []; // Se usa para casos, procedimientos y 360º
    series: EvDocCompetence[] = []; // Se usa para casos especiales y simulaciones.
    searchText = "";
    @ViewChild('inputSearch') inputSearch;
    constructor(
        private evDocService: EvDocService,
        private spinner: SpinnerService,
        private networkService: NetworkService,
    ) { }

    ngOnInit(): void {
        this.networkService.isConnected().subscribe(conn => this.connection = conn);
        this.networkService.connectionChange$.subscribe((connection) => {
            this.connection = connection;
        });
        this.loadData(this.searchText);
    }

    onSelectView = (view: EvDocViews) => {
        this.selectedView = view;
        this.searchText = "";
        this.loadData(this.searchText);
    }
    onKey(e) {
        
        if (e.keyCode == 13) {
            this.inputSearch.nativeElement.blur()
            this.search();
        }
    }
    search(){
        this.loadData(this.searchText);
    }
    clean(){
        this.searchText="";
        this.loadData(this.searchText);
    }

    isSelected = (view: EvDocViews) => view === this.selectedView;

    /** 
     * Carga datos diferentes según la vista que se haya seleccionado.
    */
    loadData(filter): void {
        this.spinner.show();
        switch (this.selectedView) {
            case EvDocViews.DOC_EVAL:
                this.domains = this.series = [];
                this.evDocService.getAllEvDocs(filter).subscribe(domains => {
                    this.domains = domains;
                    // this.idTipoMetodoEval = EvaluationTypeIds.
                    this.spinner.hide();
                }, () => this.spinner.hide());                    
                break;
            case EvDocViews.ORG_PROCEDURES:
                this.domains = this.series = [];
                this.evDocService.getByProcedures(filter).subscribe(domains => {
                    this.domains = domains;
                    this.idTipoMetodoEval = EvaluationTypeIds.EPOE;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvDocViews.ORG_CASES:
                this.domains = this.series = [];
                this.evDocService.getByCases(filter).subscribe(domains => {
                    this.domains = domains;
                    // console.log(this.domains);
                    this.idTipoMetodoEval = EvaluationTypeIds.ECLOE;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvDocViews.DEGREE_360:
                this.domains = this.series = [];
                this.evDocService.getBy360(filter).subscribe(domains => {
                    this.domains = domains;
                    this.idTipoMetodoEval = EvaluationTypeIds.v360;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvDocViews.SPECIAL_CASES:
                this.domains = this.series = [];
                this.evDocService.getSpecialCases(filter).subscribe(series => {
                    this.series = series;
                    this.idTipoMetodoEval = EvaluationTypeIds.SPECIAL;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            case EvDocViews.SIMULATIONS_COURSES:
                this.domains = this.series = [];
                this.evDocService.getSimulations(filter).subscribe(series => {
                    this.series = series;
                    this.idTipoMetodoEval = EvaluationTypeIds.SIMULATION;
                    this.spinner.hide();
                }, () => this.spinner.hide());
                break;
            default:
                this.spinner.hide();
        }
    }


    hideSpinner(): void {
        
        if (this.domains.length === 0 && this.series.length === 0){

        } else {
            this.spinner.hide();
        }
    }

    consolelog = (ev) => console.log(ev);
}
