export const locale = {
    lang: 'es',
    data: {
        'cuenta': {
            'cuenta': 'Cuenta',
            'perfil': 'Mi Perfil',
            'programa': 'Mi Programa',
            'cerrarSesion': 'Cerrar Sesión'
        },

        'otro': {
            'otro': 'Otro'
        }
    }
};
