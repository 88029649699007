export const locale = {
    lang: 'es',
    data: {
        'footer': {
            'calendar': 'Calendario',
            'recipes': 'Recetas',
            'webminars': 'Webinars',
            'programme': 'Programa',
            'galery': 'Galería',
            'contact': 'Mensajes'

        }
    }
};
