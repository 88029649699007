<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">

   <!-- <h2 class="text-center titulo-pag">Pérfil </h2> -->

    <div class="user-info mb-20" style="padding:20px;margin-left: 40px" fxLayout="column" fxLayout.gt-sm="row">

        <img class="profile-img avatar huge" *ngIf="imagenPerfil !== null" style='display: cover' [src]="_domSanitizer.bypassSecurityTrustUrl(imagenPerfil)">
        
        <div style="width: 100%;margin-left:5%">
            <div class="name">{{ name }}</div>
            <div class="tipo">{{ puesto }}</div>
            <div class="localizacion">{{ localidad }} ({{ provincia }})</div>
        </div>
        
        <div *ngIf="notifications !== undefined">
            <mat-card (click)="goToPending()"
            style="background-color:#d9534f; color: white; padding: 2px 12px !important; margin-bottom: 5px; margin-right:5px;" *ngFor="let notification of notifications">
                {{notification.pendiente}}
            </mat-card>
        </div>

        <!-- [src]="_domSanitizer.bypassSecurityTrustUrl(selfieImage)" (click)="onAvatarSelected_click()" -->
    </div>

    <div class="user-form ">

        <div class="avatar-control">
            <!-- button class=" mat-icon-button" type="button" aria-label="" mat-icon-button="" (click)="onAvatarSelected_click()">
                        <mat-icon class="mat-icon">photo_camera</mat-icon>
            </button -->
<!--
            <button class=" mat-icon-button" type="button" aria-label="" mat-icon-button="" (click)="fileInput.click()">
                <mat-icon class="mat-icon">photo_camera</mat-icon>
            </button>
            <input hidden (change)="onAvatarSelected()" #fileInput name="avatar" type="file" id="avatarfile">
-->                    
        </div>
        <mat-card style="margin-bottom:5px">
            <mat-card-title>CONTACTOS</mat-card-title>
            <mat-card-content style="display:flex; flex-wrap:wrap; justify-content: space-between">
                <mat-card *ngFor="let contact of contacts" class="col-xs-12 col-md-5-94" style="margin-bottom: 7px">
                    <div>
                        <mat-icon 
                        style="height: 20px !important;
                        width: 20px!important;
                        position: relative;
                        top: 3px;
                        margin: 0px 5px 0px 0px;">person</mat-icon>
                        <span style="color:#428bca"><b>{{contact.cargo}}</b></span> <b> {{contact.nombre_completo}}</b></div>
                    <div><span style="color:#428bca"><b>{{contact.email}}</b></span></div>
                    <div><span style="color:#428bca"><b>{{contact.telefono}}</b></span></div>
                </mat-card>
            </mat-card-content>
        </mat-card>

        <mat-card style="margin-bottom:5px">
            <mat-card-title>MIS ACCESOS DIRECTOS</mat-card-title>
            <mat-card-content style="display:flex; flex-wrap:wrap; justify-content: space-between">
                <mat-card *ngFor="let link of links" class="col-xs-12 col-md-5-94" style="margin-bottom: 7px">
                    <a (click)="getUrl(link)"><b>{{link.nombre}}</b></a>
                </mat-card>
            </mat-card-content>
        </mat-card>

        <mat-card style="margin-bottom:5px">
            <mat-card-title>PROXIMOS EVENTOS</mat-card-title>
            <mat-card-content style="display:flex; flex-wrap:wrap; justify-content: space-between">
                <mat-card *ngFor="let event of events" class="col-xs-12 col-md-5-94" style="margin-bottom: 7px">
                    <mat-card-title>{{event.nombre}}</mat-card-title>
                    <mat-card-content (click)="openInfo(event)">
                        <div style="color:#428bca">{{event.lugar}}</div>
                        <div style="color:#428bca">{{event.fechaDesde}}</div>
                    </mat-card-content>
                </mat-card>
            </mat-card-content>
        </mat-card>

        <mat-divider style="margin: 2%"></mat-divider>
            <div style="width: 100%; display:flex; align-items: center">
                <div style="width: 50%; display:flex; align-items: center">
                    <mat-icon style="font-size: 40px; color: grey" _ngcontent-c14="" aria-hidden="true" (click)="logout_clk()"
                    > <!--class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"-->
                    exit_to_app
                    <!--<img src="assets/images/cerrarsesion.png" />-->
                    </mat-icon>
                    <span style="font-size: 120%; cursor: pointer" (click)="logout_clk()">Cerrar Sesión</span>
                </div>
                <div style="width: 50%; display:flex; align-items: center">
                    <span style="font-size: 120%; cursor: pointer" (click)="openPolicy()">Ver políticas de privacidad (Pendiente)</span>
                </div>
            </div>
        <mat-divider style="margin: 2%"></mat-divider> 
    </div>

    

</div>
