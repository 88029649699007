export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'MENU': 'Menu',
			'RECIPES': {
                'TITLE': 'RECIPES'
            },
            'PROGRAMME': {
                'TITLE': 'PROGRAMME'
            },
            'WEBMINARS': {
                'TITLE': 'WEBMINARS'
            },
            'CONTACT': {
                'TITLE': 'CONTACT'
            },
            'PROFILE': {
                'TITLE': 'PROFILE'
            },
            'GALERY': {
                'TITLE': 'GALERY'
            },
            'LOGOUT': {
                'TITLE': 'LOGOUT'
            }
        }
    }
};
