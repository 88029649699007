import { HttpClient } from '@angular/common/http';
import { AlertService } from 'app/services/alert.service';
import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { Subject, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {

    private connected: boolean = null;
    private _connectionChange$: Subject<boolean> = new Subject<boolean>();
    readonly connectionChange$: Observable<boolean> = this._connectionChange$.asObservable();

    constructor (
        private connectionService: ConnectionService,
        private http: HttpClient
    ) { }

    public init(): void {
        this.checkConnection().subscribe();

        // Utilizamos un servicio de terceros para comprobar la conexión.
        this.connectionService.monitor().subscribe(isConnected => {
            this.connected = isConnected;
            // this.connected ? console.log('Conectado') : console.log('Desconectado');
            this._connectionChange$.next(this.connected);
        });
    }

    public checkConnection(): Observable<boolean> {

        return new Observable<boolean>(o => {
            // ConnectionService parece dar problemas con la primera comprobación: la hacemos nosotros manualmente.
                this.http.get('https://portresapp.implemento.es/dummy').subscribe((a) => {

                    this.connected = true;
                    this._connectionChange$.next(this.connected);
                    // console.log('Conectado');

                    o.next(this.connected);
                    o.complete();
                }, error => {
                    this.connected = false;
                    this._connectionChange$.next(this.connected);
                    // console.log('Desconectado');
                    o.next(this.connected);
                    o.complete();
                });
            });
    }

    public isConnected = (): Observable<boolean> => {
        if (this.connected !== null) {
            return of(this.connected);
        }
        return this.checkConnection();
    }

}
