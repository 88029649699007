<!--<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">-->

    <div class="custom-container col-xs-12">
        <div class="row">
            <div class="col-xs-12" id="form-eval">
                <mat-horizontal-stepper [linear]="isLinear" optional="false" #stepper>
                    <ng-container>
                        <mat-step [stepControl]="forms[0]">
                            <ng-template matStepLabel>Paso 1: Tratamiento</ng-template>
                            <stepper-zero (complete)="continueTo(0)" (goback)="goBack()"
                            *ngIf="stepper.selectedIndex === 0"></stepper-zero>
                        </mat-step>
                    </ng-container>
                    
                    <ng-container>
                        <mat-step [stepControl]="forms[1]">
                            <ng-template matStepLabel>Paso 2: Evaluación</ng-template>
                            <stepper-one (complete)="continueTo(1)" (goback)="goBack()"
                            *ngIf="stepper.selectedIndex === 1"></stepper-one>
                        </mat-step>
                    </ng-container>
            
                    <ng-container>
                        <mat-step [stepControl]="forms[2]">
                            <ng-template matStepLabel>Paso 3: Resultados</ng-template>
                            <stepper-two (complete)="continueTo(2)" (goback)="goBack()"
                            *ngIf="stepper.selectedIndex === 2"></stepper-two>
                        </mat-step>
                    </ng-container>
            
                    <ng-container>
                        <mat-step [stepControl]="forms[3]">
                            <ng-template matStepLabel>Paso 4: Finalizar</ng-template>
                            <stepper-three (complete)="continueTo(3)" (goback)="goBack()"
                            *ngIf="stepper.selectedIndex === 3"></stepper-three>
                        </mat-step>
                    </ng-container>
                </mat-horizontal-stepper>
            </div>
        </div>
    </div>
    <!--<h2 class="text-center titulo-pag" style="margin-bottom: 20px">RESIDENTES</h2>-->

    <!--<div class="user-info mb-7" style="padding:20px;" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="center" >

        <div>Nombre</div>
        <div>Centro</div>
        <div>Servicio</div>
        <div>Especialidad</div>
        <div>Activo</div>

    </div>-->
    <!--<button mat-raised-button (click)="isLinear = !isLinear" id="toggle-linear">
        {{!isLinear ? 'Enable linear mode' : 'Disable linear mode'}}
    </button>-->