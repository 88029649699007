import { ToolbarService } from './../../../layout/components/toolbar/toolbar.service';
import { SpinnerService } from './../../../services/spinner.service';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { Subject } from 'rxjs';
import { RotacionesService } from 'app/services/rotaciones.service';
import { Rotaciones } from 'app/models/rotaciones.model';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

declare let cordova: any;

@Component({
    selector   : 'app-rotaciones',
    templateUrl: './rotaciones.component.html',
    styleUrls  : ['./rotaciones.component.scss'],
    animations   : fuseAnimations
})

export class RotacionesComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('inputSearch') inputSearch;
    // userName = 'Barrios de Pedro, Marcos';
    dataSource:  MatTableDataSource<Rotaciones[]>;
    downloadUrl = environment.evaluationsUrl;
    residente: any;
    
    displayedColumns = ['year', 'hospitalName', 'service', 'rotationTutor', 'startDate', 'endDate', 'evalutationDoc'];
    searchAtributes = ['hospitalName', 'service', 'startDate', 'endDate'];
    rotaciones: any = [];
    allRotaciones: any = [];
    private _unsubscribeAll: Subject<any>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    
    constructor(private matDialog: MatDialog,
                public _dialog: MatDialog,
                private rotacionesService: RotacionesService,
                private router: Router,
                private spinner: SpinnerService,
                private toolbar: ToolbarService) {
                    
                }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnInit(): void {
        this.spinner.show();
        this.rotacionesService.residenteRotacion.subscribe(residente => {
            this.residente = residente;
        if (this.residente !== null && this.residente !== undefined) {
            this.rotacionesService.getRotaciones(this.residente.id).subscribe(rotaciones => {
                if (this.getRol() === '3') {
                    this.toolbar.setStatus(true);
                }
                this.rotaciones = rotaciones;
                this.dataSource = rotaciones;
                this.allRotaciones = rotaciones;
                this.spinner.hide();
            });   
        } else {
            this.spinner.hide();
            this.router.navigate(['misresidentes']);
        }

        });

        this.dataSource = new MatTableDataSource<Rotaciones[]>(this.rotaciones);
    }

    ngOnDestroy(): void {
        this.toolbar.setStatus(false);
    }

    /** 
    * Se ejecuta cuando se ha realizado una búsqueda.
    */
    onSearchResult(filteredRotaciones: Rotaciones[]): void {
        this.rotaciones = filteredRotaciones;
        this.dataSource = this.rotaciones;
    }
    onKey(e) {
        if (e.keyCode == 13) {
            this.inputSearch.nativeElement.blur()
        }
    }
    downloadFile(evalutationDoc): void {
        const urlDownload = `${this.downloadUrl}/${this.residente.id}/${evalutationDoc}`;
        window.open(urlDownload, '_system', 'location=yes');
        /*
        cordova.plugins.fileOpener2.open(
            urlDownload,
            'application/pdf',
            {
                error : function(e) {
                    console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                    console.log(urlDownload);
                },
                success : function () {
                    console.log('file opened successfully');
                    console.log(urlDownload);
                }
            }
        );*/
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }
}
