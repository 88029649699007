import { SpinnerService } from './../../../services/spinner.service';
import { EvDocDomain } from './../models/ev-doc-domain.model';
import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { EvDocViews } from '../ev-doc-views.enum';

@Component({
  selector: 'app-ev-doc-domains',
  templateUrl: './ev-doc-domains.component.html',
  styleUrls: ['./ev-doc-domains.component.scss']
})
export class EvDocDomainsComponent implements OnInit {

    // Este componente renderiza tanto dominios como subdominios, según cual de los dos le llegue.
    @Input() domains: EvDocDomain[];
    @Input() isSubdomain = false;
    @Input() selectedView: EvDocViews;
    @Input() idTipoMetodoEval?: number;
    @Output() hideIt = new EventEmitter<any>();
    
    constructor(
        private spinner: SpinnerService
    ) { }

    ngOnInit(): void {
    }

    isMainDocEval = () => this.selectedView === EvDocViews.DOC_EVAL;

    hideSpinner(): void {
        this.hideIt.next();
    }
}
