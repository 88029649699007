import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PortActivity } from 'app/main/pages/portfolio/models/port-activity.model';

@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {

    private entityUrl = `${environment.backendUrl}/usuarios/getActividades`;

    constructor (
        private http: HttpClient
    ) { }

    getPortfolioActivities(residentId = 0): Observable<PortActivity[]> {
        return this.http.get<PortActivity[]>(`${this.entityUrl}?idResidente=${residentId}`);
    }
}