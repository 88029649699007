<mat-accordion class="width: 100%">
    <mat-expansion-panel *ngFor="let domain of domains" class="width: 100%">
        <mat-expansion-panel-header class="domain-header" [ngClass]="{ 'subdomain-header': isSubdomain }">
            <mat-panel-title>
                <h3><b>{{ domain.name }}</b></h3>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row" style="margin-top: 8px">
            <div class="col-xs-12" *ngIf="domain.subdomains">
                <app-ev-doc-domains [idTipoMetodoEval]="idTipoMetodoEval" [domains]="domain.subdomains" [isSubdomain]="true" [selectedView]="selectedView"></app-ev-doc-domains>
            </div>
            <ng-template matExpansionPanelContent>
                <div class="col-xs-12" *ngIf="domain.competences">
                    <app-ev-doc-competences [idTipoMetodoEval]="idTipoMetodoEval" *ngIf="!isMainDocEval()" [competences]="domain.competences" (hideIt)="hideSpinner()"></app-ev-doc-competences>
                    <app-ev-doc-competences-main *ngIf="isMainDocEval()" [competences]="domain.competences" (hideIt)="hideSpinner()"></app-ev-doc-competences-main>
                </div>
            </ng-template>
        </div>

    </mat-expansion-panel>
</mat-accordion>