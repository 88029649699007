import { FormGroup, FormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    
    validateAllFormFields(formGroup: FormGroup): void {       
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);           
            if (control instanceof FormControl) {           
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {      
                this.validateAllFormFields(control);          
            }
        });
    }

    getMimeTypeFromBase64(base64Str: string): string {
        const regex = /^data:(.*);/gm;
        const matches = regex.exec(base64Str);
        return matches[1];
    }

    formatApiDateToUserDate = (v: string) => moment(v, 'YYYY-MM-DD HH-mm-ss').format('HH-mm DD-MM-YYYY');
}
