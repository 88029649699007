<div id="modal-Events" class="page-layout blank p-10" style="max-height:100vh; overflow-y: scroll; overflow-x: hidden" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    
    <div style="display: flex; justify-content: space-between; align-items: center; background-color:#f0f8ff; margin-bottom: 5px">
        <div style="margin-left: 5px"><b>Datos de la Certificación</b></div>
        <button mat-icon-button (click)="close()" disableRipple><mat-icon 
         style="display: flex; align-items: center; justify-content: center;">close</mat-icon></button>
    </div>
   <!-- <h2 class="text-center titulo-pag">Pérfil </h2> -->

    <div style="background-color:#eeeeee">
        <mat-card>
            <mat-card-content>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Residente:</b></span> {{data.residente}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Año Residencia:</b></span> {{data.anyoResidencia}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Evaluador:</b></span> {{data.evaluador}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Día en que se realiza la petición:</b></span> {{data.fechaSolicitud}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Número de actos realizados:</b></span> {{data.solicitadas}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Fecha en la que se han realizado:</b></span> {{data.fechaRealizacion}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Certificado:</b></span> {{translate(data.certificado)}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Fecha Certificación:</b></span> {{data.fechaCertificado}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <span class="col-xs-5"><b>Número de actos certificados:</b></span> {{data.certificadas}}
                </div>
                <div style="display: flex; margin-bottom: 5px;">
                    <button *ngIf="!isCertified()" (click)="openRequest(data.idCertificacion)" mat-raised-button style="background-color:#428bca; color: white; margin-top: 3px; max-width: 180px"> 
                        Certificar
                    </button>
                    <button *ngIf="isCertified()"(click)="openRequest(data.idCertificacion)" mat-raised-button style="background-color:#428bca; color: white; margin-top: 3px; max-width: 180px"> 
                        Ver
                    </button>
                </div>
            </mat-card-content>
        </mat-card>

    </div>

    

</div>
