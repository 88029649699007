import { WebStorageService } from './web-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'app/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Rotaciones } from 'app/models/rotaciones.model';
import { AlertService } from './alert.service';

@Injectable({
    providedIn: 'root'
  })
export class RotacionesService {
    baseUrl: string;
    residentesUrl: string;
    
    headers: HttpHeaders;
    accessToken;

    residenteRotacion: BehaviorSubject<any>;
    onRotacionesChange: BehaviorSubject<any>;

    constructor (public http: HttpClient,
                private config: ApiService,
                private alert: AlertService,
                private webStorageService: WebStorageService) {
    
        this.baseUrl = this.config.getBackEndUrl();
        this.residentesUrl = `${this.baseUrl}/residentes/`;
        this.headers = new HttpHeaders().set('Content-Type', 'application/json');

        this.onRotacionesChange = new BehaviorSubject([]);
        this.residenteRotacion = new BehaviorSubject(null);

        // Subscribe to any token changes.
        /*
        this.webStorageService.accessToken$.subscribe(accessToken => {
          this.accessToken = 'FFI2Wr66MCxb4aixM7HpB_VOSmvLfNjE';
        }, error => this.alert.showError({ message: 'No se ha podido recuperar el token de acceso.' }));
        */
    }

    emitResidente(residente): void {
        this.residenteRotacion.next(residente);
    }

    getRotaciones(idResidente): Observable<any> {
        return new Observable<any>(observer => {
          this.http.get(`${this.residentesUrl}getRotaciones/${idResidente}`).subscribe(rotaciones => {
            observer.next(rotaciones);
            observer.complete();
          });
        });
    }
}
