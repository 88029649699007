
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CordovaService } from '../../services/cordova.service';

declare let device: any;

@Component({
    selector   : 'home_ppal',
    templateUrl: './home.component.html',
    styleUrls  : ['./home.component.scss']
})
export class HomeComponent implements OnInit
{
    sub;
    id:number

    rol: string;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _cordovaService: CordovaService
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
    }

    ngOnInit() {
        this.rol = window.localStorage.getItem('rol');
        this.sub = this._route
            .queryParams
            .subscribe(params => {
                // Defaults to 0 if no query param provided.
                this.id = +params['id'] || 0;
                // alert(this.id )
            });

    }
}
