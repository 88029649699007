<mat-accordion class="width: 100%">
    <mat-expansion-panel *ngFor="let domain of domains" class="width: 100%">
        <mat-expansion-panel-header class="domain-header" [ngClass]="{ 'subdomain-header': isSubdomain }">
            <mat-panel-title>
                <h3><b>{{ domain.name }}</b></h3>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row" *ngIf="domain.competences" style="margin-top: 8px">
            <div class="col-xs-12">
                <ng-template matExpansionPanelContent>
                    <app-port-progress-compets [competences]="domain.competences" [anyo]="anyo"></app-port-progress-compets>
                </ng-template>
            </div>
        </div>
        <ng-container *ngIf="domain.subdomains">
            <app-port-progress-domains [domains]="domain.subdomains" [anyo]="anyo" [isSubdomain]="true"></app-port-progress-domains>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>