import { CertificateRequestComponent } from './../certificate-request/certificate-request.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit, Input } from '@angular/core';
import { RegNotebookItem } from '../reg-notebook.model';

declare var device;

@Component({
  selector: 'app-notebook-items',
  templateUrl: './notebook-items.component.html',
  styleUrls: ['./notebook-items.component.scss']
})
export class NotebookItemsComponent implements OnInit {

    @Input() items: any[] = [];
    @Input() year;
    @Input() idTipoActo: number; 
    constructor(
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        // console.log('items', this.items);
        // console.log(typeof this.year);
        // console.log(this.items);
        // console.log(this.idTipoActo);
        /*if(this.items[0].type){
            console.log(this.items);
        }*/
    }

    openRequest(data: object): void {
        if (!this.isMobileDevice()) {
            // Si no es un dispositivo móvil, usa el alerta con normalidad.
            const dialogRef = this.dialog.open(CertificateRequestComponent, {
                hasBackdrop: false,
                width: '80vw',
                data: data,
            });
        } else {
            // Si es un dispositivo móvil, tenemos que controlar dos casos:
            // 1.   El usuario cierra la alerta mediante los botones de la alerta.
            // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
            // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
            // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

            const onMobileBackButton = () => {
                dialogRef.close();
                window.removeEventListener('backbutton', onMobileBackButton, false);
            };

            document.addEventListener('backbutton', onMobileBackButton, false);
            const dialogRef = this.dialog.open(CertificateRequestComponent, {
                hasBackdrop: false,
                width: '80vw',
                data: data,
            });
            dialogRef.afterOpened().subscribe(() => {
                successResult => {window.removeEventListener('backbutton', onMobileBackButton, false);};
                errorResult => window.removeEventListener('backbutton', onMobileBackButton, false);
            });
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }

    getRol(): string {
        return localStorage.getItem('rol');
    }

    hardcodeLogic(item) {
        if(item.idType == 501 || 
            (item.idType == 1 && item.type !== 'Cursos Realizados' && item.type !== 'ECLOE') 
            || item.idType == 2 && item.type !== 'Congresos y reuniones' && item.type !== 'EPOE') {
            return true;
        } else {
            return false;
        }
    }

}
