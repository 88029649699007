export const locale = {
    lang: 'en',
    data: {
        'footer': {
            'calendar': 'Calendar',
            'recipes': 'Recipes',
            'webminars': 'Webinars',
            'programme': 'Programme',
            'galery': 'Gallery',
            'contact': 'Messages'

        }
    }
};
