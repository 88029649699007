import { IDbService } from './../../services/idb.service';
import { NetworkService } from './../../services/network.service';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { EvDocDomain } from './models/ev-doc-domain.model';
import { Observable, of, from } from 'rxjs';
import { EvDocProtocol } from './models/ev-doc-protocol.model';
import { EvDocCompetence } from './models/ev-doc-competence.model';

@Injectable({
    providedIn: 'root'
})
export class EvDocService {

    private entityUrl = `${environment.backendUrl}/documentos`;

    constructor (
        private http: HttpClient,
        private network: NetworkService,
        private dbService: IDbService
    ) { }
    
    getAllEvDocs(filter): Observable<EvDocDomain[]> {

        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<EvDocDomain[]>(`${this.entityUrl}/getAllDocumentos?filter=${filter}`);
            } else {
                return from(this.dbService.get('docsTodos')).switchMap(docs => docs ? of(docs) : of([]));
            }
        });
    }

    getByCases(filter): Observable<EvDocDomain[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this.http.get<EvDocDomain[]>(`${this.entityUrl}?filter=${filter}`);
            } else {
                return from(this.dbService.get('docsCasos')).switchMap(docs => docs ? of(docs) : of([]));
            }
        });

    }

    getByProcedures(filter): Observable<EvDocDomain[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<EvDocDomain[]>(`${this.entityUrl}/getProcedimientos?filter=${filter}`);
            } else {
                return from(this.dbService.get('docsProcedimientos')).switchMap(docs => docs ? of(docs) : of([]));
            }
        });
    }

    getBy360(filter): Observable<EvDocDomain[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<EvDocDomain[]>(`${this.entityUrl}/get360?filter=${filter}`);
            } else {
                return from(this.dbService.get('docs360')).switchMap(docs => docs ? of(docs) : of([]));
            }
        });
        

    }

    getSpecialCases(filter): Observable<EvDocCompetence[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<EvDocCompetence[]>(`${this.entityUrl}/getCasosEspeciales?filter=${filter}`);
            } else {
                return from(this.dbService.get('docsCasosEspeciales')).switchMap(docs => docs ? of(docs) : of([]));
            }
        });
        

    }

    getSimulations(filter): Observable<EvDocCompetence[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<EvDocCompetence[]>(`${this.entityUrl}/getSimulacion?filter=${filter}`);
            } else {
                return from(this.dbService.get('docsSimulacion')).switchMap(docs => docs ? of(docs) : of([]));
            }
        });
        

    }

    /** Ontiene un protocolo de actuación dado el idMethodEval. */
    getActuationProtocol(idMethodEval: string): Observable<EvDocProtocol> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<EvDocProtocol>(`${this.entityUrl}/getProtocolo/${idMethodEval}`);
            } else {
                return from(this.dbService.get('protocols')).switchMap(protocols => {
                    return protocols && protocols[idMethodEval] ? of(protocols[idMethodEval]) : of(null);
                });
            }        
        });
    }
}
