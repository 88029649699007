import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterNotebookPageComponent } from './register-notebook-page/register-notebook-page.component';
import { MatCardModule, MatButtonModule, MatIconModule, MatExpansionModule, MatMenuModule, 
    MatFormFieldModule, MatInputModule, MatDatepickerModule, MatStepperModule, MatSelectModule } from '@angular/material';
import { NotebookDomainsComponent } from './notebook-domains/notebook-domains.component';
import { NotebookItemsComponent } from './notebook-items/notebook-items.component';
import { RouterModule } from '@angular/router';
import { CertificateRequestComponent } from './certificate-request/certificate-request.component';

const routes = [ {
    path: 'cuaderno-registro',
    component: RegisterNotebookPageComponent
} ];

@NgModule({
    declarations: [
        RegisterNotebookPageComponent,
        NotebookDomainsComponent,
        NotebookItemsComponent,
        CertificateRequestComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatStepperModule,
        MatSelectModule
    ],
    entryComponents: [
        CertificateRequestComponent
    ]
})
export class RegisterNotebookModule { }
