import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { UserService } from 'app/services/users.service';
import { SpinnerService } from 'app/services/spinner.service';
import { ProgressService } from './../../../../services/progress.service';
import { Component, OnInit, Input } from '@angular/core';
import { PortProgressDomain } from '../models/port-progress-domain.model';

@Component({
  selector: 'app-port-progress',
  templateUrl: './port-progress.component.html',
  styleUrls: ['./port-progress.component.scss']
})
export class PortProgressComponent implements OnInit {

    residentId: string;

    domains: PortProgressDomain[] = [];
    @Input() anyo?;
    constructor (
        private portProgressService: ProgressService,
        private spinner: SpinnerService,
        private user: UserService,
        private evalService: EvaluationService
    ) { }

    ngOnInit(): void {
        this.spinner.show();
        if (this.user.getRol() === '2') {
            this.portProgressService.getAllPortfolio().subscribe(domains => {
                this.domains = domains;
                this.spinner.hide();
            }, error => this.spinner.hide()
            );
        } else if (this.user.getRol() === '3') {
            this.evalService.residenteConsulta.subscribe(resident => {
                this.portProgressService.getAllPortfolio(resident.id).subscribe(domains => {
                    this.domains = domains;
                   this.spinner.hide();
                }, error => this.spinner.hide()
                );
            }, error => this.spinner.hide()
            ).unsubscribe();
        }
    }

}
