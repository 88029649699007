import {MatPaginatorIntl} from '@angular/material';
import { Injectable } from '@angular/core';

/**
 * Translator for the Angular Material Paginator. Must be set as a provider in modules.
 * https://stackoverflow.com/questions/46869616/how-to-use-matpaginatorintl
*/
@Injectable({
  providedIn: 'root',
})
export class MatPaginatorIntlEsp extends MatPaginatorIntl {
  itemsPerPageLabel = 'Elementos por página: ';
  nextPageLabel     = 'Página siguiente';
  previousPageLabel = 'Pñagina anterior';

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '1 de ' + '1';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  };

}
