export const locale = {
    lang: 'es',
    data: {
        'NAV': {
            'MENU': 'Menú',
			'RECIPES': {
                'TITLE': 'RECETAS'
            },
            'PROGRAMME': {
                'TITLE': 'PROGRAMA'
            },
            'WEBMINARS': {
                'TITLE': 'WEBMINARS'
            },
            'CONTACT': {
                'TITLE': 'CONTACTO'
            },
            'PROFILE': {
                'TITLE': 'PERFIL'
            },
            'GALERY': {
                'TITLE': 'GALERÍA'
            },
            'LOGOUT': {
                'TITLE': 'CERRAR SESIÓN'
            }
        }
    }
};
