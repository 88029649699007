import { StepperResidentService } from './../../../services/stepper-resident.service';
import { UserService } from 'app/services/users.service';
import { StepperService } from './../../../services/stepper.service';
import { Router } from '@angular/router';
import { EvDocCompetence } from './../models/ev-doc-competence.model';
import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output, AfterViewChecked } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EvaluationProtocolModalComponent } from '../evaluation-protocol-modal/evaluation-protocol-modal.component';
import { EvDocViews } from '../ev-doc-views.enum';

@Component({
  selector: 'app-ev-doc-competences',
  templateUrl: './ev-doc-competences.component.html',
  styleUrls: ['./ev-doc-competences.component.scss']
})
export class EvDocCompetencesComponent implements OnInit, AfterViewInit {

    @Input() competences: EvDocCompetence[] = [];
    @Input() idTipoMetodoEval?: number;
    @Output() hideIt = new EventEmitter<any>();
    constructor (
        private dialog: MatDialog,
        private router: Router,
        private stepperTutorService: StepperService,
        private stepperResidentService: StepperResidentService,
        private userService: UserService
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.hideIt.next();
    }

    /** Si los valores son '1.1, 2.2', devuelve [ '1.1', '2.2' ] */
    getSplittedValues = (competence: EvDocCompetence) => competence.values ? competence.values.split(',') : [];
    canSeeProtocol(competence){
       return competence["idMetodoEval"] != undefined ? true : false;
    }
    onOpenActProtocol(competence: EvDocCompetence): void {
        const input = { idMethodEval: competence.idMetodoEval };
        this.dialog.open(EvaluationProtocolModalComponent, { data: input, width: '95%' });
    }

    onEvaluate(competence: EvDocCompetence): void {
        //console.log(competence);
        if (this.getRol() === '3') {
            this.stepperTutorService.idTipoMetodoEval = String(this.idTipoMetodoEval);
            this.stepperTutorService.idMetodoEval = competence.idMetodoEval;
            this.stepperTutorService.docEvalFlag = true;
            this.router.navigate(['stepper']);
        } else if (this.getRol() === '2') {
            this.stepperResidentService.idTipoMetodoEval = String(this.idTipoMetodoEval);
            this.stepperResidentService.idMetodoEval = competence.idMetodoEval;
            this.stepperResidentService.docEvalFlag = true;
            this.router.navigate(['stepper-resident']);
        }
    }

    getRol() {
        return this.userService.getRol();
    }

}
