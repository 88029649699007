import { SyncService } from './../../../services/sync.service';
import { SpinnerService } from 'app/services/spinner.service';
import { Component, OnInit } from '@angular/core';
import { RegNotebookService } from '../reg-notebook-tutor.service';
import { RegNotebookDomain } from '../reg-notebook-tutor-domain.model';

@Component({
  selector: 'app-register-notebook-page',
  templateUrl: './register-notebook-page.component.html',
  styleUrls: ['./register-notebook-page.component.scss']
})
export class RegisterNotebookPageComponent implements OnInit {

    users: any[] = [];

    constructor (
        private regNotebookService: RegNotebookService,
        private spinner: SpinnerService,
        private syncService: SyncService
    ) { }

    ngOnInit(): void {

        this.spinner.show();
        this.regNotebookService.getDataTutor().subscribe(users => {
            this.users = users;
            this.spinner.hide();
        }, () => this.spinner.hide());
    }

}
