import { environment } from './../../environments/environment';
import { NetworkService } from 'app/services/network.service';
import { ConfigService } from '../config.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs-compat';
import { of, from } from 'rxjs';
import { Profile } from '../models/profile';
import { Contacts } from '../models/contacts.model';
import { Links } from '../models/links.model';
import { Events } from '../models/events.model';
import { IDbService } from './idb.service';
 
@Injectable({
  providedIn: 'root'
})
export class ProfileService {


  token:string='';
  language:string='en';
  public url: string; // = 'http:...';
  public urlToken = '?access_token=';
  public urlLang = '&language=';
  public urlClass='/profile';
  public urlClassGetNotifConfig='/user/getNotificationConfig';
  public urlClassGetLangConfig = '/user/getLanguage';

  public urlClassChgNotifConfig='/user/changeNotificationConfig';
  public urlClassChgLangConfig = '/user/changeLanguage';
  private entityUrl = `${environment.backendUrl}/usuarios`;
  constructor(
      public _http: HttpClient, 
      private configService: ConfigService,
      private network: NetworkService,
      private dbService: IDbService
    ) {
    this.url = this.configService.getBaseUrl();
    if(localStorage){
      this.token = localStorage.getItem('token');
      this.language = localStorage.getItem('language');
    }
  }


  getNotificationConfig():Observable<any>{
    /*let profile: any = {
      trainTime:1,
      noActivity:1,
      unreadMessages:1,
      news:0
   };
  return Observable.of(profile);*/

    if(localStorage){
      this.token = localStorage.getItem('token');
      this.language = localStorage.getItem('language');
    }
    
    return this._http.get(this.url+this.urlClassGetNotifConfig+this.urlToken+this.token+this.urlLang+this.language);
  }

  changeNotificationConfig(params):Observable<any>{
    /*let profile: any = {
      trainTime:1,
      noActivity:0,
      unreadMessages:1,
      news:0
   };
  return Observable.of(profile);*/

    if(localStorage){
      this.token = localStorage.getItem('token');
      this.language = localStorage.getItem('language');
    }
    return this._http.post(this.url+this.urlClassChgNotifConfig+this.urlToken+this.token+this.urlLang+this.language, params);
  }  



  getLanguageConfig():Observable<any>{
    //let profile: string = 'es'
    //return Observable.of(profile);
  

    if(localStorage){
      this.token = localStorage.getItem('token');
      this.language = localStorage.getItem('language');
    }
    return this._http.get(this.url+this.urlClassGetLangConfig+this.urlToken+this.token);
  }

  changeLanguage(params):Observable<any>{
    //let profile: string = 'es'
    //return Observable.of(profile);


    if(localStorage){
      this.token = localStorage.getItem('token');
      this.language = localStorage.getItem('language');
    }
    return this._http.post(this.url+this.urlClassChgLangConfig+this.urlToken+this.token, params);
  }  

  getProfile():Observable<any>{
    let profile: Profile = {
        id:1,
        nombre:"Nombre Usr",
        apellidos:"Apellidos",
        notificame: true,
        language:"es",
        alias: "UsrDevelop",
        email: "email@pruebas.es",
        avatar:null,
        telefono: "000000"
    };

    return Observable.of(profile);
    if(localStorage){
      this.token = localStorage.getItem('token');
      this.language = localStorage.getItem('language');
    }
    //return this._http.get(this.url+this.urlClass+this.urlToken+this.token+this.urlLang+this.language);
  }

   /**
     * Update profile
     */

    updateProfile(profile): Observable<any>
    {
        
        let json = JSON.stringify(profile);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        if(localStorage){
          this.token = localStorage.getItem('token');
          this.language = localStorage.getItem('language');
        }
        return this._http.post(this.url+this.urlClass+'/'+profile.id+this.urlToken+this.token+this.urlLang+this.language, json, { headers: headers });
        
    }
    
    getContacts(): Observable<Contacts[]> {
        
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this._http.get<Contacts[]>(`${this.entityUrl}/getContactos`);
            } else {
              return from(this.dbService.get('contacts')).switchMap(contacts => {
                return contacts ? of(contacts) : of([]);
            });
            }
        });
    }

    getLinks(): Observable<Links[]> {
        
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this._http.get<Contacts[]>(`${this.entityUrl}/getAccesosDirectos`);
            } else {
              return from(this.dbService.get('directAccess')).switchMap(da => {
                return da ? of(da) : of([]);
            });
            }
        });
    }

    getEvents(): Observable<Events[]> {
        
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this._http.get<Contacts[]>(`${this.entityUrl}/getEventos`);
            } else {
              return from(this.dbService.get('events')).switchMap(ev=> {
                return ev ? of(ev) : of([]);
            });
            }
        });
    }

    getNotifications(): Observable<Events[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                return this._http.get<Contacts[]>(`${this.entityUrl}/getAvisos`);
            } else {
                return of([]);
            }
        });

    }

}
