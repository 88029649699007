import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
// Importarlo de aquí en vez de 'idb' ahorra un error.
// https://github.com/jakearchibald/idb/issues/95
import { openDB, IDBPDatabase, deleteDB } from 'idb'; /// with-async-ittr.js
import { Evaluacion } from 'app/models/misevaluaciones.model';

/** 
 * Encapsula el uso del plugin que se use de Indexed DB por si es necesario modificarlo en el futuro.
*/

export class DataToUpload {
    evaluationsToUpload: Evaluacion[];
}

@Injectable({
    providedIn: 'root'
})
export class IDbService {

    private db: IDBPDatabase<unknown> = null;
    private dbVersion = 1; // Este número va creciendo cada vez que se construye la BD.
    private storeName = 'portres';

    constructor() {
    }

    /** 
     * Construye la BD con todos los datos que hacen falta para el uso offline.
    */
    async buildDb(): Promise<any> {
        return new Promise(resolve => {
            if (this.db) {
                resolve(this.db);
            } else {
                openDB(environment.idbDatabaseName, this.dbVersion, {
                    upgrade: (db) => {
                        db.createObjectStore(this.storeName);
                    } 
                }).then(db => {
                    this.db = db;
                    resolve(this.db);
                });    
            }
        });
    }
     deleteDB(): Promise<any>{
      /*  return new Promise(resolve => {
            if(!this.db || this.db == undefined || this.db == null){
                console.log("No hay idb");
                resolve(true);
            }
            deleteDB(environment.idbDatabaseName, {
            blocked() {
                console.log("blocked")
                resolve(true);
            },
          }).then((response)=> {
              console.log("response");
              resolve(true);
          }).catch((error) => {
              console.log("error");
              resolve(true);
              console.log(error);
          });
        });*/
        return new Promise(resolve => {
            if(this.db){

                this.db.clear(this.storeName).then((res)=> {
                    resolve(true);
                });
            }
            else {
                resolve(true);
            }
        })
    }
        // Una vez construida la BD, guardamos los datos de esta en sus respectivas claves.
    async checkDB(): Promise<any>{
        await this.buildDb();
        const items = await this.db.transaction(this.storeName).objectStore(this.storeName).getAllKeys()

        return items.length == 0 ? false : true;
    }
    async populate(dataToUpload: DataToUpload, dataToDownload: any): Promise<any> {
        await this.buildDb();

        for (const key in dataToUpload) {
            if (dataToUpload.hasOwnProperty(key)) {
                this.set(key, dataToUpload[key]);
            }
        }
        for (const key in dataToDownload) {
            if (dataToDownload.hasOwnProperty(key)) {
                this.set(key, dataToDownload[key]);
            }
        }
    }

    /** 
     * Obtiene uno de los stores de objetos de la BD, si esta está abierta.
    */
    async get(key: string): Promise<any> {
        await this.buildDb();
        return this.db.get(this.storeName,key);
    }

    async set(key: string, value: any): Promise<any> {
        await this.buildDb();
        return this.db.put(this.storeName, value,key);
    }
}