import { CertificateRequestComponent } from './../../register-notebook/certificate-request/certificate-request.component';
import { CertificateInfoComponent } from './certificateInfo/certificateInfo.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { CertificacionesComponent, /*RotacionesViewDialog */} from './certificaciones.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';
import {MatBadgeModule} from '@angular/material/badge';

import { AuthService } from 'app/services/auth.service';
import { MatButtonModule, MatCardModule, MatDialogModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, 
    MatIconModule, MatProgressSpinnerModule, MatInputModule, MatPaginatorIntl, MatSelectModule } from '@angular/material';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { MatPaginatorIntlEsp } from 'app/MatPaginatorIntlEsp';

const routes = [
    {
        path     : 'miscertificaciones',
        component: CertificacionesComponent,
       // canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        CertificacionesComponent,
        CertificateInfoComponent,
        //RotacionesViewDialog
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatIconModule,
        SearchBarModule,
        MatButtonModule,
        NgxChartsModule,
        FuseSharedModule,
        FuseHighlightModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatSelectModule,
        MatCardModule
    ],
    exports     : [
        CertificacionesComponent
    ],
    entryComponents: [
        //RotacionesViewDialog
        CertificateInfoComponent,
        CertificateRequestComponent
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    ],
})

export class CertificacionesModule
{
}