import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of, from } from 'rxjs';
import { Resident } from 'app/models/resident.model';
import { HttpClient } from '@angular/common/http';
import { NetworkService } from './network.service';
import { IDbService } from './idb.service';

@Injectable({
    providedIn: 'root'
})
export class ResidentsService {
    
    private entityUrl = `${environment.backendUrl}/residentes`;
    private entityUrl2 = `${environment.backendUrl}/usuarios`;
    constructor (
        private http: HttpClient,
        private network: NetworkService,
        private dbService: IDbService
    ) {}
    
    getResidentInfo(idResidente = 0){
        return this.http.get<any>(`${this.entityUrl2}/getResidentInfo?idResidente=${idResidente}`);
    }
    getAllActive(): Observable<Resident[]> {

        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<Resident[]>(`${this.entityUrl}`);
            } else {
                return from(this.dbService.get('residents')).switchMap(residentesActivos => {
                    return residentesActivos ? of(residentesActivos) : of([]);
                });
            }
        });

        /*if (this.network.isConnected()) {
            return this.http.get<Resident[]>(`${this.entityUrl}`);
        } else {
            return from(this.dbService.get('residents')).switchMap(residentesActivos => {
                return residentesActivos ? of(residentesActivos) : of([]);
            });
        }*/
    }

    getAllResidents(): Observable<Resident[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<Resident[]>(`${this.entityUrl}/getAllResidents`);
            } else {
                return from(this.dbService.get('allResidents')).switchMap(residentes => {
                    return residentes ? of(residentes) : of([]);
                });
            }
        });        
        
        /*if (this.network.isConnected()) {
            return this.http.get<Resident[]>(`${this.entityUrl}/getAllResidents`);
        } else {
            return from(this.dbService.get('allResidents')).switchMap(residentes => {
                return residentes ? of(residentes) : of([]);
            });
        }*/
    }

    getInfoResident(): Observable<Resident> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {            
                return this.http.get<Resident[]>(`${this.entityUrl}/getInfoResidente`);
            } else {
                return from(this.dbService.get('infoResidente')).switchMap(residentes => {
                    return residentes ? of(residentes) : of([]);
                });
            }
        }); 
    }

    getStrengths(residentId?: number): Observable<any[]> {
        return this.network.isConnected().switchMap(connected => {
            if (connected) {
                if (residentId) {
                    return this.http.get<any[]>(`${this.entityUrl}/getFortalezasDebilidades?idResidente=${residentId}`);
                } else {
                    return this.http.get<any[]>(`${this.entityUrl}/getFortalezasDebilidades`);
                }
            } else {
                    return of([]);
                }
        });
    }    

}
