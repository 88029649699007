import { Component, OnInit, Input } from '@angular/core';
import { PortProgressDomain } from '../models/port-progress-domain.model';

@Component({
  selector: 'app-port-progress-domains',
  templateUrl: './port-progress-domains.component.html',
  styleUrls: ['./port-progress-domains.component.scss']
})
export class PortProgressDomainsComponent implements OnInit {

    @Input() domains: PortProgressDomain[] = [];
    @Input() anyo?;
    @Input() isSubdomain?: boolean;
    constructor() { }

    ngOnInit(): void {
    }

}
