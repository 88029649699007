<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
    <ng-container [matColumnDef]="column.prop" *ngFor="let column of columns">
        <th mat-header-cell *matHeaderCellDef style="background-color:#f0ad4e; color: white" [ngClass]="{'center': column.prop === '[1][objetivo]' || column.prop === '[1][conseguido]' || column.prop === '[2][objetivo]' || 
        column.prop === '[2][conseguido]' || column.prop === '[3][objetivo]' || column.prop === '[3][conseguido]'}">{{ column.title }}</th>
       <td mat-cell *matCellDef="let row" 
        [ngClass]="{'colorYear': compareLogicPhaseI(column),
                    'colorYear': compareLogicPhaseII(column),
                    'colorYear': compareLogicPhaseIII(column),
                    'center': seeIfPhase(column),
                    'anchoCompetencia': column.prop === 'nombre',
                    'noPaddRight': column.prop === '[3][conseguido]',
                    'lessPaddLeft': column.prop === 'numero'
                }">
            <ng-container style="width: 20px; height:20px; background-color:aqua; border-radius: 25%;">
                <div style="display: flex;" [ngClass]="{centerMe: column.prop !== 'nombre'}">
                    <div [ngClass]="{
                        'backgroundCompetenceGood': compareGood(column, row),
                        'backgroundCompetenceFail': compareFail(column, row)
                        }">
                        {{ render(row, column) }}
                    </div>
                </div>
            </ng-container>
        </td>
    </ng-container>

    <!--<ng-container matColumnDef="opciones">
        <th mat-header-cell *matHeaderCellDef>Opciones</th>
        <td mat-cell *matCellDef="let row">
            <button mat-raised-button color="primary" (click)="onView(row)">
                <mat-icon>remove_red_eye</mat-icon>
                Ver
            </button>
        </td>
    </ng-container>-->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>