<div class="page-layout blank p-10" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">
    <ng-container>
            <div style="display:flex; padding-bottom:5px; justify-content: space-between">
                <button style="margin-right:5px; background-color:#428BCA; color:white;" mat-raised-button (click)="goBack()">
                    Anterior
                </button>
                <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="onSubmit()">
                    Siguiente
                </button>
            </div>
        <div style="background-color:#FAEBD7">
            NIVEL DE COMPETENCIA EN PRACTICA INTEGRADA
        </div>
        <div style="margin-bottom: 14px;">
            <div style="display: flex;
                align-items: center;
                margin-bottom: 3px;">
                <span style="min-width: 75%; max-width: 75%">
                    <mat-icon style="background-color:#428BCA;color:white; margin-right:2px"
                    *ngIf="calculateLevel(nivelMedio) === 1 || calculateLevel(nivelMedio) === 0">arrow_forward</mat-icon>
                    <b>Nivel 1 (1-2 puntos).</b> Necesita supervisión completa y aumentar conocimientos.
                </span>
                <div *ngIf="calculateLevel(nivelMedio) === 1 || calculateLevel(nivelMedio) === 0" 
                    style="width: auto; background-color:#428BCA; color: white; padding-right: 5px; padding-left: 5px">
                    Puntos: {{showFixed(nivelMedio, 2)}}  Nivel Previo: {{nivelPrevioGlobal}}
                </div>
            </div>
            <div style="display: flex;
                align-items: center;
                margin-bottom: 3px;">
                <span style="min-width: 75%; max-width: 75%">
                    <mat-icon style="background-color:#428BCA;color:white; margin-right:2px"
                    *ngIf="calculateLevel(nivelMedio) === 2">arrow_forward</mat-icon>
                    <b>Nivel 2 (3-4 puntos).</b> Necesita ayuda de un profesional más experto para la realización de la actividad.
                </span>
                <div *ngIf="calculateLevel(nivelMedio) === 2"
                    style="width: auto; background-color:#428BCA; color: white; padding-right: 5px; padding-left: 5px">
                    Puntos: {{showFixed(nivelMedio, 2)}}  Nivel Previo: {{nivelPrevioGlobal}}
                </div>
            </div>
            <div style="display: flex;
                align-items: center;
                margin-bottom: 3px;">
                <span style="min-width: 75%; max-width: 75%">
                    <mat-icon style="background-color:#428BCA;color:white; margin-right:2px"
                    *ngIf="calculateLevel(nivelMedio) === 3">arrow_forward</mat-icon>
                    <b>Nivel 3 (4-5 puntos).</b> Puede realizar la actividad de forma autónoma con ayuda ocasional en casos complejos.
                </span>
                <div *ngIf="calculateLevel(nivelMedio) === 3" 
                    style="width: auto; background-color:#428BCA; color: white; padding-right: 5px; padding-left: 5px">
                    Puntos: {{showFixed(nivelMedio, 2)}}  Nivel Previo: {{nivelPrevioGlobal}}
                </div>
            </div>
            <div style="display: flex;
                align-items: center;
                margin-bottom: 3px;">
                <span style="min-width: 75%; max-width: 75%">
                    <mat-icon style="background-color:#428BCA;color:white; margin-right:2px"
                    *ngIf="calculateLevel(nivelMedio) === 4">arrow_forward</mat-icon>
                    <b>Nivel 4 (5-6 puntos).</b> Está capacitado para la realización autónoma de la actividad. Es especialista.
                </span>
                <div *ngIf="calculateLevel(nivelMedio) === 4"
                    style="width: auto; background-color:#428BCA; color: white; padding-right: 5px; padding-left: 5px">
                    Puntos: {{showFixed(nivelMedio, 2)}}  Nivel Previo: {{nivelPrevioGlobal}}
                </div>
            </div>
        </div>
        <div style="background-color:#FAEBD7; margin-bottom:7px">
            NIVEL ADQUIRIDO POR COMPETENCIA
        </div>
        <div style="display: flex;" *ngIf="dataSource !== undefined">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width:99.9%; box-shadow: none !important">
                <!-- Position Column -->
                <ng-container matColumnDef="competencia">
                    <th mat-header-cell *matHeaderCellDef 
                    style="padding: 0 0 0 0 !important; font-size:14px; font-weight:800;
                    border-bottom-style: none !important; max-width: 60vw;"> Competencia</th>
                    <td mat-cell *matCellDef="let element" 
                    style="border-bottom-style: none !important; max-width: 60vw;"> {{element.competencia}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="puntuacion">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center; padding: 0 0 0 0 !important; font-size:14px; font-weight:800;
                    border-bottom-style: none !important;"> Puntuación </th>
                    <td mat-cell *matCellDef="let element" style="text-align: center; border-bottom-style: none !important;"> {{showFixed(element.puntuacion, 2)}} </td>
                </ng-container>
                
                <!-- Weight Column -->
                <ng-container matColumnDef="nivelPrevio">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center; padding: 0 0 0 0 !important; font-size:14px; font-weight:800;
                    border-bottom-style: none !important;"> Nivel Previo </th>
                    <td mat-cell *matCellDef="let element" style="text-align: center; border-bottom-style: none !important;"> {{element.nivelPrevio}} </td>
                </ng-container>
                
                <!-- Symbol Column -->
                <ng-container matColumnDef="nivelAdquirido">
                    <th mat-header-cell *matHeaderCellDef style="text-align: center; padding: 0 0 0 0 !important; font-size:14px; font-weight:800;
                    border-bottom-style: none !important;"> Nivel Adquirido </th>
                    <td mat-cell *matCellDef="let element" style="text-align: center; border-bottom-style: none !important;"> {{element.nivelAdquirido}} </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background-color:#FAEBD7;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="border-bottom-style: none"></tr>
            </table>
        </div>
        <div style="display:flex; padding-bottom:5px; justify-content: space-between">
            <button style="margin-right:5px; background-color:#428BCA; color:white;" mat-raised-button (click)="goBack()">
                Anterior
            </button>
            <button mat-raised-button style="background-color:#428BCA; color:white;" (click)="onSubmit()">
                Siguiente
            </button>
        </div>
    </ng-container>
</div>
