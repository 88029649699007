import { CertificateRequestComponent } from './../../../register-notebook/certificate-request/certificate-request.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Component, OnInit, OnDestroy, Inject} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';


export interface NotificationConfig {
    trainTime:boolean,
    noActivity:boolean,
    unreadMessages:boolean,
    news:boolean
  }
  
  export interface LanguageConfig {
    language: string
  }
  
  

declare let window: any;
declare let device: any;

@Component({
    selector   : 'taskInfo',
    templateUrl: './taskInfo.component.html',
    styleUrls  : ['./taskInfo.component.scss'],
    animations   : fuseAnimations
})

export class TaskInfoComponent implements OnInit { 

    constructor (
        public dialogRef: MatDialogRef<TaskInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog
    ) {}

    ngOnInit(): void {
    }

    close(): void {
        this.dialogRef.close();
    }

    translate(string: string): string {
        const number = Number(string);
        const base = 'Se ha solicitado una nueva ';
        switch (number) {
            case 1:
                return base + 'certificación de actividad.';
            case 2:
                return base + 'certificación.';
            case 4: 
                return base + 'certificación de evaluación.';
        }
    }

    openRequest(data: object): void {
        this.dialogRef.close();
        if (!this.isMobileDevice()) {
            // Si no es un dispositivo móvil, usa el alerta con normalidad.
            const dialogRef = this.dialog.open(CertificateRequestComponent, {
                hasBackdrop: false,
                width: '80vw',
                data: data,
            });
        } else {
            // Si es un dispositivo móvil, tenemos que controlar dos casos:
            // 1.   El usuario cierra la alerta mediante los botones de la alerta.
            // 2.   El usuario le da al botón de retroceder del dispositivo (y la alerta debe cerrarse).
            // Para el caso 2, escuchamos temporalmente el evento backbutton y, si se ejecuta, cerramos las alertas.
            // Si la alert se cierra de forma normal, nos aseguramos de dejar de escuchar dicho evento.

            const onMobileBackButton = () => {
                dialogRef.close();
                window.removeEventListener('backbutton', onMobileBackButton, false);
            };

            document.addEventListener('backbutton', onMobileBackButton, false);
            const dialogRef = this.dialog.open(CertificateRequestComponent, {
                hasBackdrop: false,
                width: '80vw',
                data: data,
            });
            dialogRef.afterOpened().subscribe(() => {
                successResult => {window.removeEventListener('backbutton', onMobileBackButton, false);};
                errorResult => window.removeEventListener('backbutton', onMobileBackButton, false);
            });
        }
    }

    onMobileBackButton = () => {
        if (this.dialogRef){
            let dialogRef = this.dialog.open(CertificateRequestComponent);
            dialogRef.close();
            this.dialogRef.close();
        }
    }

    private isMobileDevice = () => {
        try {
            return device && device.platform;
        } catch (e) {
            return false;
        }
    }


}