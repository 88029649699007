import { AlertService } from 'app/services/alert.service';
import { SpinnerService } from 'app/services/spinner.service';
import { EvaluationService } from './../evaluation.service';
import { StepperService } from './../../../../services/stepper.service';
import { UtilsService } from './../../../../services/utils.service';
import { UserService } from 'app/services/users.service';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Question } from 'app/models/create-evaluation.model';
import { FileUploader } from 'ng2-file-upload';
import { EvaluationTypeIds } from 'app/models/evaluation-type-ids.enum';
import { MatTableDataSource } from '@angular/material';

export interface NotificationConfig {
    trainTime: boolean;
    noActivity: boolean;
    unreadMessages: boolean;
    news: boolean;
}
  
export interface LanguageConfig {
    language: string;
}
  
export interface Residentes {
    id: string;
    nombre_completo: string;
    nombre: string;
    apellidos: string;
    centro: string;
    localidad: string;
    provincia: string;
    especialidad: string;
    servicio: string;
    rotandoPor: string;
    anoResidencia: string;
    nombre_completo_tutor: string;
    activo: string;
    imagen: string;
}

export class EvaluationFile {
    base64?: string;
    name?: string;
    sizeKb?: number;
}
  
declare let navigator: any;
declare let Camera: any;
declare let window: any;
declare let LocalFileSystem: any;
declare let cordova: any;

@Component({
    selector   : 'stepper-one',
    templateUrl: './stepper-one.component.html',
    styleUrls  : ['./stepper-one.component.scss'],
    animations   : fuseAnimations
})
export class StepperOneComponent implements OnInit
{   
    @Output() complete = new EventEmitter<void>();
    @Output() goback = new EventEmitter<void>();
    @Output() changeSelectedTipoMetodoEval = new EventEmitter();
    @Input() selectedTipoMetodoEval;
    isLinear = true;
    form = new FormGroup({});
    keys: any[];
    idTipoMetodoEval: number;
    ayuda = '';
    cobatrice = '';
    disableRadioButtons = false;
    stepper: any;
    evFile;
    // Subida de fichero (para el caso especial de simulación / curso / taller).
    uploader = new FileUploader({});
    selectedFileData = null;
    uploadData: EvaluationFile = {};
    maxFileSizeMb = 15;
    displayedColumns = [ 'name', 'size', 'options' ];
    dataSource = new MatTableDataSource<EvaluationFile>();
    
    loading = false;

    constructor (
        public _domSanitizer: DomSanitizer,
        public userService: UserService,
        private _formBuilder: FormBuilder,
        private utils: UtilsService,
        private stepperService: StepperService,
        private evaluationService: EvaluationService,
        private spinner: SpinnerService,
        private alert: AlertService    
    )
    { }

    ngOnInit(): void {
        this.keys = [];
        // En el caso de tipo de evaluación especial, solo se sube un fichero.
        // console.log(this.stepperService.idTipoMetodoEval);
        if (!this.isSimulationCase()) {
            this.spinner.show();
            this.form.valueChanges.subscribe(formValues => this.stepperService.form.setValue(formValues));

            const stepperInfo = this.stepperService.stepperInfoGetObject();
            this.evaluationService.getQuestions(Number(stepperInfo.idMetodoEval)).subscribe(preguntas => {
                this.form = this.buildForm(preguntas);
                this.stepperService.form = this.form;
                this.spinner.hide();
            }, error => this.spinner.hide());
        } else {
            // Si hay algún documento subido, lo asignamos.

            const stepperData = this.stepperService.stepperInfoGetObject();
            if (stepperData['checking'] != undefined) {
                this.disableRadioButtons = true;
                this.evFile = stepperData["evaluationFile"];
                this.evFile = this._domSanitizer.bypassSecurityTrustResourceUrl(this.evFile);
                this.evFile  = this.evFile["changingThisBreaksApplicationSecurity"]


            
            }
            if (stepperData && stepperData['evaluationFile']) {
                this.uploadData = stepperData['evaluationFile'];
                this.dataSource.data = [ this.uploadData ];
            }
        }
        this.stepper = this.stepperService.getStepper();
    }

    isSimulationCase = () => Number(this.stepperService.idTipoMetodoEval) === EvaluationTypeIds.SIMULATION;
    is360            = () => Number(this.stepperService.idTipoMetodoEval) === EvaluationTypeIds.v360;
    openFile(){
        let url  = this.evFile
        //var win = window.open();
        //window.open = cordova.InAppBrowser.open;
        
        cordova.InAppBrowser.open(url, '_blank', 'location=yes');
        //console.log(win.document);
        //win.document.write('<iframe src="' + url  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
        //window.open(url, '_system', 'location=yes')
    }
    buildForm(preguntas: any[]): FormGroup {
        let formly = {};
        const values = this.stepperService.stepperInfoGetObject();
        if (values['checking'] != undefined) {
            this.disableRadioButtons = true;
            values['questionsCheck'].forEach((pregunta: any) => {
                const key = String(pregunta.idApartado);
                // asumimos que si tenemos las 'questions' dentro es que ya han sido añadidas y que deben ser todas respondidas antes de pasar sig.
                // Deshabilitar los campos -- TODO
                const object = {[key]: [pregunta.puntos !== undefined ? pregunta.puntos : null, [Validators.required]], disabled: true};
                this.keys.push({key: [key], text: pregunta.text});
                formly = {...formly, ...object};
            });

            return this._formBuilder.group(formly);

        } else {
            this.disableRadioButtons = false;
            preguntas.forEach((pregunta: any) => {
                const key = String(pregunta.idApartado);
                // asumimos que si tenemos las 'questions' dentro es que ya han sido añadidas y que deben ser todas respondidas antes de pasar sig.
    
                const object = {[key]: [values['questionsNoFormat']!= undefined ? values['questionsNoFormat'][key] : null, [Validators.required]]};
                this.keys.push({key: [key], text: pregunta.text});
                formly = {...formly, ...object};
            });

            return this._formBuilder.group(formly);
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            const answers = this.form.getRawValue();
            const questions: Question[] = [];
            const questionsNoFormat = [];
            for (const [key, value] of Object.entries(answers)) {
                const q = new Question();
                q.idApartado = key;
                q.puntuacion = value;
                questionsNoFormat[key] = value;
                questions.push(q);
              }
            const q2  = [];
            q2['questions'] = questions;
            q2['questionsNoFormat'] = questionsNoFormat;
            this.stepperService.stepperInfoRewrite(q2);
            this.complete.next();
        } else {
            this.utils.validateAllFormFields(this.form);
            this.alert.showError('Por favor, rellene todos los campos obligatorios.');
        }
    }

    goBack(): void {
        this.stepper.previous();
    }

    /**
     * Callback that fires when a file is added to the dropzone.
     */
    onFileDrop(files: File[]): void {
        const file = files[0];
        if (!this.hasValidSize(file)) {
            this.alert.showError(`El documento pesa ${this.getMb(file)} MB y el máximo es ${this.maxFileSizeMb} MB.`);
            this.uploader.queue = []; // Reject the file.
            return;
        }
        
        // Add the file to the model as base64.
        const reader = new FileReader();
        this.loading = true;
        reader.onload = (event) => {
            const base64File = (event.target as any).result;
            this.uploadData.base64 = base64File;
            this.uploadData.name = file.name;
            this.uploadData.sizeKb = this.getKb(file);
            const mimeType = this.utils.getMimeTypeFromBase64(base64File);

            if (mimeType !== 'application/pdf' && mimeType !== 'image/jpeg' && mimeType !== 'image/jpg' && mimeType !== 'image/png') {
                this.alert.showError('Solo puede subir documentos en formato PDF o imágenes.');
                this.uploader.queue = []; // Reject the file.
                return;
            }

            // Guardamos el fichero en el stepper service para que se envíe al guardar.
            this.stepperService.stepperInfoRewrite({ evaluationFile: this.uploadData });
            this.dataSource.data = [ this.uploadData ];
            this.loading = false;
        };
        reader.readAsDataURL(file); // Cuando termina, dispara el evento "onload" que se ha definido justo arriba.
    }

    fileIsSelected = () => {
        // this.uploader !== null && this.uploader.queue !== null && this.uploader.queue.length === 1;
        const stepperData = this.stepperService.stepperInfoGetObject();
        return stepperData && stepperData['evaluationFile'];
    }
    
    // tslint:disable-next-line: member-ordering
    consoleLog = console.log;

    emptyQueue = () => {
        this.uploader.queue = [];
        this.selectedFileData = null;
        this.stepperService.stepperInfoRewrite({ evaluationFile: null });
    }

    hasValidSize = (file: File): boolean => this.getMb(file) <= this.maxFileSizeMb;
  
    getMb = (file: File): number => +(file.size / 1024 / 1024).toFixed(2);

    getKb = (file: File): number => +(file.size / 1024).toFixed(2);

}
