import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegisterNotebookPageComponent } from './register-notebook-page/register-notebook-page.component';
import { MatCardModule, MatButtonModule, MatIconModule, MatExpansionModule, MatMenuModule } from '@angular/material';
import { NotebookDomainsComponent } from './notebook-domains/notebook-domains.component';
import { NotebookItemsComponent } from './notebook-items/notebook-items.component';
import { RouterModule } from '@angular/router';

const routes = [ {
    path: 'cuaderno-registro-tutor',
    component: RegisterNotebookPageComponent
} ];

@NgModule({
    declarations: [
        RegisterNotebookPageComponent,
        NotebookDomainsComponent,
        NotebookItemsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatMenuModule
    ]
})
export class RegisterNotebookTutorModule { }
