import { UserService } from 'app/services/users.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from 'app/api.service';
import { WebStorageService } from './web-storage.service';
import { LogAnswer } from 'app/main/pages/authentication/login/login.component';
import { IDbService } from './idb.service';
import { SyncService } from './sync.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements CanActivate {

    token: string;
    base_url: string;
    logIn_url: string;
    headers: HttpHeaders;

    constructor(
        private _router: Router,
        private http: HttpClient,
        private config: ApiService,
        private userService: UserService,
        private webStorageService: WebStorageService,
        private idbService: IDbService,
        public sync: SyncService,
    ) {
        if (localStorage) {
            this.token = localStorage.getItem('token');
        }

        this.headers = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        this.base_url = this.config.getBackEndUrl();
        this.logIn_url = '/login/';
    }

    public logIn(username, pass): Observable<LogAnswer> {
        const body = {
            login: username,
            password: pass
        };
        return new Observable<LogAnswer>(observer => {
            this.http
                .post<any>(
                    `${this.base_url}${this.logIn_url}authenticate`,
                    body,
                    { headers: this.headers }
                )
                .subscribe(
                    response => {
                        this.webStorageService.setAccessToken(response.token);
                        this.userService.getUserInfo().subscribe( info => {
                                localStorage.setItem('login', username);
                                localStorage.setItem('password', pass);
                                localStorage.setItem('userId', info.userId);
                                localStorage.setItem('nombrePropio', info.completeName);
                                localStorage.setItem('nombrePropio2', info.completeName2);
                                localStorage.setItem('puesto', info.job);
                                localStorage.setItem('imagenPerfil', info.image);
                                localStorage.setItem('provincia', info.province);
                                localStorage.setItem('localidad', info.locality);
                                localStorage.setItem('tipoPerfil', response.idUserType);
                            }
                        );
                        observer.next(response);
                        observer.complete();
                    },
                    error => {
                        observer.error(error);
                    }
                );
        });
    }


    public logIn2(username, pass): any {
        const body = {
            login: username,
            password: pass
        };
            this.http
                .post<any>(
                    `${this.base_url}${this.logIn_url}authenticate`,
                    body,
                    { headers: this.headers }
                )
                .subscribe(
                    response => {
                        this.webStorageService.setAccessToken(response.token);
                        this.userService.getUserInfo().subscribe( info => {
                                localStorage.setItem('login', username);
                                localStorage.setItem('password', pass);
                                localStorage.setItem('userId', info.userId);
                                localStorage.setItem('nombrePropio', info.completeName);
                                localStorage.setItem('nombrePropio2', info.completeName2);
                                localStorage.setItem('puesto', info.job);
                                localStorage.setItem('imagenPerfil', info.image);
                                localStorage.setItem('provincia', info.province);
                                localStorage.setItem('localidad', info.locality);
                                localStorage.setItem('tipoPerfil', response.idUserType);
                            }
                        );
                    },
                    error => {           
                    }
                );
        return true;
    }
    

    public isLoged() {
        if (
            typeof localStorage !== 'undefined' &&
            localStorage.getItem('accesstoken') !== null &&
            localStorage.getItem('accesstoken') !== 'null' &&
            localStorage.getItem('accesstoken') !== ''
        ) {
            return true;
        } else {
            return false;
        }
    }

    /*public isSeedEstablished() {
        let idwp = localStorage.getItem('id_wp');
        let languagewp = localStorage.getItem('language_wp');

        if (
            localStorage &&
            localStorage.getItem('id_wp') !== null &&
            localStorage.getItem('id_wp') !== 'null' &&
            localStorage.getItem('id_wp') !== '' &&
            localStorage.getItem('language_wp') !== null &&
            localStorage.getItem('language_wp') !== 'null' &&
            localStorage.getItem('language_wp') !== ''
        ) {
            return idwp + '_' + languagewp;
        } else {
            return null;
        }
    }*/

     logout() {
            if (localStorage) {
                //localStorage.setItem('language', null); //Ultimo lenguaje seleccionado en la app por el usuario
                localStorage.setItem('token', null); //token de la API
                localStorage.removeItem('password') ;
                localStorage.removeItem('login'); 
               // localStorage.removeItem('lastRefreshDateTime');
               // this.sync._lastRefreshDateTime$.next(null);
               // localStorage.setItem('token_wp', null); //token del worpress
    
                //localStorage.setItem('programme', null)  no se resetea
    
                //localStorage.setItem('id_wp', '0');
                //localStorage.setItem('language_wp', null); // lenguaje del worpress
    
               /* localStorage.setItem('subscription_id', null);
                localStorage.setItem('display_name', null);
                localStorage.setItem('lastdatecheck', null); // ultimo check del stado subscripcion
                localStorage.setItem('aceptadoimg', null);*/
    
                //localStorage.setItem('token_push', null); no se resetea y siempre que llegue nuevo token se envia este como anterior
            }
        //this._pushService.unregister();
    }

    public canActivate() {
        if (this.isLoged()) {
            return true;
        } else {
            this._router.navigate(['/login']);
            return false;
        }
    }

    public getCurrentUserName = () => localStorage.getItem('nombrePropio');
    public getCurrentUserName2 = () => localStorage.getItem('nombrePropio2');

    public getCurrentUserId = () => localStorage.getItem('userId');

}
