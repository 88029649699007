<div class="page-layout blank p-24" fusePerfectScrollbar [@animate]="{value:'*',params:{delay:'200ms',y:'100%'}}">

    <div class="user-info" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="center">
        <img class="profile-image avatar huge" [src]="_domSanitizer.bypassSecurityTrustUrl(selfieImage)">
    </div>

    <h1 class="text-center titulo-pag" *ngIf="username_wp && username_wp.length && username_wp!='null'">{{username_wp}}</h1>
   <!--   <p class="text-center sub-titulo-pag">Semana 1 entrenamiento</p> --> 


<!-- EDU - TRADUCCIONES -->
    <h3 class="grupo-menu">{{'cuenta.cuenta' | translate}}</h3>
    <mat-divider></mat-divider>

    <ul class="opciones-menu">
        <li routerLink="/profile">
            <mat-icon _ngcontent-c14="" aria-hidden="true"
                class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"><img src="assets/images/ico_app/miperfil.png" />
            </mat-icon>
            <span>{{'cuenta.perfil' | translate}}</span>
        </li>

       <!-- <li routerLink="/programmeconfig">
            <mat-icon _ngcontent-c14="" aria-hidden="true"
                class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"><img src="assets/images/ico_app/miprograma.png" />
            </mat-icon>
            <span>{{'cuenta.programa' | translate}}</span>
        </li> -->

        <li (click)="logout_clk()" >
            <mat-icon _ngcontent-c14="" aria-hidden="true"
                class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"><img src="assets/images/ico_app/cerrarsesion.png" />
            </mat-icon>
            <span>{{'cuenta.cerrarSesion' | translate}}</span>
        </li>
    </ul>


<!--Cambiado por José Carlos-->
<!--
    <h3 class="grupo-menu">Otro</h3>
    <mat-divider></mat-divider>
    <ul class="opciones-menu">
        <li routerLink="/pprivacidad">
            <mat-icon _ngcontent-c14="" aria-hidden="true"
                class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"><img src="assets/images/ico_app/privacidadterminos.png" />
            </mat-icon>
            <span>Política de privacidad</span>
        </li>

        <li routerLink="/termservicio">
            <mat-icon _ngcontent-c14="" aria-hidden="true"
                class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"><img src="assets/images/ico_app/privacidadterminos.png" />
            </mat-icon>
            <span>Términos del servicio</span>
        </li>
    </ul>
-->
<h3 class="grupo-menu">{{'otro.otro' | translate}}</h3>
<mat-divider></mat-divider>
<!-- <ul class="opciones-menu" *ngFor="let legalAdvice of legalAdvices">
    <li [routerLink]="['/termservicio', legalAdvice.idAvisoLegal]">
        <mat-icon _ngcontent-c14="" aria-hidden="true"
            class="mat-icon notranslate ng-tns-c79-84 material-icons mat-icon-no-color" role="img"><img src="assets/images/ico_app/privacidadterminos.png" />
        </mat-icon>
        <span>{{legalAdvice.titulo}}</span>
    </li>
</ul> -->


    <div class="copyright">
        <span class="copyright-a"> © 2019 Portres LTD. </span><!-- </span> <span class="copyright-b">   Website concept <span
                style="font-size: 20px; color: #ea9a9a;"> ♥ </span>&nbsp;by EntreDos</span>--> 
    </div>

</div>
