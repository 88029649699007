<!-- Representación para tablets -->
<div class="medium-display">
    <div class="row table-header">
        <div class="col-sm-2"><h4><b>Nº</b></h4></div>
        <div class="col-sm-4"><h4><b>Competencia</b></h4></div>
        <div class="col-sm-1"><h4><b>360º</b></h4></div>
        <div class="col-sm-1"><h4><b>Casos Especiales</b></h4></div>
        <div class="col-sm-1"><h4><b>ECLOE</b></h4></div>
        <div class="col-sm-1"><h4><b>EPOE</b></h4></div>
        <div class="col-sm-2"><h4><b>Simulación, cursos o talleres con evaluación</b></h4></div>
    </div>
    <div class="row competence-row" *ngFor="let competence of competences">
        <div class="col-sm-2"> {{ competence.values }} </div>
        <div class="col-sm-4"> {{ competence.name }} </div>
        <div class="col-sm-1">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIs360(competence)" (click)="onEvaluate('v360',competence)"> <!--[matMenuTriggerFor]="optsMenu360"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIs360(competence)"> <!--[matMenuTriggerFor]="optsMenu360"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>
            <!--<mat-menu #optsMenu360="matMenu">
                <button mat-menu-item (click)="onEvaluate('v360',competence)">Evaluar</button>
            </mat-menu>-->
        </div>
        <div class="col-sm-1">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsSpecial(competence)" (click)="onEvaluate('SPECIAL', competence)"> <!--[matMenuTriggerFor]="optsMenuSpecial"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsSpecial(competence)"> <!--[matMenuTriggerFor]="optsMenuSpecial"-->
                   <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuSpecial="matMenu">
                <button mat-menu-item (click)="onEvaluate('SPECIAL', competence)">Evaluar</button>
            </mat-menu>-->
        </div>
        <div class="col-sm-1">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsEcloe(competence)" (click)="onEvaluate('ECLOE',competence)"> <!--[matMenuTriggerFor]="optsMenuECLOE"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsEcloe(competence)"> <!--[matMenuTriggerFor]="optsMenuECLOE"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuECLOE="matMenu">
                <button mat-menu-item (click)="onEvaluate('ECLOE',competence)">Evaluar</button>
            </mat-menu>-->
        </div>
        <div class="col-sm-1">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsEpoe(competence)" (click)="onEvaluate('EPOE', competence)"> <!--[matMenuTriggerFor]="optsMenuEPOE"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsEpoe(competence)"> <!--[matMenuTriggerFor]="optsMenuEPOE"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuEPOE="matMenu">
                <button mat-menu-item (click)="onEvaluate('EPOE', competence)">Evaluar</button>
            </mat-menu>-->
        </div>
        <div class="col-sm-2">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsSimulation(competence)" (click)="onEvaluate('SIMULATION', competence)"> <!--[matMenuTriggerFor]="optsMenuSimulation"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsSimulation(competence)"> <!--[matMenuTriggerFor]="optsMenuSimulation"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuSimulation="matMenu">
                <button mat-menu-item (click)="onEvaluate('SIMULATION', competence)">Evaluar</button>
            </mat-menu>-->
        </div>
    </div>
</div>

<!-- Representación para dispositivos pequeños -->
<div class="small-display">
    <div class="row competence-row" *ngFor="let competence of competences">
        <div class="col-xs-4 table-header"><b>Nº</b></div>
        <div class="col-xs-8"> {{ competence.values}} </div>

        <div class="col-xs-4 table-header"><b>Procedimiento</b></div>
        <div class="col-xs-8"> {{ competence.name }} </div>

        <div class="col-xs-4 table-header"><b>360º</b></div>
        <div class="col-xs-8">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIs360(competence)" (click)="onEvaluate('v360', competence)"><!--[matMenuTriggerFor]="optsMenu360"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIs360(competence)"><!--[matMenuTriggerFor]="optsMenu360"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenu360="matMenu">
                <button mat-menu-item (click)="onEvaluate(competence)">Evaluar</button>
            </mat-menu>-->
        </div>

        <div class="col-xs-4 table-header"><b>Casos Especiales</b></div>
        <div class="col-xs-8">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsSpecial(competence)" (click)="onEvaluate('SPECIAL', competence)"><!-- [matMenuTriggerFor]="optsMenuSpecial"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsSpecial(competence)"><!-- [matMenuTriggerFor]="optsMenuSpecial"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuSpecial="matMenu">
                <button mat-menu-item (click)="onEvaluate(competence)">Evaluar</button>
            </mat-menu>-->
        </div>

        <div class="col-xs-4 table-header"><b>ECLOE</b></div>
        <div class="col-xs-8">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsEcloe(competence)" (click)="onEvaluate('ECLOE', competence)"><!--[matMenuTriggerFor]="optsMenuECLOE"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsEcloe(competence)"><!--[matMenuTriggerFor]="optsMenuECLOE"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuECLOE="matMenu">
                <button mat-menu-item (click)="onEvaluate(competence)">Evaluar</button>
            </mat-menu>-->
        </div>

        <div class="col-xs-4 table-header"><b>EPOE</b></div>
        <div class="col-xs-8">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsEpoe(competence)" (click)="onEvaluate('EPOE', competence)"><!--[matMenuTriggerFor]="optsMenuEPOE"-->
                    <!--<mat-icon>done</mat-icon>-->
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsEpoe(competence)"><!--[matMenuTriggerFor]="optsMenuEPOE"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuEPOE="matMenu">
                <button mat-menu-item (click)="onEvaluate(competence)">Evaluar</button>
            </mat-menu>-->
        </div>

        <div class="col-xs-4 table-header"><b>Simulación, cursos o talleres con evaluación</b></div>
        <div class="col-xs-8">
            <ng-container>
                <button mat-raised-button class="evaluate-button" *ngIf="evalMethodIsSimulation(competence)" (click)="onEvaluate('SIMULATION', competence)"><!--[matMenuTriggerFor]="optsMenuSimulation"-->
                    <!--<mat-icon>done</mat-icon>-->
                    Evaluar
                </button>
            </ng-container>
            <!--<ng-container *ngIf="getRol() === '2'">
                <button mat-icon-button *ngIf="evalMethodIsSimulation(competence)"><!--[matMenuTriggerFor]="optsMenuSimulation"-->
                    <!--<mat-icon>done</mat-icon>
                </button>
            </ng-container>-->
            <!--<mat-menu #optsMenuSimulation="matMenu">
                <button mat-menu-item (click)="onEvaluate(competence)">Evaluar</button>
            </mat-menu>-->
        </div>
    </div>
</div>
    