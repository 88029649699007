<div id="top" class="page-layout blank p-10" fusePerfectScrollbar [ngClass]="{'hideMe': dataSource === null || dataSource === undefined}">

    <!-- SEARCH-->
        <div class="filtro" fxLayout="row" *ngIf="form" style="justify-content: space-around;">
            <!--<div style="max-width: 30%; margin-left: 2%">-->
                <mat-form-field style="width: 30%">
                    <!--<mat-icon matSuffix>search</mat-icon>-->
                    <input #inputSearch1 (keydown)="onKey($event)" style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por residente" [(ngModel)]="searchText" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchText !== ''" matSuffix mat-icon-button (click)="clean()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field> 
                <!--<button mat-raised-button style="background-color:#17A08C; color:white;" routerLink="/stepper">
                    <mat-icon>add</mat-icon> Nuevo
                </button>-->
            <!--</div>
            <div style="max-width: 30%; width: 30%; margin-left: 2%">-->
                <mat-form-field style="width: 30%">
                    <!--<mat-icon matSuffix>search</mat-icon>-->
                    <input #inputSearch2 (keydown)="onKey($event)" style="margin-top: 0.438em  !important;" matInput type="text" placeholder="Buscar por tipo" [(ngModel)]="searchTextCaso" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchTextCaso !== ''" matSuffix mat-icon-button (click)="cleancaso()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field>
            <!--</div>

            <div style="max-width: 30%; width: 30%; margin-left: 2%">-->
                <mat-form-field style="width: 30%">
                    <!--<mat-icon matSuffix>search</mat-icon>-->
                    <input #inputSearch3 (keydown)="onKey($event)"  matInput style="margin-top: 0.438em  !important;" [(ngModel)]="searchMetodo" placeholder="Buscar por nombre" (ngModelChange)="typingTimer(); allclean()">
                    <button mat-button *ngIf="searchMetodo !== ''" matSuffix mat-icon-button (click)="cleanmetodo()">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                </mat-form-field>
            <!--</div>-->
        </div>

        <mat-paginator #paginator2 [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 2)"></mat-paginator>
        <!--<div class="spinner-container" style="display:flex; height:75vh; justify-content: center; align-items: center; color: #17A08C"
        id="spinnerEval" *ngIf="dataSource.loading$ | async">
            <mat-spinner></mat-spinner>
        </div>-->
        <ng-container *ngIf="filtered.length === 0 && (searchText !== '' || searchTextCaso !== '' || searchMetodo !== '')">
            <div style="margin-left: 25px">No se encontraron datos que mostrar</div>
        </ng-container>

        <mat-table #table [dataSource]="dataSource"
        [@animateStagger]="{value:'50'}" style="width: 100%">
        <ng-container *ngFor="let column of displayedColumnsNoAction" [matColumnDef]="column" >
                <mat-header-cell *matHeaderCellDef class="mat-center-style">{{translateColumns(column)}}</mat-header-cell>
                <mat-cell *matCellDef="let certificate" class="mat-center-style" style="width: 100%; min-height: 25px !important">
                        <p class="mobile-label col-xs-2" style="margin-top: 0px !important; margin-bottom: 0px !important">{{translateColumns(column)}}</p>
                        <p class="mobile-data" style="margin-top: 0px !important; margin-bottom: 0px !important">{{certificate[column]}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="acciones">
                <mat-header-cell *matHeaderCellDef class="mat-center-style" >Acciones</mat-header-cell>
                <mat-cell *matCellDef="let certificate" class="mat-center-style" style="width: 100%; min-height: 25px !important; padding-left: 10px !important">
                    <button (click)="openCertificate(certificate)" mat-raised-button style="background-color:#428bca; color: white; margin-top: 3px; max-width: 180px"> 
                        <mat-icon style="color: white; font-size: 15px">remove_red_eye</mat-icon> 
                            Ver
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" style="width:100%"></mat-header-row>
            <mat-row *matRowDef="let rotacion; columns: displayedColumns;" style="width:100%; min-height: 15px !important"
                    matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
            </mat-row>
        </mat-table>

        <mat-paginator #paginator [length]="calculateLength()" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" (page)="syncPages($event, 1)"></mat-paginator>
</div>
