import { ToolbarService } from './../../../layout/components/toolbar/toolbar.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { EvaluationService } from 'app/main/pages/stepper/evaluation.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ResidentsService } from 'app/services/residents.service';
import * as moment from 'moment';

export enum PortfolioViews {
    EVALUATIONS = 0,
    ACTIVITIES = 1,
    PROGRESS = 2
}

@Component({
    selector   : 'fortalezas',
    templateUrl: './fortalezas.component.html',
    styleUrls  : ['./fortalezas.component.scss'],
    animations   : fuseAnimations
})
export class FortalezasComponent implements OnInit, OnDestroy {   

    navButtons = [
        { key: PortfolioViews.EVALUATIONS, text: 'Evaluaciones' },
        { key: PortfolioViews.ACTIVITIES, text: 'Actividades' },
        { key: PortfolioViews.PROGRESS, text: 'Progreso' },
    ];
    resident;
    strData: any[] = [];
    weakData: any[] = [];
    selectedView: PortfolioViews = PortfolioViews.EVALUATIONS;
    portfolioViews = PortfolioViews;

    constructor(
        private evalService: EvaluationService,
        private resService: ResidentsService,
        private router: Router,
        private toolbarService: ToolbarService
    ) {}

    ngOnInit(): void {
        if (this.getRol() === '2') {
            this.resService.getResidentInfo().subscribe((res) => {
                this.resident = res;
                // console.log(res);
            });
            this.resService.getStrengths().subscribe((data: any) => {
                    this.strData = data[0].fortalezas;
                    this.weakData = data[0].debilidades;
                }
            );
        }
        else if (this.getRol() === '3') {
            this.toolbarService.setStatus(true);
            this.evalService.residenteConsulta.subscribe(resident => {
                console.log(resident);
                if (resident !== null && resident !== undefined) {
                    this.resident = resident;
                    this.resService.getStrengths(this.resident.id).subscribe((data: any) => {
                        this.strData = data[0].fortalezas;
                        this.weakData = data[0].debilidades;
                        });
                } else {
                    this.router.navigate(['misresidentes']);
                }
            }).unsubscribe();
        }
    }

    onSelectView = (view: PortfolioViews) => this.selectedView = view;

    viewIs = (view: PortfolioViews) => this.selectedView === view;

    
    getRol(): string {
        return localStorage.getItem('rol');
    }
    ngOnDestroy(): void {
        this.toolbarService.setStatus(false);
    }

    formatDate(fecha): string {
        return moment(fecha).format('DD/MM/YYYY');
    }

    goEvaluation(idEval, idTipo): void {
        this.evalService.emitTutorStrWeak(true);
        const params = {
            id: idEval,
            idTipo: idTipo
        };
        this.router.navigate(['stepper'], { queryParams: params });
    }
}
