import { Component, OnInit, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  /** 
   * We should implement some search latency as an improvement.
  */
  @Input() allItems: any[] = [];
  @Input() searchAttributes: string[] = [];
  @Output() search: EventEmitter<any[]> = new EventEmitter<any[]>();
    
  searchText: string = null;
  @ViewChild('inputSearch') inputSearch;

  constructor() { }

  ngOnInit() { }

  /** 
   * Filters all the items and fires the search event with all the elements that contain 
   * at least one attribute that matches the search text.
  */
  onSearch(): void {
    let filtered;
    if (this.searchText === null || this.searchText === '') {
      filtered = [ ...this.allItems ]; // Reset the filter and show all items.

    } else {
      filtered = this.allItems.filter(this.atLeastOneAttributeMatches);
    }
    this.search.emit(filtered);
  }

  /** 
   * Return true if at least one attribute of the item is matched with the search text.
   * number attributes are converted to string to include them in the search.
  */
  atLeastOneAttributeMatches = (item: any): boolean => {
    let attribute, value;
    for (let index = 0; index < this.searchAttributes.length; index++) {
      
      attribute = this.searchAttributes[index];
      value = item[attribute];
      if (typeof value === 'number') {
        value = String(value);
      }

      if (value !== null && value !== undefined && typeof value === 'string') {
        const searchText = this.searchText = this.searchText.toLowerCase()
        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
        if (attribute === 'endDate' || attribute === 'startDate') {
            if (value.includes(moment(searchText, 'DD/MM/YYYY').format('YYYY-MM-DD'))) {
                return true;
            }
        }
        else if (value.toLowerCase().includes(searchText)) {
          return true;
        }
      }
    }
    return false;
  }
  onKey(e) {
    if (e.keyCode == 13) {
        this.inputSearch.nativeElement.blur();
    }
}
  clearSearch(): void {
    this.searchText = null;
    this.onSearch();
  }

}
