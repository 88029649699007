import { StepperResidentService } from './../../../services/stepper-resident.service';
import { UserService } from 'app/services/users.service';
import { Router } from '@angular/router';
import { StepperService } from './../../../services/stepper.service';
import { SpinnerService } from 'app/services/spinner.service';
import { Component, OnInit, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { EvDocCompetenceMain } from '../models/ev-doc-competence-main.model';
import { EvaluationTypeIds } from 'app/models/evaluation-type-ids.enum';

/** 
 * Este componente solo se utiliza para renderizar la pestaña de Documentos de Evaluación (la principal).
*/

@Component({
  selector: 'app-ev-doc-competences-main',
  templateUrl: './ev-doc-competences-main.component.html',
  styleUrls: ['./ev-doc-competences-main.component.scss']
})
export class EvDocCompetencesMainComponent implements OnInit, AfterViewInit {

    @Input() competences: EvDocCompetenceMain[] = [];
    @Output() hideIt = new EventEmitter<any>();
    constructor (
        private spinner: SpinnerService,
        private stepperTutorService: StepperService,
        private stepperResidentService: StepperResidentService,
        private router: Router,
        private userService: UserService
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.hideIt.next();
    }

    /** 
     * Esta clasificación NO es la misma que en la de EvDocViews sino que viene determinada por BD.
     * 1 => ECLOE
     * 2 => EPOE
     * 3 => Casos Especiales
     * 4 => Simulacion ... etc
     * 5 => 360
    */

    evalMethodIsEcloe =      (c: EvDocCompetenceMain) => c.evaluationTypes ? c.evaluationTypes[EvaluationTypeIds.ECLOE] != null : false; /* equiparación lógica a != null */
    evalMethodIsEpoe =       (c: EvDocCompetenceMain) => c.evaluationTypes ? c.evaluationTypes[EvaluationTypeIds.EPOE] != null : false;
    evalMethodIsSpecial =    (c: EvDocCompetenceMain) => c.evaluationTypes ? c.evaluationTypes[EvaluationTypeIds.SPECIAL] != null : false;
    evalMethodIsSimulation = (c: EvDocCompetenceMain) => c.evaluationTypes ? c.evaluationTypes[EvaluationTypeIds.SIMULATION] != null : false;
    evalMethodIs360 =        (c: EvDocCompetenceMain) => c.evaluationTypes ? c.evaluationTypes[EvaluationTypeIds.v360] != null : false;

    onEvaluate(evalMethod, competence): void {
        const idMetodoEval = competence.evaluationTypes[EvaluationTypeIds[evalMethod]];
        if (this.getRol() === '3') {
            this.stepperTutorService.idTipoMetodoEval = EvaluationTypeIds[evalMethod];
            this.stepperTutorService.idMetodoEval = idMetodoEval;
            this.stepperTutorService.docEvalFlag = true;
            this.router.navigate(['stepper']);
        } else if (this.getRol() === '2') {
            this.stepperResidentService.idTipoMetodoEval = EvaluationTypeIds[evalMethod];
            this.stepperResidentService.idMetodoEval = idMetodoEval;
            this.stepperResidentService.docEvalFlag = true;
            this.router.navigate(['stepper-resident']);
        }
    }

    getRol() {
        return this.userService.getRol();
    }
}
