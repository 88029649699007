import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { EvaluationTypeIds } from './../../../models/evaluation-type-ids.enum';
import { FormBuilder } from '@angular/forms';
import { EvaluationDataSource } from './../stepper/datasource.service';
import { EvaluationService } from './../stepper/evaluation.service';
import { SpinnerService } from './../../../services/spinner.service';
import { Component, ViewChild, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subject, merge, Observable, Subscription } from 'rxjs';
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

declare let cordova: any;

@Component({
    selector   : 'app-evaluaciones',
    templateUrl: './evaluaciones.component.html',
    styleUrls  : ['./evaluaciones.component.scss'],
    animations   : fuseAnimations
})

export class EvaluacionesComponent implements OnInit, AfterViewInit {
    @ViewChild('inputSearch1') inputSearch1;
    @ViewChild('inputSearch2') inputSearch2;

    dataSource = new  MatTableDataSource<any>([]);
    downloadUrl = environment.evaluationsUrl;
    residente: any;
    casoLabel = '';
    casoColumnLabel = '';
    searchText: string = '';
    searchTextCaso: string = '';
    searchTextClinico: string = '';
    searchMetodo: number = 0;
    queryParams: {tipo: string} = {tipo: ''};
    flag = 0;
    displayedColumns: string[];
    searchAtributes: string[];
    evaluaciones: any = [];
    allEvaluaciones: any = [];
    filtered = [];
    tipoEvaluacion;
    evaluationLength;
    form;
    typingTime;
    evalTypeOptions = [
        { id: 0, name: 'Sin filtro' },
        { id: EvaluationTypeIds.ECLOE, name: 'ECLOE' },
        { id: EvaluationTypeIds.EPOE, name: 'EPOE' },
        { id: EvaluationTypeIds.SPECIAL, name: 'Casos Especiales' },
        { id: EvaluationTypeIds.v360, name: '360º' },
        { id: EvaluationTypeIds.SIMULATION, name: 'Simulaciones, cursos o talleres' }
    ];
    private _unsubscribeAll: Subject<any>;
    @ViewChildren(MatPaginator) paginatorList: QueryList<MatPaginator>;

    paginator: MatPaginator;
    paginator2: MatPaginator;
    // @ViewChild(MatSort) sort: MatSort;
    
    constructor(private matDialog: MatDialog,
                public _dialog: MatDialog,
                // private rotacionesService: RotacionesService,
                private evaluationService: EvaluationService,
                private router: Router,
                private route: ActivatedRoute,
                private spinner: SpinnerService,
                private formB: FormBuilder) {
                }
    /*loadDataSourceFormApi(){
        this.spinner.show();
        this.route.queryParams.subscribe((params: {tipo: string}) => {
            this.tipoEvaluacion = params.tipo;
            if (params.tipo === 'competencias') {          
                this.evaluationService.getEvaluacionesCompetenciasPag(
                    '',
                    'asc',
                    this.paginator.pageIndex,
                    this.paginator.pageSize
                ).subscribe(evaluaciones => {
                    this.evaluaciones = evaluaciones['evaluaciones'];
                    this.dataSource = new MatTableDataSource<Evaluaciones[]>(this.evaluaciones);
                    //this.dataSource.data = this.evaluaciones;
                    this.allEvaluaciones = [ ...this.evaluaciones ];
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.displayedColumns = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada', 'acciones'];
                    this.searchAtributes = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
                    this.paginator.page
                    .pipe(
                        tap(() => this.loadDataSourceFormApi())
                    )
                    .subscribe();
                    
                    this.spinner.hide();
                }, (error) => {
                    this.spinner.hide();
                    console.log('Error: ' + error);  
                });
            } else if (params.tipo === 'procedimientos') {
                this.evaluationService.getEvaluacionesProcedimientosPag(                    
                '',
                'asc',
                this.paginator.pageIndex,
                this.paginator.pageSize).subscribe(evaluaciones => {
                    this.evaluaciones = evaluaciones;
                    this.dataSource = new MatTableDataSource<Evaluaciones[]>(this.evaluaciones);
                    this.allEvaluaciones = [ ...evaluaciones ];
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.displayedColumns = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada', 'acciones'];
                    this.searchAtributes = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
                    this.paginator.page
                    .pipe(
                        tap(() => this.loadDataSourceFormApi())
                    )
                    .subscribe();
                    
                    this.spinner.hide();
                }, (error) => {
                    this.spinner.hide();
                    console.log('Error: ' + error);
                });
            }
        });
    }*/
    
    loadDataSourceFormApi(filters, order, pageIndex, pageSize): void{
        this.spinner.show();
        this.route.queryParams.subscribe((params: {tipo: string}) => {
            if (this.tipoEvaluacion !== params.tipo && this.tipoEvaluacion !== undefined) {
                this.spinner.show();
                this.paginator.pageIndex = 0;
                this.searchText = '';
                this.searchTextCaso = '';
                this.searchTextClinico = '';
                this.searchMetodo = 0;
            }
            this.tipoEvaluacion = params.tipo;
            setTimeout(() => {
                if (params.tipo === 'competencias') {
                    /*
                    this.dataSource = new EvaluationDataSource(this.evaluationService);  
                    this.dataSource.loadCompetencias(filters, order, pageIndex, pageSize);
                    this.dataSource.evaluationLength.subscribe( length => {
                        this.evaluationLength = length;

                    });
                    */
                   this.evaluationService.getEvaluacionesCompetenciasPag().subscribe((res) => {
                        this.casoLabel = 'Buscar por caso clínico';
                        this.casoColumnLabel = 'Caso';
                        this.evaluaciones = res.evaluaciones;
                        this.evaluationLength = res.evaluacionesTotales;
                        this.loadEvalPage();
                        this.spinner.hide();
                        this.spinner.hide();
                   });
                    // this.dataSource.paginator = this.paginator;
                    // this.dataSource.sort = this.sort;
                    this.displayedColumns = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada', 'acciones'];
                    this.searchAtributes = ['tipo', 'fecha', 'nombreResidente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
                    
                } else if (params.tipo === 'procedimientos') {

                    this.evaluationService.getEvaluacionesProcedimientosPag().subscribe((res)=> {
                        this.casoLabel = 'Buscar por procedimiento';
                        this.casoColumnLabel = 'Procedimiento';
                        this.evaluaciones = res.evaluaciones;
                        this.evaluationLength = res.evaluacionesTotales;
                        this.loadEvalPage();
                        this.spinner.hide();
                        this.spinner.hide();
                     });
                    // this.dataSource.paginator = this.paginator;
                    // this.dataSource.sort = this.sort;
                    this.displayedColumns = ['tipo', 'fecha', 'evaluador', 'residente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada', 'acciones'];
                    this.searchAtributes = ['tipo', 'fecha', 'nombreResidente', 'competencia', 
                    'metodo', 'caso', 'nivelRequerido', 'nivelAlcanzado', 'certificada'];
                }
            });
        });
    }

    ngOnInit(): void {
        this.spinner.show();
        this.form = this.formB.group({
            'searchBar': [null]
        });
        // console.log(this.paginatorList);
        this.loadDataSourceFormApi(this.searchText, 'asc', 0, 10);
        // this.loadDataSourceFormApi(this.searchText, 'asc', this.paginatorList.toArray()[1].pageIndex, this.paginatorList.toArray()[1].pageSize);
        this.evaluationService.emitTutorPortfolioView(false);
        // this.loadDataSourceFormApi(this.searchText, 'asc', this.paginator.pageIndex, this.paginator.pageSize);
    }

    onKey(e) {
        if (e.keyCode == 13) {
            this.inputSearch1.nativeElement.blur()
            this.inputSearch2.nativeElement.blur()

        }
    }
    ngAfterViewInit(): void {
        if (this.paginatorList.toArray().length > 1) {
            this.paginator2 = this.paginatorList.toArray()[0];
            this.paginator = this.paginatorList.toArray()[1];
            //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
            merge(/*this.sort.sortChange,*/ this.paginator.page, this.paginator2.page)
                .pipe(
                    tap(() => this.loadEvalPage())
                )
                .subscribe();
            merge(this.paginator.page, this.paginator2.page)
            .pipe(
                tap(() => this.loadEvalPage())
            )
            .subscribe();
            this.form.valueChanges.pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.paginator2.pageIndex = 0;
                    this.loadEvalPage();
                })
                ).subscribe();
        }
            else {
                this.paginator = this.paginatorList.toArray()[0];
                //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
                merge(/*this.sort.sortChange,*/ this.paginator.page)
                    .pipe(
                        tap(() => this.loadEvalPage())
                    )
                    .subscribe();
                merge(this.paginator.page)
                .pipe(
                    tap(() => this.loadEvalPage())
                )
                .subscribe();
                this.form.valueChanges.pipe(
                    debounceTime(150),
                    distinctUntilChanged(),
                    tap(() => {
                        this.paginator.pageIndex = 0;
                        this.loadEvalPage();
                    })
                    ).subscribe();
            }
    }

    downloadFile(evalutationDoc): void {
        const urlDownload = `${this.downloadUrl}${this.residente.id}/${evalutationDoc}`;
        // window.open(urlDownload);
        window.open(urlDownload, '_system', 'location=yes');
        /*
        cordova.plugins.fileOpener2.open(
            urlDownload,
            // 'application/vnd.android.package-archive'
        );
        */
    }

    format(string: string): string {
        if (Number(string) === 0) {
            return 'No';
        } else if (Number(string) === 1) {
            return 'Si';
        }
    }

    loadEvalPage() {
        /*
        this.loadDataSourceFormApi(
            this.searchText,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
            */
            let pageNumber = this.paginator.pageIndex;
            let pageSize = this.paginator.pageSize;
            const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
            const final = initial + pageSize;
            
            if ((this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== 0 || this.searchTextClinico !== '') && this.filtered.length > 0) {
                this.dataSource.data = this.filtered.slice(initial, final);
            } else {
                this.dataSource.data = this.evaluaciones.slice(initial, final);
                // console.log(this.dataSource.data);
            }
    }

    /*clearSearch(): void {
        this.searchText = '';
        this.filtered = [];
        this.onSearch();
    }

    clearSearchCaso(): void {
        this.searchTextCaso = '';
        this.filtered = [];
        this.onSearch();
    }*/

    //TODO - CAMBIAR ESTO
    /*onSearch(): void {
        this.paginator.pageIndex = 0;
        this.loadDataSourceFormApi(this.searchText,             
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    onSearchCaso(): void {
        this.paginator.pageIndex = 0;
        this.loadDataSourceFormApi(this.searchTextCaso,             
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }*/

    /*typingTimer(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchText === '') {
                this.paginator.pageIndex = 0;
                this.loadDataSourceFormApi(this.searchText,             
                    this.sort.direction,
                    this.paginator.pageIndex,
                    this.paginator.pageSize);
            }
        }, 1000);
    }*/

    typingTimer(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchTextCaso.length >= 3 || 
                (this.searchText === '' && this.searchTextCaso === '' && this.searchMetodo === 0)) {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                window.scrollTo(0, 0);
                document.getElementById('top').scrollIntoView();
                // console.log("scrolling to top");
                let pageNumber = this.paginator.pageIndex;
                let pageSize = this.paginator.pageSize;
                const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
                const final = initial + pageSize;
                    // Casos sobre 4
                if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchMetodo !== 0 && this.searchTextClinico !== ''){
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso))
                    .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo)
                    .filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico));
                } 

                    // Casos sobre 3
                if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchMetodo !== 0){
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso))
                    .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                }

                if (this.searchTextClinico !== '' && this.searchTextCaso !== '' && this.searchMetodo !== 0){
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico))
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso))
                    .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                } 

                if (this.searchText !== '' && this.searchTextClinico !== '' && this.searchMetodo !== 0){
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico))
                    .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                } 

                if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchTextClinico !== ''){
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso))
                    .filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico));
                } 
                

                    // Casos sobre 2
                else if (this.searchText !== '' && this.searchTextCaso !== '') {
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso));
                }
                else if (this.searchText !== '' && this.searchMetodo !== 0) {
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                }
                else if (this.searchMetodo !== 0 && this.searchTextCaso !== '') {
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo)
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso));
                }

                else if (this.searchText !== '' && this.searchTextClinico !== '') {
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText))
                    .filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico));
                }

                else if (this.searchTextClinico !== '' && this.searchTextCaso !== '') {
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico))
                    .filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso));
                }
                
                else if (this.searchMetodo !== 0 && this.searchTextClinico !== '') {
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo)
                    .filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico));
                }

                    // casos sobre 1
                else if (this.searchText !== '') {
                    const searchText = this.searchText.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchText));
                } else if (this.searchTextCaso !== '') {
                    const searchTextCaso = this.searchTextCaso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.competencia.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextCaso));
                } else if (this.searchTextClinico !== '') {
                    const searchTextClinico = this.searchTextClinico.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                    this.filtered = this.evaluaciones.filter(r => r.caso.toLowerCase()
                    .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                    .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                    .includes(searchTextClinico));
                    console.log(this.filtered);
                } else if (this.searchMetodo !== 0) {
                    this.filtered = this.evaluaciones.filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                }



                else {
                    this.filtered = this.evaluaciones;
                }
                this.dataSource.data = this.filtered.slice(initial, final);
            }
        }, 1000);
    }

    typingTimerInstant(): void {
        clearTimeout(this.typingTime);
        this.typingTime = setTimeout(() => {
            if (this.searchText.length >= 3 || this.searchTextCaso.length >= 3 || this.searchMetodo !== 0 || 
                (this.searchText === '' && this.searchTextCaso === '' && this.searchMetodo === 0)) {
                this.paginator.pageIndex = 0;
                this.paginator2.pageIndex = 0;
                window.scrollTo(0, 0);
                document.getElementById('top').scrollIntoView();
                // console.log("scrolling to top");
                let pageNumber = this.paginator.pageIndex;
                let pageSize = this.paginator.pageSize;
                const initial = (pageNumber > 0) ? (pageNumber) * pageSize : 0;
                const final = initial + pageSize;
                    // Casos sobre 4
                    if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchMetodo !== 0 && this.searchTextClinico !== ''){
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso))
                        .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo)
                        .filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico));
                    } 
    
                        // Casos sobre 3
                    if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchMetodo !== 0){
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso))
                        .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                    }
    
                    if (this.searchTextClinico !== '' && this.searchTextCaso !== '' && this.searchMetodo !== 0){
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico))
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso))
                        .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                    } 
    
                    if (this.searchText !== '' && this.searchTextClinico !== '' && this.searchMetodo !== 0){
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico))
                        .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                    } 
    
                    if (this.searchText !== '' && this.searchTextCaso !== '' && this.searchTextClinico !== ''){
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso))
                        .filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico));
                    } 
                    
    
                        // Casos sobre 2
                    else if (this.searchText !== '' && this.searchTextCaso !== '') {
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso));
                    }
                    else if (this.searchText !== '' && this.searchMetodo !== 0) {
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                    }
                    else if (this.searchMetodo !== 0 && this.searchTextCaso !== '') {
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo)
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso));
                    }
    
                    else if (this.searchText !== '' && this.searchTextClinico !== '') {
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText))
                        .filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico));
                    }
    
                    else if (this.searchTextClinico !== '' && this.searchTextCaso !== '') {
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico))
                        .filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso));
                    }
                    
                    else if (this.searchMetodo !== 0 && this.searchTextClinico !== '') {
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo)
                        .filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico));
                    }
    
                        // casos sobre 1
                    else if (this.searchText !== '') {
                        const searchText = this.searchText.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchText));
                    } else if (this.searchTextCaso !== '') {
                        const searchTextCaso = this.searchTextCaso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.competencia.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextCaso));
                    } else if (this.searchTextClinico !== '') {
                        const searchTextClinico = this.searchTextClinico.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u');
                        this.filtered = this.evaluaciones.filter(r => r.caso.toLowerCase()
                        .replace(/á/g, 'a').replace(/é/g, 'e').replace(/í/g, 'i').replace(/ó/g, 'o').replace(/ú/g, 'u')
                        .replace(/à/g, 'a').replace(/è/g, 'e').replace(/ì/g, 'i').replace(/ò/g, 'o').replace(/ù/g, 'u')
                        .includes(searchTextClinico));
                    } else if (this.searchMetodo !== 0) {
                        this.filtered = this.evaluaciones.filter(r => Number(r.idTipoMetodoEval) === this.searchMetodo);
                    }

                    else {
                        this.filtered = this.evaluaciones;
                    }
                this.dataSource.data = this.filtered.slice(initial, final);
            }
        }, 1);
    }


/*
    hider(): void {
        if (this.dataSource) {
            setTimeout(() =>
                this.dataSource.loading$.subscribe(loading => {
                    if (!loading) {
                        this.spinner.hide();
                    } else {
                        this.spinner.show();
                    }
                })
            );
        }
    } */

    calculateLength(): number {
        if ((this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== 0 || this.searchTextClinico !== '') && this.filtered.length > 0) {
            return this.filtered.length;
        } else if (this.filtered.length === 0 && (this.searchText !== '' || this.searchTextCaso !== '' || this.searchMetodo !== 0 || this.searchTextClinico !== '')){
            return 0;
        } else {
            return this.evaluationLength;
        }
    }

    /*cleaner(): void {
        this.searchText = '';
        this.filtered = [];
        this.filtered = this.evaluaciones.filter(r => r.competencia.includes(this.searchTextCaso));
    }
    cleanerCaso(): void {
        this.searchTextCaso = '';
        this.filtered = [];
        this.filtered = this.evaluaciones.filter(r => r.nombreResidente.includes(this.searchText));
    }*/

    clean(): void {
        this.searchText = '';
        this.typingTimerInstant();
    }

    cleancaso(): void {
        this.searchTextCaso = '';
        this.typingTimerInstant();
    }

    cleanclinico(): void {
        this.searchTextClinico = '';
        this.typingTimerInstant();
    }

    allclean(): void {
        if (this.searchText === '' && this.searchTextCaso === '' && this.searchTextClinico === '' && this.searchMetodo === 0) {
            this.filtered = [];
        }
    }

    syncPages1to2(event) {
        this.paginator2.pageIndex = event.pageIndex;
        this.paginator2.pageSize = event.pageSize;
        this.paginator2.length = event.length;
    }
    
    syncPages2to1(event) {
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.pageSize = event.pageSize;
        this.paginator.length = event.length;
    }

    
    syncPages(event, number) {
        window.scrollTo(0, 0);
        document.getElementById('top').scrollIntoView();
        // console.log("scrolling to top");
        if (number === 1) {
            this.paginator2.pageIndex = event.pageIndex;
            this.paginator2.pageSize = event.pageSize;
            this.paginator2.length = event.length;
        } else if (number === 2) {
            this.paginator.pageIndex = event.pageIndex;
            this.paginator.pageSize = event.pageSize;
            this.paginator.length = event.length;
        }
    }

    appearLogic() {

    }

}
