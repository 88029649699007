<div class="row table-header">
    <div class="col-xs-5"><h4><b>Nombre</b></h4></div>
    <div class="col-xs-5"><h4><b>Competencias</b></h4></div>
    <div class="col-xs-2"><h4><b>Acciones</b></h4></div>
</div>
<div class="row competence-row" *ngFor="let competence of competences">
    <div class="col-xs-5">
        <span class="comp-name">{{ competence.name }}</span>
    </div>
    <div class="col-xs-5">
        <button mat-raised-button class="chip-btn chip-colored" *ngFor="let value of getSplittedValues(competence)">
            <b>{{ value }}</b>
        </button>
    </div>
    <div class="col-xs-2">
        <button mat-icon-button [matMenuTriggerFor]="optsMenu" class="actions-btn">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #optsMenu="matMenu">
            <button mat-menu-item *ngIf="canSeeProtocol(competence)" (click)="onOpenActProtocol(competence)">Protocolo de actuación</button>
            <button mat-menu-item (click)="onEvaluate(competence)">Evaluar</button>
        </mat-menu>
    </div>
</div>