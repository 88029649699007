import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { PortProgressCompetence } from '../models/port-progess-competence.model';
import { EvaluationService } from '../../stepper/evaluation.service';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { UtilsService } from 'app/services/utils.service';
import { PortEvaluation } from '../models/port-evaluation.model';

@Component({
  selector: 'app-port-progress-compets',
  templateUrl: './port-progress-compets.component.html',
  styleUrls: ['./port-progress-compets.component.scss']
})
export class PortProgressCompetsComponent implements OnInit {

    @Input() competences: PortProgressCompetence[] = [];
    @Input() anyo?;
    dataSource = new MatTableDataSource<PortProgressCompetence>();
    columns = [
        { prop: 'numero', title: 'Nº' },
        { prop: 'nombre', title: 'Competencia' },
        { prop: '[1][objetivo]', title: 'Etapa I (R1-R2) troncal Objetivo', 
            formatter: (row) => {
                if (row['1']['objetivo'] !== ''){
                    return row['1']['objetivo'];
                } else {
                    return '-';
                }
            }
        },
        { prop: '[1][conseguido]', title: 'Etapa I (R1-R2) troncal Conseguido', 
            formatter: (row) => {
                if (row['1']['conseguido'] !== ''){
                    return row['1']['conseguido'];
                } else {
                    return '-';
                }
            }
        },
        { prop: '[2][objetivo]', title: 'Etapa II (R3-R4) troncal Objetivo', 
            formatter: (row) => {
                if (row['2']['objetivo'] !== ''){
                    return row['2']['objetivo'];
                } else {
                    return '-';
                }
            }
        },
        { prop: '[2][conseguido]', title: 'Etapa II (R3-R4) troncal Conseguido', 
            formatter: (row) => {
                if (row['2']['conseguido'] !== ''){
                    return row['2']['conseguido'];
                } else {
                    return '-';
                }
            }
        },
        { prop: '[3][objetivo]', title: 'Etapa III (R5) experto Objetivo', 
            formatter: (row) => {
                if (row['3']['objetivo'] !== ''){
                    return row['3']['objetivo'];
                } else {
                    return '-';
                }
            }
        },
        { prop: '[3][conseguido]', title: 'Etapa III (R5) experto Conseguido', 
            formatter: (row) => {
                if (row['3']['conseguido'] !== ''){
                    return row['3']['conseguido'];
                } else {
                    return '-';
                }
            }
        },
    ];
    displayedColumns: string[] = [];

    constructor (
        private utils: UtilsService
    ) { }

    ngOnInit(): void {
        this.displayedColumns = this.columns.map(c => c.prop);
        // this.displayedColumns.push('opciones');
        this.dataSource.data = this.competences;
    }

    onView(competence: PortProgressCompetence): void { }

    render = (row, column) => column.formatter ? column.formatter(row) : row[column.prop];
    
    stringCompare(string1: string, string2: string, e?: boolean): boolean {
        if (e) {
            return Number(string1) <= Number(string2);
        } else {
            return Number(string1) < Number(string2);
        }
    }
    compareLogicPhaseI(column): boolean {
        return ((this.anyo === 'R1' || this.anyo === 'R2') && (column.prop === '[1][objetivo]' || column.prop === '[1][conseguido]'));
    }
    compareLogicPhaseII(column): boolean {
        return ((this.anyo === 'R3' || this.anyo === 'R4') && (column.prop === '[2][objetivo]' || column.prop === '[2][conseguido]'));
    }
    compareLogicPhaseIII(column): boolean {
        return ((this.anyo === 'R5') && (column.prop === '[3][objetivo]' || column.prop === '[3][conseguido]'));
    }
    seeIfPhase(column): boolean {
        return (column.prop === '[1][objetivo]' || column.prop === '[1][conseguido]' || column.prop === '[2][objetivo]' 
        || column.prop === '[2][conseguido]' || column.prop === '[3][objetivo]' || column.prop === '[3][conseguido]');
    }

    compareGood(column, row): boolean {
        return (column.prop === '[1][conseguido]' && this.stringCompare(row['1']['objetivo'], row['1']['conseguido'], true))
        || (column.prop === '[2][conseguido]' && this.stringCompare(row['2']['objetivo'], row['2']['conseguido'], true))
        || (column.prop === '[3][conseguido]' && this.stringCompare(row['3']['objetivo'], row['3']['conseguido'], true))
    }

    compareFail(column, row): boolean {
        return ((this.anyo === 'R1' || this.anyo === 'R2') && column.prop === '[1][conseguido]' && 
        (this.stringCompare(row['1']['conseguido'], row['1']['objetivo']) || row['1']['conseguido'] === '')) || (
        (this.anyo === 'R3' || this.anyo === 'R4') && column.prop === '[2][conseguido]' &&   
        (this.stringCompare(row['2']['conseguido'], row['2']['objetivo']) || row['2']['conseguido'] === '')) ||
        (this.anyo === 'R5' && column.prop === '[3][conseguido]' &&   
        (this.stringCompare(row['3']['conseguido'], row['3']['objetivo']) || row['3']['conseguido'] === ''));
    }
}
