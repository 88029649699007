import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { WebStorageService } from '../web-storage.service';
import { Observable } from 'rxjs';
import { IDbService } from '../idb.service';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root'
})
export class skipLogin implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private idbService: IDbService,
    private alertService: AlertService
    ) {}

   canActivate(): Observable<boolean> {
     // console.time("skipLogin");
    return new Observable<boolean>(observer => {
        if (localStorage.getItem("password") !== null && localStorage.getItem("password") !== undefined && localStorage.getItem("password")!== "" &&
        localStorage.getItem("login") !== null && localStorage.getItem("login") !== undefined && localStorage.getItem("login")!== "") {
          this.idbService.checkDB().then((active) => {
            if(!active) {
                this.alertService.showWarning("No tiene datos sincronizados, recuerde sincronizarlos para un uso offline");
            }
        })
          if(localStorage.getItem("tipoPerfil")=="3"){
            // console.timeEnd("skipLogin");
            this.router.navigate(['profile']);
          }
          else{
            // console.timeEnd("skipLogin");

            this.router.navigate(['profile']);
          }
        }
        // console.timeEnd("skipLogin");

        observer.next(true);
        observer.complete();
    });


  }

}
