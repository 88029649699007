<!--  *ngIf="loginForm !== undefined && loginForm !== null" -->

<div id="login" fxLayout="column">

        <div id="login-form-wrapper" fxLayout="row" fxLayoutAlign="center center">
            
            <div class="logo">
                <img src="assets/images/logo_hospitalaFe.png">
            </div>

            <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
                  
                <div class="title">Bienvenido a e-MIR-Medicina Intensiva</div>
    
                <form name="loginForm" [formGroup]="loginForm" novalidate>
    <!--  -->
                    <mat-form-field appearance="outline">
                        <mat-label>Usuario</mat-label>
                        <input matInput formControlName="login" autocorrect="off" autocapitalize="none" autocomplete="off">
                        <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                        <mat-error>
                            Usuario requerido
                        </mat-error>
                        <!-- mat-error
                            *ngIf="!loginForm.get('email').hasError('required') &&
                                    loginForm.get('email').hasError('email')">
                                    {{'LOGIN.validmail' | translate}}
                        </mat-error -->
                    </mat-form-field>
    <!--   -->
                    <mat-form-field appearance="outline">
                        <mat-label>Contraseña</mat-label>
                        <input matInput type="password" formControlName="password">
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                        <mat-error>                            
                            Contraseña requerida
                        </mat-error>
                    </mat-form-field>
    
                <!--    <mat-form-field appearance="outline">
                        <mat-label>{{'LOGIN.lang' | translate}}</mat-label>
                        <select (change)="language_chg()" matNativeControl formControlName="language">
                          <option value="en">English</option>
                          <option value="es">Español</option>
                        </select>
                      </mat-form-field> -->
    
                      <div class="span-error text-center" *ngIf="spanError">
                        <span style="color: red"><strong> Usuario o contraseña incorrrectos</strong></span>
                    </div>
    
                    <button [disabled]="loginForm.invalid" mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                             (click)="login()">
                        ENTRAR
                    </button>
    
                </form>
    
                <div class="mt-20 login-pie-btn"> 
    
                </div>
    
            </div>
    
        </div>
    
    </div>
    