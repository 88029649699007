
import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import {Location} from '@angular/common';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from './i18n/en';
import { locale as spanish } from './i18n/es';
import { ToolbarService } from '../../layout/components/toolbar/toolbar.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

import { AuthService } from '../../services/auth.service';


@Component({
    selector   : 'menu-ppal',
    templateUrl: './menu.component.html',
    styleUrls  : ['./menu.component.scss'],
    animations   : fuseAnimations
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy
{
    sub;
    id:number;
    selfieImage:any;
    username_wp:string;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _location: Location,

        private _toolbarService:ToolbarService,
        public _domSanitizer: DomSanitizer,
        public _authService:AuthService
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, spanish);
                // Configure the layout
                this._fuseConfigService.config = {
                    layout: {
                        footer   : {
                            hidden: true
                        }
                    }
                };
    }

    ngOnInit() {
        this.username_wp = localStorage.getItem('display_name');
       /* this.legalAdviceService.getAllLegalAdvices().subscribe((legalAdvices)=> {
            this.legalAdvices = legalAdvices;
        })*/
        let fotoAvatar = localStorage.getItem('avatarpath');
        if(fotoAvatar){
            this.selfieImage = fotoAvatar;
        }else{
            this.selfieImage = 'assets/images/avatars/profile.jpg'
        }
        
        this.sub = this._route
          .queryParams
          .subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.id = +params['id'] || 0;
            //alert(this.id )
          });
          setTimeout(() => {
            this._toolbarService.setStatus(true); // mostrar flecha atras
            this._toolbarService.setIconProfileImg(false); // ocultar imagen perfil
          });
      }

    ngAfterViewInit() {
       
    }

    ngOnDestroy(){
        this._toolbarService.setStatus(false); // ocultar flecha atras
        this._toolbarService.setIconProfileImg(true); // mostrar imagen perfil
    }

    logout_clk(){
        this._authService.logout();
        this._router.navigate(['/login']);
    }
}
