<mat-accordion class="width: 100%">
    <mat-expansion-panel *ngFor="let domain of domains" class="width: 100%">
        <mat-expansion-panel-header class="domain-header" [ngClass]="{ 'subdomain-header': isSubdomain }" style="background-color:#cccccc;">
            <mat-panel-title>
                <h3 style="color:#696969" [ngClass]="{ 'subdomain-header-text': isSubdomain }" ><b>{{ domain.name }}</b></h3>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row" style="margin-top: 8px">
            <div class="col-xs-12" *ngIf="domain.subdomains">
                <app-notebook-domains [domains]="domain.subdomains" [isSubdomain]="true" [year]="year"
                [idTipoActo]="idTipoActo"></app-notebook-domains>
            </div>
            <ng-template matExpansionPanelContent>
                <div class="col-xs-12" *ngIf="domain.competences">
                    <app-notebook-items [items]="domain.competences" [year]="year"  [idTipoActo]="idTipoActo"></app-notebook-items>
                </div>
            </ng-template>
        </div>

    </mat-expansion-panel>
</mat-accordion>