import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { EvaluacionesComponent, /*RotacionesViewDialog */} from './evaluaciones.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FuseHighlightModule } from '@fuse/components';
import {MatBadgeModule} from '@angular/material/badge';

import { AuthService } from 'app/services/auth.service';
import { MatButtonModule, MatDialogModule, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, 
    MatIconModule, MatProgressSpinnerModule, MatInputModule, MatPaginatorIntl, MatSelectModule } from '@angular/material';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { MatPaginatorIntlEsp } from 'app/MatPaginatorIntlEsp';

const routes = [
    {
        path     : 'misevaluaciones',
        component: EvaluacionesComponent,
       // canActivate: [AuthService]
    }
];

@NgModule({
    declarations: [
        EvaluacionesComponent,
        //RotacionesViewDialog
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,

        MatDialogModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatIconModule,
        SearchBarModule,
        MatButtonModule,
        NgxChartsModule,
        FuseSharedModule,
        FuseHighlightModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatSelectModule
    ],
    exports     : [
        EvaluacionesComponent
    ],
    entryComponents: [
        //RotacionesViewDialog
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEsp },
    ],
})

export class EvaluacionesModule
{
}