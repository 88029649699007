
/** 
 * Enumera cada una de las secciones del form de evaluaciones. Cada tipo de evaluación tendrá permitido visualizar unas u otras.
*/
export enum FormSection {
    ITEMS_PROF = 0,
    RESIDENTE = 1,
    ANYO = 2,
    SERV_HOSP = 3,
    EVALUADOR = 4,
    FECHA = 5,
    PUESTO_EVAL = 6, 
    COBATRICE = 7,
    RESUMEN_CASO = 8,
    MANEJO_CASO = 9, 
    DISCUSION = 10,
    CONCLUSION = 11,
    BIBLIOGRAFIA = 12,
    DATOS_PACIENTE = 13,
    COMPLEJIDAD = 14,
    EDAD = 15,
    SEXO = 16,
    NUEVO = 17,
}