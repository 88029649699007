import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule, MatButtonModule, MatExpansionModule, MatMenuModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { EvaluationDocumentsPageComponent } from './evaluation-documents-page/evaluation-documents-page.component';
import { EvDocDomainsComponent } from './ev-doc-domains/ev-doc-domains.component';
import { EvDocCompetencesComponent } from './ev-doc-competences/ev-doc-competences.component';
import { RouterModule } from '@angular/router';
import { EvaluationProtocolComponent } from './evaluation-protocol/evaluation-protocol.component';
import { EvaluationProtocolModalComponent } from './evaluation-protocol-modal/evaluation-protocol-modal.component';
import { EvDocCompetencesMainComponent } from './ev-doc-competences-main/ev-doc-competences-main.component';
import { FormsModule } from '@angular/forms';

const routes = [ {
    path: 'documentos-evaluacion',
    component: EvaluationDocumentsPageComponent
} ];

@NgModule({
  declarations: [
    EvaluationDocumentsPageComponent,
    EvDocDomainsComponent,
    EvDocCompetencesComponent,
    EvaluationProtocolComponent,
    EvaluationProtocolModalComponent,
    EvDocCompetencesMainComponent
],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatCardModule,
    MatButtonModule,
    FormsModule,
    MatExpansionModule,
    MatMenuModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,


  ],
  entryComponents: [
    EvaluationProtocolModalComponent
  ]
})
export class EvaluationDocumentsModule { }
